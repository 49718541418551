import {
    CANCEL_BOOKING,
    FETCH_BOOKINGS,
    FETCH_BOOKINGS_FAILED,
    FETCH_BOOKINGS_LATER, FETCH_BOOKINGS_LATER_FAILED,
    FETCH_BOOKINGS_LATER_SUCCESS,
    FETCH_BOOKINGS_SUCCESS,
    FETCH_BOOKINGS_TEAM,
    FETCH_BOOKINGS_TEAM_FAILED,
    FETCH_BOOKINGS_TEAM_SUCCESS, FETCH_NEWS_BOOKINGS, FETCH_NEWS_BOOKINGS_FAILED, FETCH_NEWS_BOOKINGS_SUCCESS,
    FETCH_NOTES,
    FETCH_NOTES_FAILED,
    FETCH_NOTES_SUCCESS, FETCH_ONCE_ALL_BOOKINGS, FETCH_ONCE_ALL_BOOKINGS_SUCCESS, FETCH_TOTAL_BOOKINGS_CANCEL, FETCH_TOTAL_BOOKINGS_CANCEL_SUCCESS,
    STOP_FETCH_BOOKINGS,
    UPDATE_BOOKING
} from "../store/types";

const INITIAL_STATE = {
    bookings: null,
    newbookings: null,
    booking_later: [],
    booking_driver_later: [],
    booking_teams: [],
    booking_news: [],
    listBookAliasDriver: [],
    active: [],
    tracked: null,
    loading: false,
    notes: null,
    totalBookingCancel: null,
    totalBookingIgnore: null,
    error: {
        flag: false,
        msg: null
    }
}

export const bookingslistreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case STOP_FETCH_BOOKINGS:
            return {
                ...state,
                bookings: null,
                active: [],
                tracked: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case FETCH_BOOKINGS:
            return {
                ...state,
                loading: true
            };
        case FETCH_BOOKINGS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case FETCH_BOOKINGS_FAILED:
            return {
                ...state,
                bookings: null,
                active: [],
                tracked: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_NEWS_BOOKINGS:
            return {
                ...state,
                loading: true
            };
        case FETCH_NEWS_BOOKINGS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case FETCH_NEWS_BOOKINGS_FAILED:
            return {
                ...state,
                booking_news: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_BOOKINGS_LATER:
            return {
                ...state,
                loading: true
            };
        case FETCH_BOOKINGS_LATER_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case FETCH_BOOKINGS_LATER_FAILED:
            return {
                ...state,
                booking_driver_later: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };


        case FETCH_BOOKINGS_TEAM:
            return {
                ...state,
                loading: true
            };
        case FETCH_BOOKINGS_TEAM_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case FETCH_BOOKINGS_TEAM_FAILED:
            return {
                ...state,
                booking_teams: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_ONCE_ALL_BOOKINGS:
            return {
                ...state,
                loading: true
            };
        case FETCH_ONCE_ALL_BOOKINGS_SUCCESS:
            return {
                ...state,
                listBookAliasDriver: action.payload.listBookAliasDriver,
                loading: false
            };

        case FETCH_NOTES:
            return {
                ...state,
                loading: true
            };
        case FETCH_NOTES_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };

        case FETCH_TOTAL_BOOKINGS_CANCEL:
            return {
                ...state,
                loading: true
            };
        case FETCH_TOTAL_BOOKINGS_CANCEL_SUCCESS:
            return {
                ...state,
                totalBookingCancel: action.payload.totalBookingCancel,
                totalBookingIgnore: action.payload.totalBookingIgnore,
                loading: false
            };

        case FETCH_NOTES_FAILED:
            return {
                ...state,
                notes: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case UPDATE_BOOKING:
            return {
                ...state
            }
        case CANCEL_BOOKING:
            return {
                ...state
            };
        default:
            return state;
    }
};