import React, {useContext, useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from '@material-ui/core/Button';
import {FirebaseContext} from "common";
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";
import {Box, Checkbox, FormControl, FormLabel, List, Typography} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import AlertDialog from "../AlertDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {colors} from "../../config/theme";
import {Rating} from "@material-ui/lab";
import {ConvertSecondToHms, ConvertTimeStampTimeForPa, ConvertTimeStampToDate} from "common/src/other/DateFunctions";
import {formatPriceRound, HiddenPhone} from "common/src/other/CommonFunctions";
import {formatNumber} from "react-native-currency-input";
import ReactAudioPlayer from "react-audio-player";
import IconButton from "@material-ui/core/IconButton";
import {View} from "react-native-web";
import {PlayCircleFilledOutlined, Refresh} from "@material-ui/icons";
import {getRecordAudio} from "common/src/other/PAApiFunctions";

const API_PA_PHONE = "a3a799635add5feb5ad2f1cb1b1680e3";
const useStyles = makeStyles(theme => ({
    titleList: {
        fontSize: 14, marginTop: 10
    }, submit: {
        backgroundColor: colors.YELLOW.primary
    }, backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)", flexDirection: 'column'
    }, row: {
        borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "rgba(255,0,0,0.74)"
    }, inputdimmed: {
        fontSize: 18, color: "#737373"
    }, carphoto: {
        height: '18px', marginRight: '10px'
    }, input: {
        borderRadius: 4, position: 'relative', backgroundColor: "#E8E8E8", fontSize: 16, padding: '5px', color: "#000"
    }, commonInputStyle: {
        borderRadius: 4, backgroundColor: "#E8E8E8",
    }, title: {color: "#000"}
}));

export default function ShowCustomerCareDialog(props) {
    const {api, paCallByIdRef, trackBookingRef} = useContext(FirebaseContext);
    const {bookingDetail, updateBooking, setAudioCallCenterApi} = api;
    const dispatch = useDispatch();
    const styles = useStyles();
    const showModal = props.showModal;
    const hideModal = props.hideModal;
    const [commonAlert, setCommonAlert] = useState({open: false, msg: ''});
    const [totalValue, setTotalValue] = useState(null);
    const [valueStar, setValueStar] = useState('');
    const [bookingDetailData, setBookingDetailData] = useState(null);
    const [isPickup, setIsPickup] = useState(null);
    const [isDrop, setIsDrop] = useState(null);
    const bookingdata = useSelector((state) => state.bookingdata.bookingDetail);
    const [loadingAudio, setLoadingAudio] = useState(null);
    const [adminNote, setAdminNote] = useState(null);

    useEffect(() => {
        if (props.rowData && props.rowData.booking_id) {
            dispatch(bookingDetail(props.rowData.booking_id));
        }
    }, [props.rowData]);

    useEffect(() => {
        if (bookingdata) {
            setBookingDetailData(bookingdata);
        }
    }, [bookingdata]);

    const handleCommonAlertClose = (e) => {
        e.preventDefault();
        setCommonAlert({open: false, msg: ''})
    };

    const loadRecordFile = async (
        source,
        from_date,
        to_date,
        id,
        time_call,
        time
    ) => {
        setLoadingAudio({[time]: true});
        let listAudio = [];

        if (source && from_date && to_date) {
            await getRecordAudio(source, from_date, to_date)
                .then(async (dataResult) => {
                    if (dataResult && dataResult.data) {
                        let listAudioFiltered = JSON.parse(dataResult.data)
                            .filter((a) => a.inbound == "")
                            .map((am) => {
                                listAudio.push(am.recording.split("/").at(-1));
                            });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            await getRecordAudio("102", from_date, to_date)
                .then((dataResult) => {
                    if (dataResult && dataResult.data) {
                        let listAudioFiltered = JSON.parse(dataResult.data)
                            .filter((a) => a.inbound == "" && a.to == source)
                            .map((am) => {
                                listAudio.push(am.recording.split("/").at(-1));
                            });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            await getRecordAudio("101", from_date, to_date)
                .then(async (dataResult) => {
                    if (dataResult && dataResult.data) {
                        let listAudioFiltered = JSON.parse(dataResult.data)
                            .filter((a) => a.inbound == "" && a.to == source)
                            .map((am) => {
                                listAudio.push(am.recording.split("/").at(-1));
                            });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            if (time_call) {
                time_call.map(async (time) => {
                    let from_date1 = ConvertTimeStampTimeForPa((time - 600) * 1000);
                    let to_date1 = ConvertTimeStampTimeForPa((time + 1800) * 1000);
                    await getRecordAudio("101", from_date1, to_date1)
                        .then((dataResult) => {
                            if (dataResult && dataResult.data) {
                                let listAudioFiltered = JSON.parse(dataResult.data)
                                    .filter((a) => a.inbound == "" && a.to == props.phone)
                                    .map((am) => {
                                        listAudio.push(am.recording.split("/").at(-1));
                                    });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    await getRecordAudio("102", from_date1, to_date1)
                        .then((dataResult) => {
                            if (dataResult && dataResult.data) {
                                let listAudioFiltered = JSON.parse(dataResult.data)
                                    .filter((a) => a.inbound == "" && a.to == props.phone)
                                    .map((am) => {
                                        listAudio.push(am.recording.split("/").at(-1));
                                    });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                });
            }

            if (listAudio) {
                setAudioCallCenterApi(id, listAudio);
            }
            setLoadingAudio(null);
        } else {
            setLoadingAudio(null);
            alert("Thiếu thông tin");
            return null;
        }
    };

    const formatPrice = (value) => {
        return formatPriceRound(value, formatNumber) + "(đ)";
    }

    return (<Dialog
        open={showModal}
        onClose={hideModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xl"}
        fullWidth={true}
    >
        <DialogTitle
            id="alert-dialog-title">{"Cuốc khách - "}{bookingDetailData ? bookingDetailData.customer_note : "đang tải dữ liệu"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {bookingDetailData && props.rowData ?
                    <FormControl>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <p>
                                    <b>Bí Danh / SĐT khách: </b><span>{props.callObj && props.callObj.alias ? props.callObj.alias : "Chưa đặt"} / {HiddenPhone(props.rowData.source)}</span><br/>
                                    <b>Lái xe nhận: </b><span>{bookingDetailData.codeDriver + " (" + bookingDetailData.vehicle_number + " )"}</span><br/>
                                </p>
                                <p>
                                    <b>Thời gian nhận khách: </b><span>{ConvertTimeStampToDate(bookingDetailData.accept_time)}</span><br/>
                                    <b>Thời gian bật đồng hồ : </b><span>{bookingDetailData.trip_start_time}</span><br/>
                                    <b>Thời gian trả khách: </b><span>{ConvertTimeStampToDate(bookingDetailData.trip_end_time * 1000)}</span>
                                </p>
                                <p>
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label_don">Điểm
                                            đón: {bookingDetailData.pickupAddress}</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label_don"
                                            name="row-radio-buttons-group_don"
                                            value={props.rowData && props.rowData.hasUpdateReport ? props.rowData.pickup_is_ok : isPickup}
                                            onChange={(e, value) => {
                                                setIsPickup(value);
                                            }
                                            }
                                        >
                                            <FormControlLabel value="dung" control={<Radio/>} label="Đúng"/>
                                            <FormControlLabel value="sai" control={<Radio/>} label="Sai"/>
                                        </RadioGroup>
                                    </FormControl>
                                </p>
                                <p>
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label_tra">Điểm
                                            trả: {"Điểm ABC"}</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label_tra"
                                            name="row-radio-buttons-group_tra"
                                            value={props.rowData && props.rowData.hasUpdateReport ? props.rowData.drop_is_ok : isDrop}
                                            onChange={(e, value) => {
                                                setIsDrop(value);
                                            }
                                            }
                                        >
                                            <FormControlLabel value="dung" control={<Radio/>} label="Đúng"/>
                                            <FormControlLabel value="sai" control={<Radio/>} label="Sai"/>
                                        </RadioGroup>
                                    </FormControl>
                                </p>
                                <hr/>
                                <p>
                                    <b>Km đón / chở (khách): </b><span>KM Đón / {Math.round(bookingDetailData.estimateDistance * 100) / 100}(km)</span>
                                </p>
                                <p>
                                    <b>Thời gian chờ / có khách: </b><span>{ConvertSecondToHms(bookingDetailData.waitingTime)} / Thời gian có khách</span>
                                </p>
                                <p>
                                    <b>Tiền app / Nhập số tiền Khách hàng thanh toán thực tế cho lái xe: </b><span>{formatPrice(bookingDetailData.customer_paid)}</span> /
                                    <span>
                                    <TextField
                                        id="text_tien_thuc_thu"
                                        disabled={false}
                                        value={props.rowData && props.rowData.hasUpdateReport ? props.rowData.total_paid : totalValue}
                                        placeholder={"Nhập số tiền Khách hàng thanh toán thực tế cho lái xe"}
                                        onChange={(event) => {
                                            setTotalValue(event.target.value);
                                        }}
                                    />
                                </span>
                                </p>
                                <hr/>
                                <Box
                                >
                                    <Typography component="legend">Đánh giá mức độ hài lòng của khách hàng đối với tài
                                        xế :</Typography>
                                    <Rating name="half-rating" defaultValue={2.5} precision={0.5}
                                            value={props.rowData && props.rowData.hasUpdateReport ? props.rowData.rating : valueStar}
                                            size="large"
                                            onChange={(event, newValue) => {
                                                setValueStar(newValue);
                                            }}/>
                                </Box>
                                <hr/>
                                {props.isBooking ?
                                    <p>Ghi âm: </p> : null}
                                {props.rowData.audios && props.isBooking ? (
                                    <List
                                        sx={{
                                            width: "100%",
                                            maxWidth: 100,
                                            bgcolor: "background.paper",
                                            position: "relative",
                                            overflow: "auto",
                                            maxHeight: 300,
                                            "& ul": {padding: 0},
                                        }}
                                    >
                                        {props.rowData.audios.map((a, index) => {
                                            return (
                                                <li key={`section-${index}`}>
                                                    <ReactAudioPlayer
                                                        src={
                                                            "https://crm.pavietnam.vn/api/playRecording.php?api_key=" +
                                                            API_PA_PHONE +
                                                            "&recording_file=" +
                                                            a
                                                        }
                                                        style={{
                                                            backgroundColor: a.includes("out")
                                                                ? colors.YELLOW.primary
                                                                : colors.GREEN.prime,
                                                        }}
                                                        title={
                                                            "" + a.split("-").at(-3) + " : " + a.split("-").at(-2)
                                                        }
                                                        controls
                                                    />
                                                </li>
                                            );
                                        })}

                                        <li key={`section-${props.rowData.audios.length}`}>
                                            <IconButton
                                                aria-label="Nghe ghi âm"
                                                color="secondary"
                                                size="medium"
                                                disabled={loadingAudio && loadingAudio[props.rowData.time]}
                                                onClick={async () => {
                                                    await loadRecordFile(
                                                        props.rowData.source,
                                                        ConvertTimeStampTimeForPa(
                                                            (parseInt(props.rowData.time) - 600) * 1000
                                                        ),
                                                        ConvertTimeStampTimeForPa(
                                                            (parseInt(props.rowData.time) + 1800) * 1000
                                                        ),
                                                        props.rowData.id,
                                                        props.rowData.time_call_out ? props.rowData.time_call_out : [],
                                                        props.rowData.time
                                                    );
                                                }}
                                            >
                                                {loadingAudio && loadingAudio[props.rowData.time] ? (
                                                    <View>{"Đang tải"}</View>
                                                ) : (
                                                    <Refresh fontSize="inherit" color={"action"}/>
                                                )}
                                            </IconButton>
                                        </li>
                                    </List>
                                ) : props.isBooking ? (
                                    <IconButton
                                        aria-label="Nghe ghi âm"
                                        color="secondary"
                                        size="medium"
                                        disabled={loadingAudio && loadingAudio[props.rowData.time]}
                                        onClick={async () => {
                                            await loadRecordFile(
                                                props.rowData.source,
                                                ConvertTimeStampTimeForPa(
                                                    (parseInt(props.rowData.time) - 600) * 1000
                                                ),
                                                ConvertTimeStampTimeForPa(
                                                    (parseInt(props.rowData.time) + 1800) * 1000
                                                ),
                                                props.rowData.id,
                                                props.rowData.time_call_out ? props.rowData.time_call_out : [],
                                                props.rowData.time
                                            );
                                        }}
                                    >
                                        {loadingAudio && loadingAudio[props.rowData.time] ? (
                                            <span>{"Đang tải"}</span>
                                        ) : (
                                            <PlayCircleFilledOutlined fontSize="inherit" color={"action"}/>
                                        )}
                                    </IconButton>
                                ) : null}
                                {props.isBooking ?
                                    <>
                                        <hr/>
                                        <p>
                                            <b>Ghi chú quản trị: </b>
                                            <TextField
                                                id="text_tien_thuc_thu"
                                                disabled={false}
                                                fullWidth={true}
                                                value={adminNote ? adminNote : (props.rowData && props.rowData.admin_note ? props.rowData.admin_note : '')}
                                                placeholder={"Nhập ghi chú quản trị"}
                                                onChange={(event) => {
                                                    setAdminNote(event.target.value);
                                                }}
                                            />
                                        </p></> : null}
                                <hr/>
                            </Grid>

                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                {!props.isBooking ?
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        disabled={props.rowData && props.rowData.hasUpdateReport}
                                        id={"notifybutton_save"}
                                        className={styles.submit}
                                        onClick={() => {
                                            if (props.rowData && props.rowData.id) {
                                                paCallByIdRef(props.rowData.id).update({
                                                    rating: valueStar,
                                                    total_paid: totalValue,
                                                    pickup_is_ok: isPickup,
                                                    drop_is_ok: isDrop,
                                                    hasUpdateReport: true
                                                }).then(r => {
                                                    if (bookingDetailData.status === 'PAID') {
                                                        bookingDetailData.status = 'COMPLETE';
                                                        bookingDetailData.rating = valueStar;
                                                        bookingDetailData.hasUpdateReport = true;
                                                        dispatch(updateBooking(bookingDetailData));
                                                    }
                                                }).then(res => {
                                                    alert("Báo cáo đã cập nhật, bạn không thể thay đổi!");
                                                    hideModal();
                                                }).catch(error => {
                                                    alert("Báo cáo đã thất bại, vui lòng liên hệ quản trị!");
                                                    hideModal();
                                                });
                                            }
                                        }}
                                    >
                                        {"Gửi báo cáo"}
                                    </Button> :
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        disabled={props.rowData && props.rowData.hasUpdateReportAdmin}
                                        id={"notifybutton_save"}
                                        className={styles.submit}
                                        onClick={() => {
                                            if (props.rowData && props.rowData.id) {
                                                paCallByIdRef(props.rowData.id).update({
                                                    admin_node: adminNote,
                                                    hasUpdateReportAdmin: true
                                                }).then(r => {
                                                    if (bookingDetailData.id) {
                                                        trackBookingRef(bookingDetailData.id + "/hasUpdateReportAdmin").set(true);
                                                    }
                                                }).then(res => {
                                                    alert("Báo cáo đã cập nhật, bạn không thể thay đổi!");
                                                    hideModal();
                                                }).catch(error => {
                                                    alert("Báo cáo đã thất bại, vui lòng liên hệ quản trị!");
                                                    hideModal();
                                                });
                                            }
                                        }}
                                    >
                                        {"Gửi nhận xét"}
                                    </Button>}
                            </Grid>

                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="red"
                                    size="small"
                                    id={"cancelbutton_notify"}
                                    className={styles.submit}
                                    onClick={hideModal}
                                >
                                    {"Bỏ qua"}
                                </Button>
                            </Grid>
                        </Grid>
                        <AlertDialog titleConfirm={commonAlert.titleConfirm ? commonAlert.titleConfirm : null}
                                     open={commonAlert.open}
                                     onClose={commonAlert.onClose ? commonAlert.onClose : handleCommonAlertClose}
                                     onConfirm={commonAlert.onConfirm ? commonAlert.onConfirm : null}>{commonAlert.msg}</AlertDialog>
                    </FormControl> : null}
            </DialogContentText>
        </DialogContent>
    </Dialog>);
}
