import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Circle, GoogleMap, InfoWindow, Marker, Polygon, withGoogleMap, withScriptjs} from "react-google-maps";
import {language} from "config";
import GoogleMapsAutoComplete from "./GoogleMapsAutoComplete";
import {Grid} from "@material-ui/core";
import AddBookings from "../views/AddBookings";
import throttle from 'lodash/throttle';
import {FirebaseContext} from "common";
import {fetchAddressFullfromCoords} from "common/src/other/GoogleAPIFunctions";
import GroupDrivers from "./MenuCar/GroupDrivers";
import InfoWindowDriver from "./InfoWinfows/InfoWindowDriver";
import MarkerQueue from "./Markers/MarkerQueue";
import {useDispatch, useSelector} from "react-redux";
import UpdatePickupBooking from "../views/UpdatePickupBooking";
import AvailableCarQueue from "./Markers/AvailableCarQueue";
import PopupBookingModal from "./Dialogs/PopupBookingModal";

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = {current: null};

const Map = withScriptjs(withGoogleMap(props => {
        const loaded = useRef(false);
        const {api, bookingRef} = useContext(FirebaseContext);
        const {
            userDetail,
            bookingDetail,
            updateTripPickup,
            updateTripDrop
        } = api;
        const dispatch = useDispatch();
        if (typeof window !== 'undefined' && !loaded.current && !window.google) {
            if (!document.querySelector('#google-maps')) {
                loadScript(
                    props.googleMapURL,
                    document.querySelector('head'),
                    'google-maps',
                );
            }

            loaded.current = true;
        }
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
        const fetch = useMemo(
            () =>
                throttle((request, callback) => {
                    autocompleteService.current.getPlacePredictions(request, callback);
                }, 200),
            [],
        );

        const [markers, setMarkers] = useState([]);
        const [hideCircle, setHideCircle] = useState(false);
        const [center, setCenter] = useState(null);
        const [carInfo, setCarInfo] = useState(null);
        const auth = useSelector(state => state.auth);
        const bookingdata = useSelector(state => state.bookingdata);
        const [markerSelected, setMarkerSelected] = useState(null);
        const [searchAddress, setSearchAddress] = useState(null);
        const [callCenterDetail, setCallCenterDetail] = useState(null);
        const [bookingObj, setBookingObj] = useState(null);
        const [booking, setBooking] = useState(null);
        const [driverActive, setDriverActive] = useState(null);
        const mapRef = useRef();
        const tripdata = useSelector(state => state.tripdata);

        const onMapLoad = useCallback((map) => {
            mapRef.current = map;
        }, []);


        const onMapClick = useCallback((mapsMouseEvent) => {
            let addressObjFull = fetchAddressFullfromCoords(mapsMouseEvent.latLng.lat() + "," + mapsMouseEvent.latLng.lng());
            addressObjFull.then(function (result) {
                if (props.isPickup && tripdata.selected == 'drop') {
                    dispatch(updateTripDrop({
                        lat: mapsMouseEvent.latLng.lat(),
                        lng: mapsMouseEvent.latLng.lng(),
                        add: result.formatted_address,
                        from: "map"
                    }));
                }

                if (props.isPickup && tripdata.selected == 'pickup') {
                    dispatch(updateTripPickup({
                        lat: mapsMouseEvent.latLng.lat(),
                        lng: mapsMouseEvent.latLng.lng(),
                        add: result.formatted_address,
                        from: "map"
                    }));
                }

                if (props.isPickup) {
                } else {
                    fetch({input: result.formatted_address}, (results) => {
                        if (results) {
                            results[0].coords = {
                                lat: result.geometry.location.lat,
                                lng: result.geometry.location.lng
                            }
                            results[0].placeDetails = result;
                            setHideCircle(!hideCircle);

                            setMarkers(() => [
                                {
                                    lat: mapsMouseEvent.latLng.lat(),
                                    lng: mapsMouseEvent.latLng.lng(),
                                    time: new Date(),
                                    address: results[0],
                                    address_string: result.formatted_address,
                                    circle: {
                                        radius: 3000,
                                        options: {
                                            strokeColor: "#ff0000"
                                        }
                                    }
                                }]);
                        }
                    });
                }
            });
        });


        /*useEffect(async () => {
            let listNoteBooking = [];
            await bookingRef.orderByChild("type").equalTo("TT").once("value", snapshot => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    const bookings = Object.keys(data)
                        .map((i) => {
                            data[i].id = i;
                            return data[i];
                        });
                    for (let i = 0; i < bookings.length; i++) {
                        if (bookings[i].driver && bookings[i].pickup && bookings[i].customer_note) {
                            bookings[i].pickup.note = bookings[i].customer_note;
                            listNoteBooking.push(bookings[i].pickup);
                        }
                    }
                }
            });
            if (listNoteBooking) {
                setListNoteRadius(listNoteBooking);
            }
        }, []);*/

        useEffect(() => {
            let markerList = [];
            if (props.isPickup && tripdata.pickup && tripdata.pickup.lat) {
                markerList[0] = {
                    lat: tripdata.pickup.lat,
                    lng: tripdata.pickup.lng,
                    time: new Date(),
                    type: 'pickup',
                    address_string: tripdata.pickup.add,
                    icon: {
                        url: (require('../assets/img/pin_pickup.png').default),
                        scaledSize: new window.google.maps.Size(25, 32)
                    },
                    circle: {
                        radius: 3000,
                        options: {
                            strokeColor: "#ff0000"
                        }
                    }
                }
            }

            if (props.isPickup && tripdata.drop && tripdata.drop.lat) {
                markerList[1] = {
                    lat: tripdata.drop.lat,
                    lng: tripdata.drop.lng,
                    time: new Date(),
                    type: 'drop',
                    address_string: tripdata.drop.add,
                    icon: {
                        url: (require('../assets/img/pin.png').default),
                        scaledSize: new window.google.maps.Size(30, 32)
                    },
                    circle: {
                        radius: 3000,
                        options: {
                            strokeColor: "#ff0000"
                        }
                    }
                }
            }

            setMarkers(markerList);

            /* if (tripdata.drop && tripdata.pickup) {
                 console.log(getDirections(
                     {latitude: tripdata.pickup.lat, longitude: tripdata.pickup.lng},
                     {latitude: tripdata.pickup.lat, longitude: tripdata.drop.lng}
                 ));
             }*/

        }, [tripdata]);

        //{ latitude: lat, longitude: lng }
        const getDirections = (origin, destination) => {
            const directionsService = new window.google.maps.DirectionsResult();
            if (origin !== null && destination !== null) {
                directionsService.route(
                    {
                        origin: origin,
                        destination: destination,
                        travelMode: new window.google.maps.TravelMode.DRIVING
                    },
                    (result, status) => {
                        if (status === new window.google.maps.DirectionsStatus.OK) {
                            return result;
                        } else {
                            return null;
                            console.error(`error fetching directions ${result}`);
                        }
                    }
                );
            } else {
                console.log('Please mark your destination in the map first!');
                return null;
            }
        };

        useEffect(() => {
            if (props.center) {
                setCenter(props.center);
            }
        }, [props.center]);

        useEffect(() => {
            if (props.callDetail) {
                setCallCenterDetail(props.callDetail);
                if (props.callDetail.accept_driver) {
                    dispatch(userDetail(props.callDetail.accept_driver));
                }
                if (props.callDetail.booking_id) {
                    dispatch(bookingDetail(props.callDetail.booking_id));
                }
            }
        }, [props.callDetail]);


        useEffect(() => {
            if (props.bookingObject) {
                setBookingObj(props.bookingObject);
            }
        }, [props.bookingObject]);

        useEffect(() => {
            if (auth.user) {
                setDriverActive(auth.user);
            }
        }, [auth]);

        useEffect(() => {
            if (bookingdata.bookingDetail) {
                setBooking(bookingdata.bookingDetail);
            }
        }, [bookingdata]);

        const loadCarInfo = useCallback((driver) => {
            if (driver) {
                setCarInfo(driver);
                setCenter(driver.location);
            } else {
                setCarInfo(null);
            }
        });

        useEffect(() => {
            if (driverActive && driverActive.location) {
                setCenter(driverActive.location);
            }
        }, [driverActive]);

        const onMarkerDragEnd = useCallback((e, markerPick, index) => {
            markerPick.lat = e.latLng.lat();
            markerPick.lng = e.latLng.lng();
            markerPick.time = new Date();
            markers[index] = markerPick;
            setMarkers(markers);
        });

        const closeCallback = useCallback(
            () => {
                setDriverActive(null);
                setBooking(null);
                setBookingObj(null);
            },
            [],
        );

        return (
            <>
                {props.isPickup ?
                    <GroupDrivers
                        loadCarInfo={loadCarInfo}
                    /> : null}
                {!props.isPickup ? <Grid item xs={12}>
                    <GoogleMapsAutoComplete
                        apiOptions={{language: 'vi', region: 'vn'}}
                        variant={"outlined"}
                        placeholder={language.pickup_location}
                        value={searchAddress}
                        onChange={
                            (value) => {
                                setSearchAddress(value);
                                if (value && value.coords) {
                                    setCenter({lat: value.coords.lat, lng: value.coords.lng});
                                }
                            }
                        }
                    />
                </Grid> : null}
                <br/>
                <GoogleMap
                    ref={mapRef}
                    defaultZoom={props.group && props.group == 'Vùng tự do' ? 17 : 13}
                    defaultCenter={center}
                    zoom={props.group && props.group == 'Vùng tự do' ? 17 : 13}
                    center={center}
                    onClick={props.group && props.group == 'Vùng tự do' ? onMapClick : null}
                    onLoad={onMapLoad}
                >
                    {props.isPickup && props.polygons ?
                        <Polygon
                            path={props.polygons}
                            editable={false}
                            options={{
                                strokeColor: "rgba(61,146,132,0.40)",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: "rgba(61,146,132,0.40)",
                                fillOpacity: 0.8,
                                polygonKey: 1
                            }}
                        /> : null
                    }


                    {markers ? markers.map((markerPick, index) => {
                        var icon = null;
                        if (markerPick.icon) {
                            icon = markerPick.icon;
                        } else {
                            if (props.isPickup && markerPick.type == 'pickup') {
                                icon = {
                                    url: (require('../assets/img/pin_pickup.png').default),
                                    scaledSize: new window.google.maps.Size(25, 32)
                                }
                            }
                            if (props.isPickup && markerPick.type == 'drop') {
                                icon = {
                                    url: (require('../assets/img/pin.png').default),
                                    scaledSize: new window.google.maps.Size(25, 32)
                                }
                            }
                        }
                        return (
                            <>
                                <React.Fragment key={markerPick.lat + markerPick.lng}>
                                    <Marker
                                        key={markerPick.time.toISOString()}
                                        position={{lat: markerPick.lat, lng: markerPick.lng}}
                                        icon={icon}
                                        onClick={() => {
                                            if (props.isPickup) {
                                                if (markerPick.type && markerPick.type == 'pickup') {
                                                    dispatch(api.updatSelPointType('pickup'));
                                                }
                                                if (markerPick.type && markerPick.type == 'drop') {
                                                    dispatch(api.updatSelPointType('drop'));
                                                }
                                            } else {
                                                loadCarInfo(null);
                                                setMarkerSelected(markerPick);
                                                setHideCircle(!hideCircle);
                                            }
                                        }}
                                        draggable={true}
                                        onDragEnd={(e) => {
                                            markerPick.lat = e.latLng.lat();
                                            markerPick.lng = e.latLng.lng();
                                            let addressObjFull = fetchAddressFullfromCoords(markerPick.lat + "," + markerPick.lng);
                                            addressObjFull.then(function (result) {
                                                if (props.isPickup && markerPick.type == 'pickup') {
                                                    dispatch(updateTripPickup({
                                                        lat: e.latLng.lat(),
                                                        lng: e.latLng.lng(),
                                                        add: result.formatted_address,
                                                        from: "map"
                                                    }));
                                                    //props.getPickupCallback(markerPick);
                                                }
                                                if (props.isPickup && markerPick.type == 'drop') {
                                                    dispatch(updateTripDrop({
                                                        lat: e.latLng.lat(),
                                                        lng: e.latLng.lng(),
                                                        add: result.formatted_address,
                                                        from: "map"
                                                    }));
                                                }

                                                fetch({input: result.formatted_address}, (results) => {
                                                    if (results) {
                                                        results[0].coords = {
                                                            lat: result.geometry.location.lat,
                                                            lng: result.geometry.location.lng
                                                        }
                                                        results[0].placeDetails = result;
                                                        let selectMk = {
                                                            lat: markerPick.lat,
                                                            lng: markerPick.lng,
                                                            type: markerPick.type ? markerPick.type : null,
                                                            time: new Date(),
                                                            address: results[0],
                                                            address_string: result.formatted_address,
                                                            circle: {
                                                                radius: 3000,
                                                                options: {
                                                                    strokeColor: "#ff0000"
                                                                }
                                                            }
                                                        };

                                                        if (props.isPickup) {
                                                        } else {
                                                            markers[index] = selectMk;
                                                            setMarkers(markers);
                                                            setMarkerSelected(selectMk);
                                                        }
                                                    }
                                                });
                                            });
                                        }}
                                        title={language.click_to_book}

                                    />
                                    {!props.isPickup && markerPick.circle && (
                                        <Circle
                                            defaultCenter={{
                                                lat: parseFloat(markerPick.lat),
                                                lng: parseFloat(markerPick.lng)
                                            }}
                                            visible={hideCircle}
                                            radius={markerPick.circle.radius}
                                            options={markerPick.circle.options}
                                            onClick={() => {
                                                setHideCircle(!hideCircle);
                                            }}
                                        />
                                    )}
                                </React.Fragment>
                            </>
                        );
                    }) : null}

                    {/*{listNoteRadius ?
                        listNoteRadius.map((markerPick, index) => {
                            return (
                                <>
                                    <React.Fragment key={"" + (index + 1).toString + "f"}>
                                        <Circle
                                            key={"" + (index + 1).toString + "c"}
                                            defaultCenter={{
                                                lat: markerPick.lat,
                                                lng: markerPick.lng
                                            }}
                                            radius={60}
                                            options={{
                                                fillColor: "rgba(0,89,255,0.56)",
                                                fillOpacity: 0.1,
                                                strokeColor: "rgba(61,146,132,0.2)",
                                                strokeOpacity: 0.1,
                                                strokeWeight: 0.1,
                                            }}
                                        />
                                    </React.Fragment>
                                </>
                            )
                        })
                        : null}*/}

                    {!props.isPickup && markerSelected && markerSelected.address ? (
                        <InfoWindow
                            zIndex={30}
                            options={{marginTop: 101}}
                            position={{lat: Number(markerSelected.lat), lng: Number(markerSelected.lng)}}
                            onCloseClick={() => {
                                setMarkerSelected(null);
                            }}
                        >
                            {(driverActive && booking) || bookingObj ?
                                <UpdatePickupBooking {...props} address={markerSelected.address} driverActive={driverActive} booking={booking} bookingObj={bookingObj} closeCallback={closeCallback}/>
                                : <AddBookings address={markerSelected.address}/>}
                        </InfoWindow>
                    ) : null}

                    {props.isPickup ?
                        <MarkerQueue loadCarInfo={loadCarInfo}/> : null}
                    {props.isPickup && props.groupApply ?
                        <AvailableCarQueue loadCarInfo={loadCarInfo} groupApply={props.groupApply}/> : null}
                    {props.isPickup && carInfo ?
                        <Marker
                            position={{lat: carInfo.location.lat, lng: carInfo.location.lng}}
                            key={carInfo.id}
                            options={
                                {
                                    icon: require(`../assets/img/display_` + carInfo.carStatus).default,
                                    size: {width: 32, height: 32},
                                }
                            }
                            onClick={async () => {
                                setMarkerSelected(null);
                                loadCarInfo(carInfo);
                            }}
                            label={
                                {
                                    color: '#ffffff',
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    text: carInfo.codeDriver,
                                    padding: 10,
                                }
                            }
                        >
                        </Marker>
                        : null
                    }
                    {props.isPickup && carInfo ?
                        <InfoWindowDriver carInfo={carInfo} loadCarInfo={loadCarInfo}/> : null}

                    {props.openDialogBooking ?
                        <PopupBookingModal isOpen={props.openDialogBooking} handleCloseDialogBooking={props.handleCloseDialogBooking}/> : null}
                </GoogleMap>
            </>
        );
    }
));

export default Map;
