const GetDistance = (lat1, lon1, lat2, lon2) => {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    } else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344;
        return dist;
    }
};
export const HiddenPhone = (phone) => {
    phone = setCharAt(phone, 3, '*');
    phone = setCharAt(phone, 4, '*');
    phone = setCharAt(phone, 5, '*');
    return phone;
}

export const setCharAt = (str, index, chr) => {
    if (index > str.length - 1) return str;
    return str.substring(0, index) + chr + str.substring(index + 1);
}

export const findIndexByValueOfArray = (needle, array) => {
    var results = [];
    var idx = array.indexOf(needle);
    while (idx != -1) {
        results.push(idx);
        idx = array.indexOf(needle, idx + 1);
    }
    return results;
}

export const addCharToString = (char, string, total) => {
    for (let i = 0; i < total; i++) {
        char = char.toString() + string.toString();
    }
    return char;
}

/***
 *
 * @param usr
 * @param bankinh
 * @param pickup {lat, lng}
 * @param listDrivers
 * @param carType
 * @param resultOut
 * @param resultIn
 * @param group
 * @returns {Promise<unknown>}
 */
export function getUserValidSign(usr, bankinh, pickup = null, listDrivers, carType, resultOut, resultIn = [], group = null, currentAreaId = [], resultInArea = []) {
    return new Promise((resolve) => {
        if (usr.id && usr.pushToken && usr.codeDriver && usr.carType && usr.groupDriver
            && usr.queue === false && usr.driverActiveStatus === true && usr.approved === true && usr.usertype === 'driver' && !usr.lock_driver_status
            && usr.pushToken && usr.id
            && usr.carTypeCode === carType
        ) {
            var originalDistance = -1;
            if (group) {
                if (group.area_ids && usr.currentAreaId) {
                    let areas_in_group = group.area_ids;
                    if (areas_in_group.indexOf(usr.currentAreaId) > -1) {
                        resultInArea.push({id: usr.id, codeDriver: usr.codeDriver.toString().trim(), pushToken: usr.pushToken, location: usr.location});
                    }
                }
            }
            if (pickup && pickup.lat && pickup.lng) {
                originalDistance = GetDistance(pickup.lat, pickup.lng, usr.location.lat, usr.location.lng);
                if (parseInt(usr.groupDriver) !== 10
                    && parseInt(usr.groupDriver) !== 0
                    && parseInt(usr.groupDriver) !== 1
                    && parseInt(usr.groupDriver) !== 9
                    && parseInt(usr.groupDriver) !== 12
                    && originalDistance <= parseFloat(bankinh)) {
                    resultOut.push({id: usr.id, codeDriver: usr.codeDriver.toString().trim(), pushToken: usr.pushToken, location: usr.location});
                }
                if (resultIn && group && group.value) {
                    let listDriverCode = group.codes ? group.codes.split(',') : [];
                    if (parseInt(group.value.toString().trim()) === parseInt(usr.groupDriver.toString().trim())) {
                        if (parseInt(group.value.toString().trim()) !== 10
                            && parseInt(group.value.toString().trim()) !== 0
                            && parseInt(group.value.toString().trim()) !== 1
                            && parseInt(group.value.toString().trim()) !== 9
                            && parseInt(group.value.toString().trim()) !== 12
                            && originalDistance <= parseFloat(bankinh)
                            && listDriverCode.includes(usr.codeDriver.toString().trim())) {
                            resultIn.push({id: usr.id, codeDriver: usr.codeDriver.toString().trim(), pushToken: usr.pushToken, location: usr.location});
                        }
                    }
                }
            } else {
                if (currentAreaId) {
                    if (usr.group_info
                        && usr.group_info.group
                        && parseInt(usr.groupDriver) !== 10
                        && parseInt(usr.groupDriver) !== 0
                        && parseInt(usr.groupDriver) !== 1
                        && parseInt(usr.groupDriver) !== 9
                        && parseInt(usr.groupDriver) !== 12
                        && usr.currentAreaId
                        && parseInt(currentAreaId.toString().trim()) === parseInt(usr.currentAreaId.toString().trim())) {
                        resultOut.push({id: usr.id, codeDriver: usr.codeDriver.toString().trim(), pushToken: usr.pushToken, location: usr.location});
                    }

                    if (usr.group_info
                        && usr.group_info.group
                        && parseInt(usr.groupDriver) !== 10
                        && parseInt(usr.groupDriver) !== 0
                        && parseInt(usr.groupDriver) !== 1
                        && parseInt(usr.groupDriver) !== 9
                        && parseInt(usr.groupDriver) !== 12
                        && usr.currentAreaId
                        && group
                        && parseInt(currentAreaId.toString().trim()) === parseInt(usr.currentAreaId.toString().trim())) {
                        let listDriverCode = group ? group.codes.split(',') : [];
                        if (listDriverCode.includes(usr.codeDriver.toString().trim())) {
                            resultIn.push({id: usr.id, codeDriver: usr.codeDriver.toString().trim(), pushToken: usr.pushToken, location: usr.location});
                        }
                    }

                } else {
                    if (parseInt(usr.groupDriver) !== 10
                        && parseInt(usr.groupDriver) !== 0
                        && parseInt(usr.groupDriver) !== 1
                        && parseInt(usr.groupDriver) !== 9
                        && parseInt(usr.groupDriver) !== 12
                        && group
                        && usr.currentAreaId) {
                        let listAreaCode = group.area_ids ? group.area_ids : [];
                        if (listAreaCode.includes(usr.currentAreaId.toString().trim())) {
                            resultOut.push({id: usr.id, codeDriver: usr.codeDriver.toString().trim(), pushToken: usr.pushToken, location: usr.location});
                        }
                    }
                    if (group && group.value) {
                        let listDriverCode = group.codes ? group.codes.split(',') : [];
                        if (parseInt(group.value.toString().trim()) === parseInt(usr.groupDriver.toString().trim())) {
                            if (parseInt(group.value.toString().trim()) !== 10
                                && parseInt(group.value.toString().trim()) !== 0
                                && parseInt(group.value.toString().trim()) !== 1
                                && parseInt(group.value.toString().trim()) !== 9
                                && parseInt(group.value.toString().trim()) !== 12
                                && listDriverCode.includes(usr.codeDriver.toString().trim())) {
                                resultIn.push({id: usr.id, codeDriver: usr.codeDriver.toString().trim(), pushToken: usr.pushToken, location: usr.location});
                            }
                        }
                    }
                }

            }
        }
        resolve({resultIn: resultIn, resultOut: resultOut, resultInArea: resultInArea});
    });
}

export function validPhoneVN(phonenumber) {
    var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    if (phonenumber.match(phoneno)) {
        return true;
    } else {
        return false;
    }
}

export function validDriverCode(code) {
    if (code.charAt(0) == 0) {
        return false;
    } else {
        if (code && parseInt(code) > 10 && parseInt(code) <= 999) {
            return true;
        } else {
            return false;
        }
    }
}

export function validBienSo(code) {
    return true;
    /*const codeno = /^[0-9]{2}[A-Z]{1}[0-9]{5}$/;
    if (code && code.match(codeno)) {
        return true;
    } else {
        return false;
    }*/
}

export function removeAccents(str) {
    return str.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/đ/g, 'd').replace(/Đ/g, 'D');
}

export function estimateTimePickup(quangduong = 0, vantoc = 40) {
    if (quangduong > 0) {
        return ((quangduong / vantoc) * 3600) + 60;
    }
}

export function formatDisplayAddress(address) {
    if (address) {
        let addressArray = address.split(",");
        if (addressArray && addressArray.length >= 2) {
            return addressArray[0] + ', ' + addressArray[1] + (addressArray[2] ? ', ' + addressArray[2] : '') + (addressArray[3] ? ', ' + addressArray[3] : '') + (addressArray[4] ? ', ' + addressArray[4] : '');
        } else {
            return '';
        }
    } else {
        return '';
    }
}


export function formatDisplayAddressShort(address) {
    if (address) {
        let addressArray = address.split(",");
        if (addressArray && addressArray.length >= 2) {
            return addressArray[0] + ', ' + addressArray[1];
        } else {
            return '';
        }
    } else {
        return '';
    }
}


export function formatDisplayAddress2(address) {
    if (address) {
        let addressArray = address.split(",");
        if (addressArray && addressArray.length >= 2) {
            return addressArray[0] + ', ' + addressArray[1] + '...';
        } else {
            return '';
        }
    } else {
        return '';
    }
}

export function formatDisplayDate(datetime) {
    if (datetime) {
        let timeArray = datetime.split(" ");
        if (timeArray && timeArray.length >= 2) {
            return timeArray[5] + ' ' + timeArray[0] + '/' + timeArray[2] + '/' + timeArray[4];
        } else {
            return '';
        }
    } else {
        return '';
    }
}

export function formatDisplayDateSmart(datetime) {
    if (datetime) {
        let timeArray = datetime.split(" ");
        if (timeArray && timeArray.length >= 2) {
            return timeArray[5] + ' ' + timeArray[0] + '/' + timeArray[2] + '/' + timeArray[4];
        } else {
            return '';
        }
    } else {
        return '';
    }
}

export function formatPriceRound(value, formatNumber) {
    value = parseFloat(value);
    value = Number((value).toFixed(0));
    let priceFormat = formatNumber(value, {
        separator: ',',
        precision: 0,
        delimiter: '.',
        ignoreNegative: false,
    });
    let priceFormatArray = priceFormat.split('.');
    var result = '';
    if (priceFormatArray && priceFormatArray.length > 1) {
        var thousan = priceFormatArray[priceFormatArray.length - 1];
        if (parseFloat(thousan) > 500) {
            thousan = 1000;
        } else {
            thousan = 0;
        }
        priceFormatArray.map((p, index) => {
            if (index !== priceFormatArray.length - 1) {
                result = result ? result + '' + p.toString() : p.toString();
            }
        });
        result = (parseInt(result) * 1000) + parseInt(thousan);
    }
    return formatNumber(result, {
        separator: ',',
        precision: 0,
        delimiter: '.',
        ignoreNegative: false,
    });
}

export function object_equals(x, y) {
    if (x === y) return true;
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x) {
        if (!x.hasOwnProperty(p)) continue;
        // other properties were tested using x.constructor === y.constructor

        if (!y.hasOwnProperty(p)) return false;
        // allows to compare x[ p ] and y[ p ] when set to undefined

        if (x[p] === y[p]) continue;
        // if they have the same strict value or identity then they are equal

        if (typeof (x[p]) !== "object") return false;
        // Numbers, Strings, Functions, Booleans must be strictly equal

        if (!object_equals(x[p], y[p])) return false;
        // Objects and Arrays must be tested recursively
    }

    for (p in y)
        if (y.hasOwnProperty(p) && !x.hasOwnProperty(p))
            return false;
    // allows x[ p ] to be set to undefined

    return true;
}

export function getDaysInMonth(month, year) {
    return Array.from(
        {length: new Date(year, month, 0).getDate()}, // get next month, zeroth's (previous) day
        (_, i) => new Date(year, month - 1, i + 1)    // get current month (0 based index)
    );
}


export function groupByArray(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export function isAudio(note) {
    if (note.includes("https://firebasestorage.googleapis.com/v0/b/eto-viet.appspot.com/o/voices")) {
        return true;
    } else {
        return false;
    }
}