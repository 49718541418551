import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useDispatch, useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import {features, language} from 'config';
import {FirebaseContext} from 'common';
import {useHistory} from "react-router-dom";

export default function GroupCoords() {
    const {api} = useContext(FirebaseContext);
    const {
        editGroupCoord,
        fetchGroupCoords
    } = api;
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const coorddata = history.location.state.rowData;
    const groupcoorddata = useSelector(state => state.groupcoorddata);
    const [imageCoord, setImageCoord] = useState(null);
    const gps = useSelector(state => state.gpsdata);

    let coord_id = coorddata.tableData.id;
    if (!coord_id) {
        coord_id = history.location.search;
    }
    const columns = [
            {title: "Tên hiển thị", field: 'name', render: rowData => <span>{rowData.name}</span>},
            {title: "Code", field: 'code', render: rowData => <span>{rowData.code}</span>},
            {title: "Giới hạn", field: 'limit', render: rowData => <span>{rowData.limit}</span>},
            {title: "Bán kinh", field: 'radius', render: rowData => <span>{rowData.radius}</span>},
            {title: "ID Điểm gốc", field: 'group_driver_id', initialEditValue: coord_id, editable: 'never'},
            {title: "Tọa độ", field: 'coord', render: rowData => <span>{rowData.coord}</span>},
            {title: "Mở Ca Đêm", field: 'is_night', type: 'boolean', initialEditValue: false},
            {
                title: "Ảnh", field: 'image', render: rowData => <img src={rowData.image} alt={rowData.name} style={{width: 75}}/>,
                editComponent: () => {
                    return (
                        <div>
                            <input
                                type='file'
                                onChange={(e) => {
                                    setImageCoord(e.target.files[0]);
                                    return;
                                }}
                            />
                        </div>
                    )
                }

            },
            {title: "Trạng thái khóa", field: 'lock_status', type: 'boolean', initialEditValue: false}
        ]
    ;

    useEffect(() => {
        dispatch(fetchGroupCoords(coord_id));
    }, [fetchGroupCoords]);

    useEffect(() => {
        if (groupcoorddata.coords) {
            setData(groupcoorddata.coords);
        } else {
            setData([]);
        }
    }, [groupcoorddata.coords]);


    return (
        groupcoorddata.loading ? <CircularLoading/> :
            <MaterialTable
                title={language.promo_locations + " Nhóm: " + coorddata.label}
                columns={columns}
                data={data}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            if (features.AllowCriticalEditsAdmin) {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    newData.group_driver_id = coord_id;
                                    tblData.push(newData);
                                    let id = tblData.length - 1;
                                    dispatch(editGroupCoord(tblData, coord_id, imageCoord, id, "Add"));
                                }, 600);
                            } else {
                                alert(language.demo_mode);
                                reject();
                            }
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            if (features.AllowCriticalEditsAdmin) {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    newData.group_driver_id = coord_id;
                                    tblData[tblData.indexOf(oldData)] = newData;
                                    let id = tblData.indexOf(oldData);
                                    dispatch(editGroupCoord(tblData, coord_id, imageCoord, id, "Update"));
                                }, 600);
                            } else {
                                alert(language.demo_mode);
                                reject();
                            }
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            if (features.AllowCriticalEditsAdmin) {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    tblData.splice(tblData.indexOf(oldData), 1);
                                    dispatch(editGroupCoord(tblData, coord_id, null, null, "Delete"));
                                }, 600);
                            } else {
                                alert(language.demo_mode);
                                reject();
                            }
                        }),
                }}
            />
    );
}
