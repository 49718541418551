import {
    FirebaseConfig
} from 'config';

export function RequestPushMsg(token, title, msg) {

    if (token && token != undefined) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "token": token,
            "title": title,
            "msg": msg
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`https://${FirebaseConfig.projectId}.web.app/send_notification`, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }
}