export const MainConfig = {
    AppDetails: {
        app_name: "ETO",
        app_description: "ETO - Ứng dụng gọi xe chuyên nghiệp",
        app_identifier: "com.etolocal.taxi",
        ios_app_version: "2.4.1",
        android_app_version: 241
    },
    domain: "https://eto.net.vn",
    PA_API_CALLNOW: {
        api_key: "4c45e7655ec7fcb3389ec2a9cb9fbe92",
        url: "https://crm.pavietnam.vn/api/callNow.php"
    },
    VIETQR: {
        clientID: "b8244d91-24ef-4520-97ed-f1491f0b1555",
        apiKey: "ceb017bb-8aa3-47a3-800b-5533e5359588"
    },
    FirebaseConfig: {
        apiKey: "AIzaSyBYJ7QFMyGkWY5PYkJVkCY5kFboq9Zns3E",
        authDomain: "etovn2021.firebaseapp.com",
        databaseURL: "https://etovn2021-default-rtdb.firebaseio.com",
        projectId: "etovn2021",
        storageBucket: "etovn2021.appspot.com",
        messagingSenderId: "46682711019",
        appId: "1:46682711019:web:b8196b4072be956f31efae",
        measurementId: "G-7BQJ0MH0J9",
        WebPushCertificate: "BMhea1jx1aV9rP0Wx__par7-ZJZqnLoxnmAR-v8ueKi3riYBwyLUWHXjBf66RlMaheETFjj5VNFW4RMTLl0RRmI"
    },
    Google_Map_Key: "AIzaSyA5_hh_WTj2tBst1nml6CVs-8FcM_b_KG0",
    iosClientId: "314152728806-kt5410q75ue7d3fnhnefrk1hqc9inko5.apps.googleusercontent.com",
    androidClientId: "314152728806-cmh728bv04jdad8dag7n1mko4g0oc4gg.apps.googleusercontent.com",
    iosStandaloneAppClientId: "1:314152728806:ios:efffc664413951b0e16327",
    androidStandaloneAppClientId: "1:656348424055:android:097ef1b4f77a33e7582372",
    PurchaseDetails: {
        CodeCanyon_Purchase_Code: "71940451-60a5-4743-ab02-52c118f28b70",
        Buyer_Email_Address: "tkvclub01@hotmail.com"
    },
    CATCHA_SITE_KEY: "6Le3O0QbAAAAAK2a2lCRcfvsZdN-ZBE19NTJLeV8",
    CATCHA_SECRET_KEY: "6Le3O0QbAAAAAGJsnVJ3wt3iky6v-e7iJa1NeBbG",
    FIREBASE_APPCHECK_DEBUG_TOKEN: "E393C352-9C08-495D-9BBA-B2C31CC8C892",
    ANDROID_DEBUG_TOKEN: "5F155D37-5988-450D-87EA-B987728BF3F0",
    Twilio: {
        TWILIO_AUTH_TOKEN: "0fea99c047ce8d90d2113830b2d26357",
        TWILIO_AUTH_SID: "AC3da16921dae0384caf45e7c13e10606a"
    },
    expo_owner: "etolocal",
    expo_slug: "eto",
    expo_project_id: "7f11e52f-9eb6-4bfc-bec9-c22563c439f6"
}