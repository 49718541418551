import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useDispatch, useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import {features, language} from 'config';
import {FirebaseContext} from 'common';
import Popup from "reactjs-popup";
import {makeStyles} from "@material-ui/core/styles";
import Price2 from "../components/CarType/Price2";

const useStyles = makeStyles(theme => ({
    modal: {
        fontSize: 12,
        border: 1,
        borderStyle: 'solid',
        borderColor: '#cfcece',
        background: '#f6fbe4'
    },
    modalHeader: {
        width: '100%',
        borderBottom: '1',
        borderBottomColor: 'gray',
        borderBottomStyle: 'solid',
        fontSize: 18,
        textAlign: 'center',
        padding: 5,
        fontWeight: 'bold'
    },
    modalContent: {
        width: '100%',
        padding: '10px 5px',
    },
    modalActions: {
        width: '100%',
        padding: '10px 5px',
        margin: 'auto',
        textAlign: 'center'
    },

    modalClose: {
        cursor: 'pointer',
        position: 'absolute',
        display: 'block',
        padding: '2px 5px',
        width: 40,
        height: 40,
        right: -10,
        top: -10,
        background: '#ffffff',
        color: 'black',
        border: 1,
        borderRadius: 18,
        borderStyle: 'solid',
        borderColor: '#cfcece'
    }

}));

export default function CarTypes() {
    const {api} = useContext(FirebaseContext);
    const classes = useStyles();
    const {
        editCarType
    } = api;
    const columns = [
        {title: "Loại hình kinh doanh", field: 'type', lookup: {taxi: "XE TAXI", hop_dong: "XE HỢP ĐỒNG"}, initialEditValue: "taxi"},
        {title: "Cài bảng giá", field: 'code', render: rowData => <ModalPrice code={rowData.code}/>},
        {title: language.image, field: 'image', render: rowData => <img alt='Car' src={rowData.image} style={{width: 50}}/>},
        {title: language.car_code, field: 'code'},
        {title: "Mã lái xe", field: 'driverCodes'},
        {title: language.name, field: 'name'},
        /*{title: language.rate_per_km, field: 'rate_per_kilometer', type: 'numeric'},
        {title: language.rate_per_kilometer_10_15, field: 'rate_per_kilometer_10_15', type: 'numeric'},
        {title: language.rate_per_kilometer_15_30, field: 'rate_per_kilometer_15_30', type: 'numeric'},
        {title: language.rate_per_kilometer_30, field: 'rate_per_kilometer_30', type: 'numeric'},
        {title: language.rate_per_hour, field: 'rate_per_hour', type: 'numeric'},
        {title: language.min_fare, field: 'min_fare', type: 'numeric'},*/
        {title: "Giá 1p PT", field: 'min_minute', type: 'numeric'},
        {title: language.convenience_fee, field: 'convenience_fees', type: 'numeric'},
        {
            title: language.convenience_fee_type,
            field: 'convenience_fee_type',
            lookup: {flat: language.flat, percentage: language.percentage},
        },
        {title: language.extra_info, field: 'extra_info'}
    ];
    const [data, setData] = useState([]);
    const cartypes = useSelector(state => state.cartypes);
    const dispatch = useDispatch();
    const [tabSelect, setTabSelect] = useState('one');

    useEffect(() => {
        if (cartypes.cars) {
            setData(cartypes.cars);
        } else {
            setData([]);
        }
    }, [cartypes.cars]);

    const handleChange = (event, value) => {
        setTabSelect(value);
    };

    const ModalPrice = (props) => (
        <Popup
            trigger={<button className='button'> Thuê bao</button>}
            modal
            nested
            position="top center"
        >
            {close => (
                <div className={classes.modal}>
                    <button className={classes.modalClose} onClick={close}>
                        X
                    </button>
                    {/*<AppBar position="static">
                        <Tabs value={tabSelect} onChange={handleChange}>
                            <Tab value="one" label="1 chiều"/>
                            <Tab value="two" label="2 chiều"/>
                        </Tabs>
                    </AppBar>*/}
                    <div className={classes.modalHeader}> Bảng giá thuê bao 2 chiều #{props.code}</div>
                    <div className={classes.modalContent}>
                        {' '}
                        <Price2 code={props.code}/>
                    </div>
                    <div className={classes.modalActions}>
                        <button
                            className='button'
                            onClick={() => {
                                console.log('modal closed ');
                                close();
                            }}
                        >
                            Đóng
                        </button>
                    </div>
                </div>
            )}
        </Popup>
    );

    return (
        cartypes.loading ? <CircularLoading/> :
            <MaterialTable
                title={language.car_type}
                columns={columns}
                data={data}
                options={{
                    exportButton: true
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            if (features.AllowCriticalEditsAdmin) {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    tblData.push(newData);
                                    dispatch(editCarType(tblData), "Add");
                                }, 600);
                            } else {
                                alert(language.demo_mode);
                                reject();
                            }
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            if (features.AllowCriticalEditsAdmin) {
                                setTimeout(() => {
                                    resolve();
                                    dispatch(editCarType(newData, "Update", data.indexOf(oldData)), "Update");
                                }, 600);
                            } else {
                                alert(language.demo_mode);
                                reject();
                            }
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            if (features.AllowCriticalEditsAdmin) {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    tblData.splice(tblData.indexOf(oldData), 1);
                                    dispatch(editCarType(tblData), "Delete");
                                }, 600);
                            } else {
                                alert(language.demo_mode);
                                reject();
                            }
                        }),
                }}
            />
    );
}
