import React, {useContext, useState} from 'react';
import {useDispatch} from "react-redux";
import {FirebaseContext} from "common";
import * as XLSX from 'xlsx';
import {ConvertTimeStampToDate, GetCurrentMiniTimeStamp} from "common/src/other/DateFunctions";
import {Text, View} from "react-native-web";

export default function ImportLichTrucDem(props) {
    const {api} = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const {
        setCaTrucDem
    } = api;
    const [loading, setLoading] = useState(false);

    const processData = dataString => {
        let list = [];
        const dataStringLines = dataString.split(/\r\n|\n/);
        let monthYear = dataStringLines[1].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        let month = monthYear[1];
        let year = monthYear[3];

        let currentDateFomated = new Date();

        let date_import = GetCurrentMiniTimeStamp();
        for (let i = 3; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (!row[0] || row[0] == '') {
                continue;
            }

            let date = row[0] + "/" + month + "/" + year;
            let date_key = row[0] + "_" + month + "_" + year;

            let dateFormated = new Date(month + "/" + row[0] + "/" + year + " 20:30:00");

            if (dateFormated < currentDateFomated) {
                continue;
            }

            let admin = row[3];
            let prime_driver = row[2].replace(/"/g, '');

            let data_insert = {
                date_add: date,
                admin: admin,
                prime_driver: prime_driver,
                second_driver: prime_driver,
                date_import: date_import
            }
            list.push(data_insert);
            dispatch(setCaTrucDem(date_key, data_insert));
        }
    }

    // handle file upload
    const handleFileUpload = e => {
        setLoading(true);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type: 'binary'});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, {header: 1});

            processData(data);
        };
        reader.readAsBinaryString(file);
        alert("Nạp dữ liệu thành công.");
        setTimeout(() => {
            setLoading(false);
            props.close();
        }, 3000);

    }
    return (
        loading ? <View style={{height: 100, width: 300}}><Text>Vui lòng đợi dữ liệu mới cập nhật</Text></View> :
            <input
                type="file"
                accept=".csv,.xlsx,.xls"
                placeholder={"Import Lịch Ca Đêm"}
                onChange={handleFileUpload}
                required={true}
            />

    );
}
