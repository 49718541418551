import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from "@material-ui/core/FormControl";
import {HiddenPhone} from "common/src/other/CommonFunctions";
import {Text} from "react-native-web";
import {useDispatch, useSelector} from "react-redux";
import {FirebaseContext} from "common";


const useStyles = makeStyles(theme => ({
    titleList: {
        fontSize: 14,
        marginTop: 10
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        height: 39,
    },
    backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)",
        flexDirection: 'column'
    },
    row: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "rgba(255,0,0,0.74)"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    input: {
        fontSize: 18,
        color: "#000"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
}));

export default function CancelCallCenter(props) {
    const styles = useStyles();
    const {api, app} = useContext(FirebaseContext);
    const {
        updateCallCancelById,
        cancelBooking
    } = api;
    const dispatch = useDispatch();
    const [listReason, setListReason] = useState([]);
    const [reason, setReason] = useState(null);
    const cancelreasondata = useSelector(state => state.cancelreasondata);


    const handleChangeReason = (event, value) => {
        setReason(value);
    };

    useEffect(() => {
        if (props.fromTT) {
            let reasons = [];
            if (cancelreasondata && cancelreasondata.complex) {
                cancelreasondata.complex.filter((r) => r.type == 'system').map((c) => {
                    reasons.push(c.label);
                })
            }
            setListReason(reasons);
        } else {
            if (listReason) {
                setListReason([]);
            }
        }
    }, [props.fromTT]);

    return (
        <FormControl>
            <Grid container spacing={1} style={{minHeight: 63}}>
                <Grid item sm={12} md={12} lg={12} style={{paddingTop: 28}}>
                    <Text>{"Hủy cuộc gọi"}</Text>
                    <Text>{" - " + props.time + " ||| SĐT: " + HiddenPhone(props.phone)}</Text>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    {props.listLyDo || listReason ?
                        <Autocomplete
                            value={reason}
                            freeSolo
                            autoSelect
                            disablePortal
                            defaultValue={props.listLyDo[0]}
                            id={"reason_" + props.uniqueid}
                            options={listReason && listReason.length > 0 ? listReason.map((option) => option) : props.listLyDo.map((option) => option)}
                            onChange={handleChangeReason}
                            renderInput={(params) => <TextField {...params} label="Lý do khách hủy"/>}
                        /> : null}
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        id={"buttonreason_" + props.uniqueid}
                        size="small"
                        className={styles.submit}
                        onClick={() => {
                            if (props.fromTT && props.booking_id && props.booking_id.booking_id) {
                                app.database().ref('bookings/' + props.booking_id.booking_id).once('value', (snapshot) => {
                                    const data = snapshot.val();
                                    if (data.customer_wish) {
                                        alert("Khách đã lên xe, bạn không thể hủy chuyến đi");
                                    } else {
                                        dispatch(cancelBooking({
                                            booking: data,
                                            reason: reason ? reason : "Không xác định",
                                            reason_type: "system",
                                            cancel_by: 'system'
                                        }));

                                        setTimeout(() => {
                                            let data = {
                                                status: "cancel",
                                                reason: reason ? reason : "Không xác định",
                                                cancel_by: 'system'
                                            }
                                            dispatch(updateCallCancelById(props.firebase_id, data));
                                        }, 100);
                                    }
                                });
                            } else {
                                setTimeout(() => {
                                    let data = {
                                        status: "cancel",
                                        reason: reason ? reason : "Không xác định"
                                    }
                                    dispatch(updateCallCancelById(props.firebase_id, data));
                                }, 100);
                            }
                            setTimeout(props.closeCallback, 200);
                        }}
                    >
                        {props.booking_id ? "Xác nhận" : "Không đặt"}
                    </Button>
                </Grid>

                <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="red"
                        size="small"
                        id={"cancelbutton_" + props.uniqueid}
                        className={styles.submit}
                        onClick={props.closeCallback}
                    >
                        {"Bỏ qua"}
                    </Button>
                </Grid>
            </Grid>
        </FormControl>
    )
        ;
}
