import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { View } from "react-native-web";
import { useDispatch, useSelector } from "react-redux";
import { FirebaseContext } from "common";
import { colors } from "../../config/theme";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  GetCurrentMiniTimeStamp,
  GetCurrentTimeStamp,
} from "common/src/other/DateFunctions";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  fullHeight: {
    height: "100%",
    width: "100%",
  },
  inputColor: {
    color: colors.LINK,
  },
  titleList: {
    fontSize: 14,
    marginTop: 10,
  },
  submit: {
    backgroundColor: colors.MAIN,
    margin: theme.spacing(2, 0, 2),
    height: 39,
  },
  backgroundRed: {
    backgroundColor: "rgba(255,179,179,0.74)",
    flexDirection: "column",
  },
  row: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "rgba(255,0,0,0.74)",
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373",
  },
  carphoto: {
    height: "18px",
    marginRight: "10px",
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#E8E8E8",
    fontSize: 16,
    padding: "10px 2px 10px 10px",
    color: "#000",
  },
  commonInputStyle: {
    borderRadius: 4,
    backgroundColor: "#E8E8E8",
  },
}));

export default function AddCallCenter(props) {
  const { api, paCallCenterRef } = useContext(FirebaseContext);
  const { updateTripDrop } = api;
  const dispatch = useDispatch();
  const styles = useStyles();
  const [note, setNote] = useState("");
  const [phone, setPhone] = useState("");
  const auth = useSelector((state) => state.auth);
  return (
    <FormControl>
      <View style={{ flexDirection: "column", justifyContent: "center" }}>
        <View
          style={{
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-end",
              marginTop: 20,
            }}
          >
            <View
              style={{
                flexDirection: "column",
                alignItems: "flex-end",
                width: 160,
              }}
            >
              <TextField
                id="phone"
                label={"Số điện thoại"}
                variant="filled"
                fullWidth
                className={styles.commonInputStyle}
                InputProps={{
                  className: styles.input,
                  style: { textAlignLast: "start" },
                }}
                value={phone}
                onChange={(event) => {
                  let text = event.target.value;
                  setPhone(text);
                }}
              />
            </View>
            <View
              style={{
                flexDirection: "column",
                alignItems: "flex-end",
                width: "60%",
              }}
            >
              <TextField
                id="note"
                label={"Lời nhắn tới lx"}
                variant="filled"
                fullWidth
                className={styles.commonInputStyle}
                InputProps={{
                  className: styles.input,
                  style: { textAlignLast: "start" },
                }}
                value={note}
                onChange={(event) => {
                  let text = event.target.value;
                  setNote(text);
                }}
              />
            </View>
          </View>
          <View style={{ flexDirection: "row", paddingTop: 20 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="small"
              color="secondary"
              id={
                "cancelbutton_" +
                (props.callDetail && props.callDetail.uniqueid
                  ? props.callDetail.uniqueid
                  : "")
              }
              className={styles.submit}
              style={{ marginLeft: 50, width: 250 }}
              onClick={() => {
                if (phone && note) {
                  let dataPa = {};
                  dataPa.status = "pending";
                  dataPa.checksum = GetCurrentMiniTimeStamp();
                  dataPa.action = "note_call";
                  dataPa.destination = "101";
                  dataPa.time = GetCurrentTimeStamp();
                  dataPa.uniqueid = GetCurrentMiniTimeStamp();
                  dataPa.msnv = auth.info.uid;
                  dataPa.source = phone;
                  dataPa.note = note;
                  dataPa.type = "TT";
                  paCallCenterRef.push(dataPa).then((res) => {
                    alert("Đã khởi tạo cuốc gọi, Tâm có thể đặt cuốc khách");
                  });
                } else {
                  alert("Vui lòng nhập sdt và ghi chú");
                }
              }}
            >
              {"Tạo cuộc gọi"}
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="red"
              size="small"
              id={
                "cancelbutton_" +
                (props.callDetail && props.callDetail.uniqueid
                  ? props.callDetail.uniqueid
                  : "")
              }
              className={styles.submit}
              style={{ marginLeft: 50 }}
              onClick={props.closeCallback}
            >
              {"Đóng"}
            </Button>
          </View>
        </View>
      </View>
    </FormControl>
  );
}
