import React from 'react';
import {View} from "react-native-web";
import {colors} from "../../config/theme";
import IconButton from "@mui/material/IconButton";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import {HiddenPhone} from "common/src/other/CommonFunctions";
import {ConvertTimeStampTimeForPa} from "common/src/other/DateFunctions";
import PhoneDisabledOutlinedIcon from "@mui/icons-material/PhoneDisabledOutlined";

export default function BookingCancelComponent(props) {
    const obj = props.callCenterObj;
    return (
        <View style={{
            maxWidth: 310, backgroundColor: 'rgba(255,255,255,0.77)',
            borderRadius: 10,
            shadowOffset: {
                height: 1,
                width: 0,
            },
            shadowColor: colors.BLACK,
            shadowOpacity: 0.35,
            shadowRadius: 10,
            border: 1,
            borderStyle: 'solid',
            borderColor: "#c7c7c7",
            marginTop: 10
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: "100%",
                padding: 0,
                margin: 0
            }}>
                <IconButton
                    aria-label="Gọi khách"
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    color="primary"
                    size="large"
                    onClick={() => {

                    }}
                >
                    <PhoneCallbackIcon fontSize="inherit"/>
                </IconButton>
                <div style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: "rgba(255,255,255,0.71)",
                    '&:hover': {
                        backgroundColor: "rgba(255,255,255,0.97)",
                    },
                    width: 200
                }}>
                    <h4 style={{fontSize: 30}}>{HiddenPhone(obj.item.source)}</h4>
                </div>

                <IconButton size="large"
                            color={"primary"}
                            onClick={() => {
                                if (obj.item && obj.item.source) {
                                    if (props.handleClickOpenDialogBooking) {
                                        props.handleClickOpenDialogBooking(obj.item);
                                    }
                                }
                            }
                            }
                            style={{
                                position: `absolute`,
                                right: `10px`,
                                top: '80px',
                                zIndex: 999,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexGrow: 1,
                                borderRadius: 5,
                                padding: 10
                            }}
                >
                    <img
                        height={"50"}
                        src={require("../../assets/img/rebooking_icon.png").default}
                        alt="Password"
                    />
                </IconButton>
                    <IconButton size="large"
                                color={"primary"}
                                onClick={() => {
                                    if(props.openCancelCallback){
                                        props.openCancelCallback(obj.item);
                                    }
                                }
                                }
                                style={{
                                    position: `absolute`,
                                    right: `10px`,
                                    top: '10px',
                                    zIndex: 999,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexGrow: 1,
                                    borderRadius: 5,
                                    padding: 10
                                }}
                    >
                        <PhoneDisabledOutlinedIcon style={{fontSize: 35, color: "#ff0000"}}/>
                    </IconButton>
            </div>


            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: "100%"
            }}>
                <IconButton
                    aria-label="Ghi chú"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexGrow: 1,
                        padding: 10
                    }}
                    color="primary"
                    size="medium"
                    onClick={() => {

                    }}
                >
                    <CalendarMonthOutlinedIcon fontSize="inherit" style={{color: "#6059a7"}}/>
                </IconButton>
                <div style={{
                    display: 'flex',
                    flexGrow: 1,
                    position: 'relative',
                    borderRadius: 5,
                    backgroundColor: "rgba(255,255,255,0.71)",
                    '&:hover': {
                        backgroundColor: "rgba(255,255,255,0.97)",
                    },
                    width: 250,
                }}>
                    <h4>{ConvertTimeStampTimeForPa(obj.item.time * 1000)}</h4>
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: "100%"
            }}>
                <IconButton
                    aria-label="Ghi chú"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexGrow: 1,
                        padding: 10
                    }}
                    color="primary"
                    size="medium"
                    onClick={() => {

                    }}
                >
                    <NoteAltOutlinedIcon fontSize="inherit" style={{color: "#6059a7"}}/>
                </IconButton>
                <div style={{
                    display: 'flex',
                    flexGrow: 1,
                    position: 'relative',
                    borderRadius: 5,
                    backgroundColor: "rgba(255,255,255,0.71)",
                    '&:hover': {
                        backgroundColor: "rgba(255,255,255,0.97)",
                    },
                    width: 250,
                }}>
                    <h4 style={{fontSize: 15}}>{obj.item.booking_note ? obj.item.booking_note : (obj.item.note ? obj.item.note : "")}</h4>
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: "100%",
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <h2 style={{color: "#005D9C", fontWeight: 'bold', fontSize: 20, margin: 0, padding: 0, paddingLeft: 10, paddingRight: 5}}>Cuốc khách bị huỷ </h2>
            </div>
        </View>
    )
}
