import {
    DELETE_USER,
    DELETE_USER_FAILED,
    DELETE_USER_SUCCESS,
    EDIT_USER,
    EDIT_USER_FAILED,
    EDIT_USER_SUCCESS,
    FETCH_ALL_DRIVERS,
    FETCH_ALL_DRIVERS_FAILED,
    FETCH_ALL_DRIVERS_QUEUE,
    FETCH_ALL_DRIVERS_QUEUE_FAILED,
    FETCH_ALL_DRIVERS_QUEUE_SUCCESS,
    FETCH_ALL_DRIVERS_SUCCESS, FETCH_ALL_FLEETADMIN, FETCH_ALL_FLEETADMIN_FAILED, FETCH_ALL_FLEETADMIN_SUCCESS, FETCH_ALL_ONCE_DRIVER, FETCH_ALL_ONCE_DRIVER_FAILED, FETCH_ALL_ONCE_DRIVER_SUCCESS,
    FETCH_ALL_ONCE_USERS,
    FETCH_ALL_ONCE_USERS_FAILED,
    FETCH_ALL_ONCE_USERS_SUCCESS,
    FETCH_ALL_USERS,
    FETCH_ALL_USERS_FAILED,
    FETCH_ALL_USERS_SUCCESS, FETCH_DRIVER_ACTIVE, FETCH_DRIVER_ACTIVE_FAILED, FETCH_DRIVER_ACTIVE_SUCCESS,
    FETCH_DRIVER_OUT_IN_GROUP, FETCH_DRIVER_OUT_IN_GROUP_FAILED,
    FETCH_DRIVER_OUT_IN_GROUP_SUCCESS,
    SELECT_USER,
    SELECT_USER_FAILED,
    SELECT_USER_SUCCESS, STOP_FETCH_ALL_DRIVERS_SUCCESS, STOP_FETCH_MESSAGES,
    UPDATE_USER_GROUP,
    UPDATE_USER_LOCK_STATUS,
    UPDATE_USER_LOCK_STATUS_FAILED,
    UPDATE_USER_LOCK_STATUS_SUCCESS
} from "../store/types";
import {store} from "../store/store";
import {getUserValidSign} from "../other/CommonFunctions";
import {GetCurrentTimeStamp} from "../other/DateFunctions";

export const GetDistance = (lat1, lon1, lat2, lon2) => {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    } else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344;
        return dist;
    }
};

export const fetchUsers = () => (dispatch) => (firebase) => {

    const {
        usersRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_USERS,
        payload: null
    });
    usersRef.orderByChild('order_number').on("value", snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i] && data[i].usertype && data[i].usertype != 'admin')
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_ALL_USERS_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_USERS_FAILED,
                payload: "No users available."
            });
        }
    });
};

export const stopFetchUsers = () => (firebase) => {

    const {
        usersRef
    } = firebase;
    usersRef.orderByChild('order_number').off();
};

export const fetchFleetUsersOnline = () => (dispatch) => (firebase) => {

    const {
        usersRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_FLEETADMIN,
        payload: null
    });
    usersRef.orderByChild('usertype').equalTo('fleetadmin').on("value", snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i].approved)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_ALL_FLEETADMIN_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_FLEETADMIN_FAILED,
                payload: "No users available."
            });
        }
    });
};

export const fetchDriversOn = () => (dispatch) => (firebase) => {

    const {
        usersRef,
        caTrucDemsRef
    } = firebase;

    dispatch({
        type: FETCH_DRIVER_ACTIVE,
        payload: null
    });

    usersRef.orderByChild('vehicleNumberLeader').on('value', res => {
        usersRef.orderByChild("usertype").equalTo("driver").once("value", snapshot => {
            if (snapshot.val()) {
                const data = snapshot.val();
                const arr = Object.keys(data)
                    .filter(i => data[i].approved && data[i].approved === true)
                    .map(i => {
                        data[i].id = i;
                        return data[i];
                    });
                dispatch({
                    type: FETCH_DRIVER_ACTIVE_SUCCESS,
                    payload: arr
                });
            } else {
                dispatch({
                    type: FETCH_DRIVER_ACTIVE_FAILED,
                    payload: "No users available."
                });
            }
        });
    });

    caTrucDemsRef.orderByChild('second_driver').on('value', res => {
        usersRef.orderByChild("usertype").equalTo("driver").once("value", snapshot => {
            if (snapshot.val()) {
                const data = snapshot.val();
                const arr = Object.keys(data)
                    .filter(i => data[i].approved && data[i].approved === true)
                    .map(i => {
                        data[i].id = i;
                        return data[i];
                    });
                dispatch({
                    type: FETCH_DRIVER_ACTIVE_SUCCESS,
                    payload: arr
                });
            } else {
                dispatch({
                    type: FETCH_DRIVER_ACTIVE_FAILED,
                    payload: "No users available."
                });
            }
        });
    });

};


export const stopFetchDriversOn = () => (dispatch) => (firebase) => {

    const {
        usersRef,
        caTrucDemsRef
    } = firebase;
    dispatch({
        type: FETCH_DRIVER_ACTIVE_FAILED,
        payload: "No users available."
    });
    usersRef.orderByChild('vehicleNumberLeader').off();
    caTrucDemsRef.orderByChild('second_driver').off();

};

export const fetchOnceUsers = () => (dispatch) => (firebase) => {

    const {
        usersRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_ONCE_USERS,
        payload: null
    });
    usersRef.orderByChild('order_number').once("value", snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i].usertype != 'admin')
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_ALL_ONCE_USERS_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_ONCE_USERS_FAILED,
                payload: "No users available."
            });
        }
    });
};

export const fetchOnceDrivers = () => (dispatch) => (firebase) => {

    const {
        usersRef,
        groupdriverRef,
        groupdriverTotalRef,
        groupdriverCoordTotalRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_ONCE_DRIVER,
        payload: null
    });

    usersRef.orderByChild("usertype").equalTo('driver').once("value", snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i].approved == true && data[i].location)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });

            groupdriverRef.once("value", (snapshot) => {
                if (snapshot.val()) {
                    let data = snapshot.val();
                    for (let i = 0; i < data.length; i++) {
                        if (data[i] && data[i].value) {
                            if (data[i].value != '0' && data[i].value != '1' && data[i].value != '9' && data[i].value != '12' && data[i].lock_status == false) {
                                let drivers = arr.filter((d) => d.groupDriver == data[i].value);
                                if (data[i].group_coords) {
                                    let listKey = Object.keys(data[i].group_coords);
                                    if (listKey) {
                                        listKey.map((keyId) => {
                                            if (data[i].group_coords[keyId]) {
                                                let coord = data[i].group_coords[keyId];
                                                let driverCoords = arr.filter((d) => d.coordData && parseInt(d.coordData.coordId) === parseInt(keyId) && parseInt(d.coordData.groupId) === parseInt(data[i].value) && parseInt(d.coordData.groupId) === parseInt(d.groupDriver));
                                                if (driverCoords && coord) {
                                                    groupdriverCoordTotalRef(data[i].value, keyId).set(driverCoords.length);
                                                }
                                            }
                                        });
                                    }
                                }
                                if (drivers) {
                                    groupdriverTotalRef(data[i].value).set(drivers.length);
                                }
                            }
                        }
                    }
                }
            });

            dispatch({
                type: FETCH_ALL_ONCE_DRIVER_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_ONCE_DRIVER_FAILED,
                payload: "No users available."
            });
        }
    });
};

export const fetchDriversOutInGroup = (bankinh, carType, pickup, group = null, currentAreaId = null) => (dispatch) => (firebase) => {

    const {
        usersRef
    } = firebase;

    dispatch({
        type: FETCH_DRIVER_OUT_IN_GROUP,
        payload: null
    });
    var resultOut = [];
    var resultIn = [];
    let cars = store.getState().cartypes.cars;
    const groups = store.getState().groupdriverdata.group_kd;
    const profile = store.getState().auth.info.profile;

    var group_info = null;
    var driver_favourite = null;
    var currentPhone = null;
    if (profile && profile.driver_favourite) {
        driver_favourite = store.getState().auth.info.profile.driver_favourite;
    }
    if (profile && profile.mobile) {
        currentPhone = profile.mobile.replace("+840", "0");
    }
    if (store.getState().groupdriverdata && store.getState().groupdriverdata.complex && group) {
        group_info = store.getState().groupdriverdata.complex.filter((g) => g.value == group)[0];
    }
    if (cars && cars.length > 0) {
        let carSelect = cars.filter((car) => {
            if (car.code === carType) {
                return true;
            }
        });
        if (carSelect[0]) {
            let carSelected = carSelect[0];
            var listDrivers = [];
            if (carSelected.driverCodes) {
                listDrivers = carSelected.driverCodes.split(",");
            }
            usersRef.orderByChild("queue").equalTo(false).once("value", snapshot => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    const users = Object.keys(data)
                        .filter(i => data[i].approved == true && data[i].location && data[i].usertype == 'driver')
                        .map(i => {
                            data[i].id = i;
                            return data[i];
                        });
                    const promises = [];
                    for (let i = 0; i < users.length; i++) {
                        let usr = users[i];
                        if (driver_favourite !== null) {
                            if (driver_favourite[usr.id] && driver_favourite[usr.id].status === false) {
                                continue;
                            }
                        }
                        if (currentPhone && usr.river_favourite && usr.river_favourite[currentPhone]) {
                            continue;
                        }

                        if (profile && profile.codeDriver && profile.codeDriver.trim() == usr.codeDriver.trim()) {
                            continue;
                        }

                        var groupValid = null;
                        if (group && groups) {
                            groupValid = groups.filter((g) => {
                                if (g.value && g.value == group) {
                                    return true;
                                }
                            })[0];
                        }

                        promises.push(getUserValidSign(usr, bankinh, pickup, listDrivers, carType, resultOut, resultIn, groupValid, currentAreaId));
                    }
                    //{driverInGroup: resultIn, driverOutGroup: resultOut}
                    Promise.all(promises)
                        .then((results) => {
                            if (group_info !== null && group_info.codes) {
                                let listDriverCode = group_info.codes.split(',');
                                let newResultIn = [];
                                if (resultIn.length > 0) {
                                    resultIn.map((driver) => {
                                        if (driver.codeDriver && listDriverCode.includes(driver.codeDriver.toString().trim())) {
                                            newResultIn.push(driver);
                                        }
                                    });
                                }
                                //{id: usr.id, codeDriver: usr.codeDriver.toString().trim(), pushToken: usr.pushToken, location: usr.location}
                                dispatch({
                                    type: FETCH_DRIVER_OUT_IN_GROUP_SUCCESS,
                                    payload: {driverInGroup: newResultIn, driverOutGroup: resultOut}
                                });
                            } else {
                                dispatch({
                                    type: FETCH_DRIVER_OUT_IN_GROUP_SUCCESS,
                                    payload: {driverInGroup: resultIn, driverOutGroup: resultOut}
                                });
                            }

                        })
                        .catch((e) => {
                            console.log("error");
                            console.log(e);
                            dispatch({
                                type: FETCH_DRIVER_OUT_IN_GROUP_FAILED,
                                payload: null
                            });
                        });

                }
            });
            /*if (users && users.length > 0) {
                const promises = [];
                for (let i = 0; i < users.length; i++) {
                    let usr = users[i];
                    promises.push(getUserValidSign(usr, bankinh, pickup, listDrivers, carType, resultOut, resultIn, group));
                }
                //{driverInGroup: resultIn, driverOutGroup: resultOut}
                Promise.all(promises)
                    .then((results) => {
                        dispatch({
                            type: FETCH_DRIVER_OUT_IN_GROUP_SUCCESS,
                            payload: {driverInGroup: resultIn, driverOutGroup: resultOut}
                        });
                    })
                    .catch((e) => {
                        console.log("error");
                        console.log(e);
                        dispatch({
                            type: FETCH_DRIVER_OUT_IN_GROUP_FAILED,
                            payload: null
                        });
                    });
            } else {

            }*/
        }
    }
};

export const fetchAllDrivers = () => (dispatch) => (firebase) => {

    const {
        usersRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_DRIVERS,
        drivers: null
    });

    usersRef.orderByChild("usertype").equalTo("driver").on("value", snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();

            const arr = Object.keys(data)
                .filter(i => data[i] && data[i].groupDriver !== '' && data[i].approved === true)
                .map(i => {
                    data[i].id = i;
                    if (data[i].queue) {
                        data[i].carStatus = 'xe341.png';
                    } else {
                        if (data[i].driverActiveStatus === false) {
                            data[i].carStatus = 'xe040.png';
                        } else {
                            data[i].carStatus = 'xe042.png';
                        }
                    }
                    return data[i];
                });
            dispatch({
                type: FETCH_ALL_DRIVERS_SUCCESS,
                drivers: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_DRIVERS_FAILED,
                drivers: "No users available."
            });
        }
    });
};

export const fetchAllDriversOff = () => (dispatch) => (firebase) => {
    const {
        usersRef
    } = firebase;
    dispatch({
        type: STOP_FETCH_ALL_DRIVERS_SUCCESS,
        drivers: null,
    });
    usersRef.orderByChild("usertype").equalTo("driver").off();
}


export const fetchAllDriversQueue = () => (dispatch) => (firebase) => {

    const {
        usersRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_DRIVERS_QUEUE,
        driversqueue: null
    });

    usersRef.orderByChild("queue").equalTo(true).on("value", snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i].groupDriver !== '' && data[i].approved === true)
                .map(i => {
                    data[i].id = i;
                    if (data[i].queue) {
                        data[i].carStatus = 'xe341.png';
                    } else {
                        if (data[i].driverActiveStatus === false) {
                            data[i].carStatus = 'xe040.png';
                        } else {
                            data[i].carStatus = 'xe042.png';
                        }
                    }
                    return data[i];
                });
            dispatch({
                type: FETCH_ALL_DRIVERS_QUEUE_SUCCESS,
                driversqueue: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_DRIVERS_QUEUE_FAILED,
                driversqueue: "No users available."
            });
        }
    });
};


export const fetchAllDriversQueueOff = () => (dispatch) => (firebase) => {

    const {
        usersRef
    } = firebase;
    usersRef.orderByChild("queue").equalTo(true).off();
};


export const addUser = (userdata) => (dispatch) => (firebase) => {
    const {
        usersRef
    } = firebase;

    dispatch({
        type: EDIT_USER,
        payload: userdata
    });
    let countUser = 0;
    usersRef.on("value", snapshot => {
        countUser = Object.keys(snapshot.val()).length;
    });

    userdata.order_number = countUser + 1;
    usersRef.push(userdata).then(() => {
        dispatch({
            type: EDIT_USER_SUCCESS,
            payload: null
        });
    }).catch((error) => {
        dispatch({
            type: EDIT_USER_FAILED,
            payload: error
        });
    });
}

export const editUser = (id, user) => (dispatch) => (firebase) => {

    const {
        singleUserRef,
        usersRef
    } = firebase;

    dispatch({
        type: EDIT_USER,
        payload: user
    });
    let editedUser = user;
    delete editedUser.id;

    if (!editedUser.order_number) {
        let countUser = 0;
        usersRef.on("value", snapshot => {
            countUser = Object.keys(snapshot.val()).length;
        });
        editedUser.order_number = countUser + 1;
    }

    singleUserRef(id).set(editedUser).then(() => {
        dispatch({
            type: EDIT_USER_SUCCESS,
            payload: null
        });
    }).catch((error) => {
        dispatch({
            type: EDIT_USER_FAILED,
            payload: error
        });
    });
}


export const updateUserGroup = (uid, groupDriver) => (dispatch) => (firebase) => {
    const {
        singleUserRef,
    } = firebase;

    dispatch({
        type: UPDATE_USER_GROUP,
        payload: null
    });

    singleUserRef(uid).once("value", snapshot => {
        if (snapshot.val()) {
            var userDetail = snapshot.val();
            userDetail.groupDriver = groupDriver;
            singleUserRef(uid).set(userDetail).then(() => {
                dispatch({
                    type: EDIT_USER_SUCCESS,
                    payload: "Cập nhật nhóm thành công"
                });
            }).catch(error => {
                dispatch({
                    type: EDIT_USER_FAILED,
                    payload: "Có lỗi xảy ra trong khi cập nhật nhóm"
                });
            });
        }
    });


};

export const updateUserLockStatus = (uid, status) => (dispatch) => (firebase) => {
    const {
        singleUserRef,
    } = firebase;

    dispatch({
        type: UPDATE_USER_LOCK_STATUS,
        payload: null
    });

    singleUserRef(uid).once("value", snapshot => {
        if (snapshot.val()) {
            var userDetail = snapshot.val();
            userDetail.lock_driver_status = status ? status : false;
            if (status) {
                userDetail.lock_driver_time = GetCurrentTimeStamp();
            } else {
                userDetail.lock_driver_time = 0;
            }
            singleUserRef(uid).set(userDetail).then(() => {
                dispatch({
                    type: UPDATE_USER_LOCK_STATUS_SUCCESS,
                    payload: "Cập nhật Trạng thái thành công"
                });

            }).catch(error => {
                dispatch({
                    type: UPDATE_USER_LOCK_STATUS_FAILED,
                    payload: "Có lỗi xảy ra trong khi cập nhật Trạng thái"
                });
            });
        }
    });
};


export const deleteUser = (id) => (dispatch) => (firebase) => {

    const {
        singleUserRef
    } = firebase;

    dispatch({
        type: DELETE_USER,
        payload: id
    });

    singleUserRef(id).remove().then(() => {
        dispatch({
            type: DELETE_USER_SUCCESS,
            payload: null
        });
    }).catch((error) => {
        dispatch({
            type: DELETE_USER_FAILED,
            payload: error
        });
    });
}


export const getUserDetail = (id) => (dispatch) => (firebase) => {
    const {
        singleUserRef
    } = firebase;

    dispatch({
        type: SELECT_USER,
        user_detail: null
    });

    singleUserRef(id).on('value', function (snapshot) {
        if (snapshot.val()) {
            const data = snapshot.val();
            data.id = id;
            dispatch({
                type: SELECT_USER_SUCCESS,
                user_detail: data
            });
        } else {
            dispatch({
                type: SELECT_USER_FAILED,
                user_detail: "No users available."
            });
        }
    });
}

export const updatePer = (uid, totalBook) => (dispatch) => (firebase) => {
    const {
        singleUserRef
    } = firebase;
    if (uid && uid != 'undefined') {
        singleUserRef(uid).update({per: totalBook ? totalBook : 0});
    }
};

