import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from "@material-ui/core/FormControl";
import {HiddenPhone} from "common/src/other/CommonFunctions";
import {Text} from "react-native-web";
import {callNow, getRecordAudio, paCallNow} from "common/src/other/PAApiFunctions";
import {ConvertTimeStampTimeForPa, GetCurrentTimeStamp} from "common/src/other/DateFunctions";
import {FirebaseContext} from "common";
import {useDispatch} from "react-redux";
import ShowCustomerCareDialog from "./ShowCustomerCareDialog";

const useStyles = makeStyles(theme => ({
    titleList: {
        fontSize: 14,
        marginTop: 10
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        height: 39,
    },
    backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)",
        flexDirection: 'column'
    },
    row: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "rgba(255,0,0,0.74)"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    input: {
        fontSize: 18,
        color: "#000"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
}));

export default function CallNowCenter(props) {
    const styles = useStyles();
    const {api} = useContext(FirebaseContext);
    const {
        setAudioCallCenterApi,
        setTimeCallCenterApi
    } = api;
    const dispatch = useDispatch();
    const [hasClickLine, setHasClickLine] = useState(false);
    const [isShowNumber, SetIsShowNumber] = useState(false);
    useEffect(() => {
        setHasClickLine(false);
    }, []);

    return (
        <FormControl>
            <Grid container spacing={1} style={{minHeight: 63}}>
                <Grid item sm={12} md={12} lg={12} style={{paddingTop: 28}}>
                    <Text>{"Cuộc gọi đi "}</Text>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="small"
                        className={styles.submit}
                        onClick={async () => {
                        }}
                    >
                        {"SĐT (Click để xem): " + HiddenPhone(props.phone)}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                {props.lineOptions && props.lineOptions.length > 0 ?
                    props.lineOptions.map((item, key) => {
                        return (
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disabled={hasClickLine}
                                    className={styles.submit}
                                    onClick={async () => {
                                        setTimeout(() => {
                                            paCallNow(props.phone, item.value).then((result => {
                                                if (result && result.code && result.code == "1000") {
                                                    if (props.firebase_id && props.time_call) {
                                                        props.time_call.push(GetCurrentTimeStamp());
                                                        setTimeCallCenterApi(props.firebase_id, props.time_call);
                                                    }
                                                }
                                            })).catch(error => {
                                                console.log(error);
                                            });

                                        }, 200);
                                    }}
                                >
                                    {item.label}
                                </Button>
                            </Grid>
                        )
                    })
                    : null
                }
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="red"
                        size="small"
                        id={"cancelbutton_" + props.uniqueid}
                        className={styles.submit}
                        onClick={props.closeCallback}
                    >
                        {"Bỏ qua"}
                    </Button>
                </Grid>
            </Grid>
        </FormControl>
    )
        ;
}
