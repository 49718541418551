import React, {useState, useEffect, useContext} from 'react';
import MaterialTable from 'material-table';
import {useSelector, useDispatch} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import {
    features,
    dateStyle,
    language
} from 'config';
import {FirebaseContext} from 'common';
import {CChart} from "@coreui/react-chartjs";
import {View, Text, Button} from "react-native-web";
import {colors} from "../config/theme";
import {TextField} from "@material-ui/core";
import {
    ConvertSecondToHm,
    ConvertTimeStampToDateRp,
    GetDateString,
    GetDateString00
} from "common/src/other/DateFunctions";

export default function Users() {
    const {api} = useContext(FirebaseContext);
    const {
        addUser,
        editUser,
        deleteUser,
        checkUserExists,
        fetchOnceUsers,
        reportCallCenter
    } = api;
    const [data, setData] = useState([]);
    const usersdata = useSelector(state => state.usersdata);
    const dispatch = useDispatch();
    const [startDateValue, setStartDateValue] = useState(GetDateString00());
    const [endDateValue, setEndDateValue] = useState(GetDateString());
    const [startDate, setStartDate] = useState(new Date(GetDateString00()).getTime() / 1000);
    const [endDate, setEndDate] = useState(new Date(GetDateString()).getTime() / 1000);
    const pacallcenterdatas = useSelector((state) => state.pacallcenterdata);
    const [uids, setUids] = useState(null);
    const [dataSetHours, setDataSetHours] = useState([]);
    const [dataSetCk, setDataSetCk] = useState([]);
    const [labels, setLabels] = useState(['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12']);
    useEffect(() => {
        dispatch(fetchOnceUsers());
    }, []);

    useEffect(() => {
        if (pacallcenterdatas.report_fleet && uids && typeof data === 'object' && Array.isArray(pacallcenterdatas.report_fleet)) {
            let datas = pacallcenterdatas.report_fleet;
            let dataSet = [];
            let dataSetACk = [];
            let colors = ['#f87979', '#cadc33', "#359b6e"]
            let listMonth = [];
            var monthStart = 0;
            var monthEnd = 0;

            if (startDate) {
                monthStart = parseInt(ConvertTimeStampToDateRp(startDate * 1000).split("/")[1]);
            }
            if (endDate) {
                monthEnd = parseInt(ConvertTimeStampToDateRp(endDate * 1000).split("/")[1]);
            }
            if (monthStart && monthEnd) {
                for (let i = monthStart; i <= monthEnd; i++) {
                    listMonth.push("Tháng " + i);
                }
            }

            for (let i = 0; i < datas.length; i++) {
                let dataPrepare = [];
                let dataPrepareCk = [];
                let dataPrepareCkSuccess = [];
                if (monthStart && monthEnd && datas[i]) {
                    for (let j = monthStart; j <= monthEnd; j++) {
                        dataPrepare.push(datas[i].time_active_array[j.toString()]);
                        dataPrepareCk.push(datas[i].total_call_array[j.toString()]);
                        dataPrepareCkSuccess.push(datas[i].total_success_array[j.toString()]);
                    }
                }
                let objFleet = {
                    label: datas[i].name,
                    backgroundColor: colors[i] ? colors[i] : '#232323',
                    data: dataPrepare,
                };
                dataSet.push(objFleet);
                let objectCk = {
                    label: datas[i].name,
                    backgroundColor: colors[i] ? colors[i] : '#232323',
                    data: dataPrepareCk,
                    dataSuccess: dataPrepareCkSuccess
                }
                dataSetACk.push(objectCk);
            }

            setDataSetCk(dataSetACk);
            setDataSetHours(dataSet);

            if (listMonth) {
                setLabels(listMonth);
            }

        }
    }, [pacallcenterdatas]);

    useEffect(() => {
        if (usersdata.once_users) {
            let fleets = usersdata.once_users.filter(user => user.usertype === 'fleetadmin');
            let listUID = [];
            for (let i = 0; i < fleets.length; i++) {
                listUID.push(fleets[i].id);
            }
            setUids(listUID);
            setData(fleets);
        } else {
            setData([]);
        }
    }, [usersdata.once_users]);

    const columns = [
        {
            title: language.createdAt,
            field: 'createdAt',
            editable: 'never',
            defaultSort: 'desc',
            render: rowData => rowData.createdAt ? new Date(rowData.createdAt).toLocaleString(dateStyle) : null
        },
        {title: "Mã NV", field: 'codeDriver'},
        {title: language.first_name, field: 'firstName', initialEditValue: ''},
        {title: language.last_name, field: 'lastName', initialEditValue: ''},
        {title: language.email, field: 'email', editable: 'onAdd'},
        {title: language.mobile, field: 'mobile', editable: 'onAdd'},
        {title: "Online?", field: 'isOnline', type: 'boolean', initialEditValue: true},
        {
            title: language.profile_image,
            field: 'profile_image',
            render: rowData => rowData.profile_image ?
                <img alt='Profile' src={rowData.profile_image} style={{width: 50, borderRadius: '50%'}}/> : null,
            editable: 'never'
        },
        {title: language.account_approve, field: 'approved', type: 'boolean', initialEditValue: true}
    ];

    return (
        usersdata.loading ? <CircularLoading/> :
            <>
                <MaterialTable
                    title={language.fleetadmins}
                    columns={columns}
                    data={data}
                    onSelectionChange={async (e) => {
                        let dataUid = [];
                        for (let i = 0; i < e.length; i++) {
                            dataUid.push(e[i].id);
                        }
                        setUids(dataUid);
                        if (dataUid && startDate && endDate) {
                            await dispatch(reportCallCenter(dataUid, startDate, endDate));
                        } else {
                            alert("Thiếu dữ liệu đầu vào!");
                        }
                    }
                    }
                    options={{
                        exportButton: true,
                        sorting: true,
                        selection: true
                    }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    checkUserExists(newData).then((res) => {
                                        if (res.users && res.users.length > 0) {
                                            alert(language.user_exists);
                                            reject();
                                        } else if (res.error) {
                                            alert(language.email_or_mobile_issue);
                                            reject();
                                        } else {
                                            newData['createdByAdmin'] = true;
                                            newData['usertype'] = 'fleetadmin';
                                            newData['createdAt'] = new Date().toISOString();
                                            newData['referralId'] = newData.firstName.toLowerCase() + Math.floor(1000 + Math.random() * 9000).toString();
                                            dispatch(addUser(newData));
                                            resolve();
                                        }
                                    });
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    dispatch(editUser(oldData.id, newData));
                                }, 600);
                            }),
                        onRowDelete: oldData =>
                            features.AllowCriticalEditsAdmin ?
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        resolve();
                                        dispatch(deleteUser(oldData.id));
                                    }, 600);
                                })
                                :
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        resolve();
                                        alert(language.demo_mode);
                                    }, 600);
                                })
                        ,
                    }}
                />
                <View style={{
                    flexDirection: 'column', justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: 800
                }}>
                    <View style={{
                        flexDirection: 'row', justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: 20,
                    }}>
                        <TextField
                            id="datetime-local-start"
                            label={"Thời gian bắt đầu"}
                            type="datetime-local"
                            variant="outlined"
                            inputProps={{
                                min: "2023-01-01T00:31",
                                max: "2023-12-31T00:59"
                            }}
                            value={startDateValue ? startDateValue : GetDateString00()}
                            onChange={(event) => {
                                setStartDateValue(event.target.value);
                                setStartDate(new Date(event.target.value).getTime() / 1000);
                            }
                            }
                        />
                        <TextField
                            id="datetime-local-end"
                            label={"Thời gian kết thúc"}
                            type="datetime-local"
                            variant="outlined"
                            inputProps={{
                                min: "2023-01-01T00:31",
                                max: "2023-12-31T00:59"
                            }}
                            value={endDateValue ? endDateValue : GetDateString()}
                            onChange={(event) => {
                                setEndDateValue(event.target.value);
                                setEndDate(new Date(event.target.value).getTime() / 1000);
                            }
                            }
                        />
                        <Button
                            onPress={async () => {
                                if (uids && startDate && endDate) {
                                    await dispatch(reportCallCenter(uids, startDate, endDate));
                                } else {
                                    alert("Thiếu dữ liệu đầu vào!");
                                }
                            }}
                            title={pacallcenterdatas.loading_report ? "Đang tải báo cáo" : "Lấy báo cáo"}
                            disable={pacallcenterdatas.loading_report}
                            titleStyle={{color: "#FFF"}}
                            color={colors.MAIN}
                            containerStyle={{
                                flex: 1,
                                alignSelf: "flex-end",
                                paddingRight: 14,
                            }}
                        />
                    </View>
                    <View style={{
                        flexDirection: 'column', justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: 100,
                        minWidth: 1000
                    }}>
                        <View style={{
                            flexDirection: 'column', justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <View style={{
                                flexDirection: 'row', justifyContent: 'center',
                                alignItems: 'center',
                                overflowX: "scroll",
                                minWidth: 1000,
                                padding: 10
                            }}>
                                {dataSetHours && dataSetHours.length > 0 ? labels.map((l, indexl) => {
                                    return (
                                        <>
                                            <Text>{l + " :"}</Text>
                                            <View>{
                                                dataSetHours.map((d) => {
                                                        return (
                                                            <Text>{d.label + ": " + (d.data[indexl] ? ConvertSecondToHm(d.data[indexl]) : ConvertSecondToHm(0))}</Text>
                                                        )
                                                    }
                                                )
                                            }</View>
                                        </>

                                    )
                                }) : null
                                }
                            </View>
                            <Text>{"Giờ làm việc"}</Text>
                            <CChart
                                type="bar"
                                id={"char_hours"}
                                data={{
                                    labels: labels,
                                    datasets: dataSetHours ? dataSetHours : []
                                }}
                                title={"Giờ làm việc"}
                                style={{
                                    width: 1000
                                }}
                            />
                        </View>

                        <View style={{
                            flexDirection: 'column', justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <View style={{
                                flexDirection: 'row', justifyContent: 'center',
                                alignItems: 'center',
                                overflowX: "scroll",
                                width: 1000,
                                padding: 10
                            }}>
                                {dataSetCk && dataSetCk.length > 0 ? labels.map((l, indexl) => {
                                    return (
                                        <>
                                            <Text>{l + " :"}</Text>
                                            <View>{
                                                dataSetCk.map((d) => {
                                                        return (
                                                            <Text>{d.label + ": " + (d.data[indexl] ? d.data[indexl] + "Calls" : 0 + "Calls")} { " - Done: " +  (d.dataSuccess[indexl] ? d.dataSuccess[indexl] + " " : 0 + " ")}</Text>
                                                        )
                                                    }
                                                )
                                            }</View>
                                        </>

                                    )
                                }) : null
                                }
                            </View>
                            <Text>{"Cuốc khách"}</Text>
                            <CChart
                                type="bar"
                                id={"char_ck"}
                                data={{
                                    labels: labels,
                                    datasets: dataSetCk ? dataSetCk : []
                                }}
                                title={"Cuốc khách"}
                                style={{
                                    width: 1000
                                }}
                            />
                        </View>
                    </View>
                </View>
            </>
    );
}
