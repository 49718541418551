import React, {useState, useEffect, useContext} from 'react';
import MaterialTable from 'material-table';
import {useSelector, useDispatch} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import {FirebaseContext} from 'common';

export default function MistakeConfig() {
    const {api} = useContext(FirebaseContext);
    const {
        editMistakeConfig
    } = api;

    const columns = [
        {title: "Mã vi phạm", field: 'mistake_code'},
        {
            title: "Loại Vi Pham", field: 'mistake_type',
            lookup: {don_khach: "Đón khách chậm", roi_diem: "Rời điểm lâu", sap_co: "Sập cò", vao_xe_muon: "Vào xe muộn", xin_nghi: "Xin nghỉ", huy_cuoc: "Hủy cuốc khách", khac: "Vi phạm khác"}
        },
        {title: "Tên vi phạm", field: 'mistake_name'},
        {title: "Thời gian khóa", field: 'mistake_time'},
        {title: "Mét quy định", field: 'mistake_distance'},
        {title: "Phút quy định", field: 'mistake_minute'},
        {title: "Mô tả", field: 'mistake_description'},
        {
            title: "Trạng thái", field: 'mistake_status',
            lookup: {active: "Đang áp dụng", inactive: "Không áp dụng"}
        }
    ];

    const [data, setData] = useState([]);
    const mistakeconfigdata = useSelector(state => state.mistakeconfigdata);
    const dispatch = useDispatch();
    useEffect(() => {
        if (mistakeconfigdata.mistake_config) {
            setData(mistakeconfigdata.mistake_config);
        }
    }, [mistakeconfigdata.mistake_config]);

    return (
        mistakeconfigdata.loading ? <CircularLoading/> :
            <MaterialTable
                title={"Quản lý loại vi phạm"}
                columns={columns}
                data={data}
                editable={{
                    onRowAdd: newData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                const tblData = data;
                                tblData.push(newData);
                                dispatch(editMistakeConfig(newData, "Add"));
                            }, 600);
                        }),

                    onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                const tblData = data;
                                tblData[tblData.indexOf(oldData)] = newData;
                                dispatch(editMistakeConfig(newData, "Update"));
                            }, 600);
                        }),
                    onRowDelete: newData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                dispatch(editMistakeConfig(newData, "Delete"));
                            }, 600);
                        }),
                }}
            />
    );
}
