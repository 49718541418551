import React from 'react';
import {View} from "react-native-web";
import {colors} from "../../config/theme";
import IconButton from "@mui/material/IconButton";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

export default function NotifyImportanceComponent(props) {
    const obj = props.callCenterObj;
    return (
        <View style={{
            maxWidth: 310, backgroundColor: 'rgba(255,255,255,0.77)',
            borderRadius: 10,
            marginTop: 5,
            shadowOffset: {
                height: 1,
                width: 0,
            },
            shadowColor: colors.BLACK,
            shadowOpacity: 0.35,
            shadowRadius: 10,
            border: 1,
            borderStyle: 'solid',
            borderColor: "#c7c7c7",
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: "100%",
                padding: 0,
                margin: 0
            }}>
                <IconButton
                    aria-label="Thông báo quan trọng"
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    color="primary"
                    size="large"
                    onClick={() => {

                    }}
                >
                    <img
                        height={"50"}
                        src={require("../../assets/img/notify_icon.png").default}
                        alt="Password"
                    />
                </IconButton>
                <IconButton
                    aria-label="Ghi chú"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexGrow: 1
                    }}
                    color="primary"
                    size="medium"
                    onClick={() => {

                    }}
                >
                    <CalendarMonthOutlinedIcon fontSize="inherit" style={{color: "#6059a7"}}/>
                </IconButton>
                <div style={{
                    display: 'flex',
                    flexGrow: 1,
                    position: 'relative',
                    borderRadius: 5,
                    backgroundColor: "rgba(255,255,255,0.71)",
                    marginTop: 15,
                    '&:hover': {
                        backgroundColor: "rgba(255,255,255,0.97)",
                    },
                }}>
                    <h4>{"Thời gian gọi đến"}</h4>
                </div>
            </div>


            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: "100%"
            }}>
                <h2 style={{color: "#005D9C", fontWeight: 'bold', fontSize: 20, margin: 0, padding: 0, paddingLeft: 10, paddingRight: 5}}>Tiêu đề: </h2>
                <div style={{
                    position: 'relative',
                    backgroundColor: "rgba(255,255,255,0.71)",
                    '&:hover': {
                        backgroundColor: "rgba(255,255,255,0.97)",
                    },
                    width: 200,
                    marginTop: 7
                }}>
                    <span>{"Thời gian gọi đến"}</span>
                </div>
            </div>
        </View>
    )
}
