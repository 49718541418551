import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from '@material-ui/core/Button';
import {FirebaseContext} from "common";
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";
import {Checkbox, colors, FormControl, FormGroup, FormHelperText, FormLabel} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import AlertDialog from "../AlertDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {FlatList, View} from "react-native";
import TextField from "@material-ui/core/TextField";
import {GetCurrentTimeStamp} from "common/src/other/DateFunctions";

const useStyles = makeStyles(theme => ({
    titleList: {
        fontSize: 14,
        marginTop: 10
    },
    submit: {
        backgroundColor: colors.orange.A200
    },
    backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)",
        flexDirection: 'column'
    },
    row: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "rgba(255,0,0,0.74)"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#E8E8E8",
        fontSize: 16,
        padding: '5px',
        color: "#000"
    },
    commonInputStyle: {
        borderRadius: 4,
        backgroundColor: "#E8E8E8",
    },
    title: {color: "#000"}
}));

export default function ShowNotifyDialog(props) {
    const {api} = useContext(FirebaseContext);
    const {
        pushNotifyImportance,
        stopFetchAreas,
        fetchAreas
    } = api;
    const dispatch = useDispatch();
    const styles = useStyles();
    const showModal = props.showModal;
    const hideModal = props.hideModal;
    const [commonAlert, setCommonAlert] = useState({open: false, msg: ''});
    const [codeSearch, setCodeSearch] = useState('');
    const areasdata = useSelector(state => state.areasdata.areas);
    const [listAreaDataLead, setListAreaDataLead] = useState([]);
    const [listAreaDataMember, setListAreaDataMember] = useState([]);
    const [listDriver, setListDriver] = useState([]);
    const [listArea, setListArea] = useState([]);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(fetchAreas());
        return () => {
            dispatch(stopFetchAreas());
        }
    }, []);

    useEffect(() => {
        if (props.noteBooking) {
            if (props.bookingDate) {
                setTitle("Thông báo CK: [" + props.bookingDate + "] " + props.noteBooking);
            } else {
                setTitle("Thông báo CK: " + props.noteBooking);
            }
        }
    }, [props.noteBooking, props.bookingDate]);

    useEffect(() => {
        if (areasdata) {
            let listLeader = areasdata.filter((a) => {
                if (a.leaders) {
                    let leaders = Object.values(a.leaders).filter((l) => l.role === 'leader');
                    if (leaders && leaders.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                }
            });
            let listMember = areasdata.filter((a) => {
                if (a.members) {
                    let leaders = Object.values(a.members).filter((l) => l.role === 'leader');
                    if (leaders && leaders.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                }
            });

            setListAreaDataMember(listLeader.concat(listMember));
        } else {
            setListAreaDataMember([]);
        }
    }, [areasdata]);

    useEffect(() => {
        let areas = listAreaDataLead.concat(listAreaDataMember);
        if (areas) {
            let members = [{
                checked: false,
                codeDriver: 'All'
            }];
            let areasList = [{
                checked: false,
                name: 'All'
            }];
            for (let i = 0; i < areas.length; i++) {
                if (areas[i].members) {
                    let memberList = Object.values(areas[i].members);
                    if (memberList) {
                        for (let j = 0; j < memberList.length; j++) {
                            let member = members.filter((m) => m.codeDriver == memberList[j].codeDriver);
                            if (member && member.length > 0) {
                            } else {
                                if (props.driverCode && memberList[j].codeDriver == props.driverCode) {
                                    memberList[j].checked = true;
                                } else {
                                    memberList[j].checked = false;
                                }
                                members.push(memberList[j]);
                            }
                        }
                    }
                    areas[i].checked = false;
                    areasList.push(areas[i]);
                }
            }
            setListDriver(members);
            setListArea(areasList);
        }
    }, [listAreaDataLead, listAreaDataMember]);

    const handleCommonAlertClose = (e) => {
        e.preventDefault();
        setCommonAlert({open: false, msg: ''})
    };

    const renderItemCheckboxMember = ({item}) => {
        if (props.isChatOnce) {
            return null;
        }
        let checked = listDriver.filter((m) => m.codeDriver == item.codeDriver)[0].checked;
        return (
            <FormControlLabel
                control={
                    <Checkbox checked={checked} onChange={(event) => {
                        let newValue = event.target.checked;
                        if (newValue === true) {
                            let members = [];
                            for (let j = 0; j < listDriver.length; j++) {
                                let member = members.filter((m) => m.codeDriver == listDriver[j].codeDriver);
                                if (member && member.length > 0) {
                                } else {
                                    if (listDriver[j].codeDriver == item.codeDriver || item.codeDriver === 'All') {
                                        listDriver[j].checked = true;
                                    }
                                    members.push(listDriver[j]);
                                }
                            }
                            setListDriver(members);
                        } else {
                            let members = [];
                            for (let j = 0; j < listDriver.length; j++) {
                                let member = members.filter((m) => m.codeDriver == listDriver[j].codeDriver);
                                if (member && member.length > 0) {
                                } else {
                                    if (listDriver[j].codeDriver == item.codeDriver || item.codeDriver === 'All') {
                                        listDriver[j].checked = false;
                                    }
                                    if (item.codeDriver !== 'All') {
                                        if (listDriver[j].codeDriver == 'All') {
                                            listDriver[j].checked = false;
                                        }
                                    }

                                    members.push(listDriver[j]);
                                }
                            }
                            setListDriver(members);
                        }
                    }} name={item.codeDriver}/>
                }
                label={item.codeDriver}
            />
        );
    }

    const renderItemCheckboxArea = ({item}) => {
        if (props.isChatOnce) {
            return null;
        }
        let checkedArea = listArea.filter((a) => a.id == item.id)[0].checked;
        return (
            <FormControlLabel
                control={
                    <Checkbox checked={checkedArea} onChange={(event) => {
                        let newValue = event.target.checked;
                        if (newValue === true) {
                            let areasList = [];
                            for (let i = 0; i < listArea.length; i++) {
                                if (listArea[i].id == item.id || item.name === 'All') {
                                    listArea[i].checked = true;
                                }
                                areasList.push(listArea[i]);
                            }
                            setListArea(areasList);
                        } else {
                            let areasList = [];
                            for (let i = 0; i < listArea.length; i++) {
                                if (listArea[i].id == item.id || item.name === 'All') {
                                    listArea[i].checked = false;
                                }
                                if (item.name !== 'All') {
                                    if (listArea[i].name == 'All') {
                                        listArea[i].checked = false;
                                    }
                                }
                                areasList.push(listArea[i]);
                            }
                            setListArea(areasList);
                        }
                    }} name={item.name}/>
                }
                label={item.name}
            />
        );
    }

    return (
        <Dialog
            open={showModal}
            onClose={hideModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={props.isChatOnce ? 'sm' : "xl"}
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">{props.isChatOnce && props.driverCode ? "Gửi thông báo cho tài xế [" + props.driverCode + "]" : "Gửi thông báo đặc biệt cho tài xế"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormControl>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="text_title"
                                    label={"Tiêu đề"}
                                    variant="filled"
                                    fullWidth
                                    className={styles.commonInputStyle}
                                    InputProps={{
                                        className: styles.input,
                                        style: {textAlignLast: 'start'}
                                    }}
                                    disabled={props.isChatOnce && props.noteBooking ? true : false}
                                    value={props.isChatOnce && props.noteBooking ? props.noteBooking : title}
                                    onChange={(event) => {
                                        setTitle(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="text_content"
                                    label={"Nội dung"}
                                    variant="filled"
                                    fullWidth
                                    className={styles.commonInputStyle}
                                    InputProps={{
                                        className: styles.input,
                                        style: {textAlignLast: 'start'}
                                    }}
                                    value={content}
                                    onChange={(event) => {
                                        setContent(event.target.value);
                                    }}
                                />
                            </Grid>
                            {!props.isChatOnce ?
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="text_search"
                                        label={"Tìm kiếm lái xe"}
                                        variant="filled"
                                        fullWidth
                                        className={styles.commonInputStyle}
                                        InputProps={{
                                            className: styles.input,
                                            style: {textAlignLast: 'start'}
                                        }}
                                        value={codeSearch}
                                        onChange={(event) => {
                                            setCodeSearch(event.target.value);
                                        }}
                                    />
                                </Grid> : null}
                            {!props.isChatOnce ?
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FormControl component="fieldset" variant="standard">
                                        <FormLabel component="legend">Gửi thông báo theo Tài Xế</FormLabel>
                                        <FormGroup>
                                            <FlatList
                                                data={codeSearch && codeSearch.length > 0 ? listDriver.filter((d) => d.codeDriver == codeSearch) : listDriver}
                                                ItemSeparatorComponent={() => <View style={{height: 1}}/>}
                                                renderItem={renderItemCheckboxMember}
                                                keyExtractor={(item, index) => index.toString()}
                                                key={"checkboxsendnotifymember"}
                                            />
                                        </FormGroup>
                                        <FormHelperText>Chọn lái xe để gửi thông báo</FormHelperText>
                                    </FormControl>
                                </Grid> : null}
                            {!props.isChatOnce ?
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FormControl
                                        component="fieldset"
                                        variant="standard"
                                    >
                                        <FormLabel component="legend">Gửi thông báo theo khu vực kd</FormLabel>
                                        <FormGroup>
                                            <FlatList
                                                data={listArea ? listArea : []}
                                                ItemSeparatorComponent={() => <View style={{height: 1}}/>}
                                                renderItem={renderItemCheckboxArea}
                                                keyExtractor={(item, index) => index.toString()}
                                                key={"checkboxsendnotifyarea"}
                                            />
                                        </FormGroup>
                                        <FormHelperText>Chọn vùng để gửi tât cả các tài xế ĐK</FormHelperText>
                                    </FormControl>
                                </Grid> : null}

                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    id={"notifybutton_save"}
                                    className={styles.submit}
                                    onClick={
                                        () => {
                                            if (title.length > 3 && content.length > 10) {
                                                for (let i = 0; i < listDriver.length; i++) {
                                                    if (listDriver[i].checked) {
                                                        let dataNotify = {
                                                            date_add: GetCurrentTimeStamp(),
                                                            status: 'pending',
                                                            type: 'leader_notify',
                                                            title: title,
                                                            content: content,
                                                            to: listDriver[i].uid,
                                                            params: {
                                                                leader: auth.info.profile
                                                            }
                                                        };
                                                        dispatch(pushNotifyImportance(dataNotify));
                                                    }
                                                }

                                                for (let j = 0; j < listArea.length; j++) {
                                                    if (listArea[j].checked) {
                                                        let memberList = Object.values(listArea[j].members);
                                                        if (memberList) {
                                                            for (let j = 0; j < memberList.length; j++) {
                                                                let dataNotify = {
                                                                    date_add: GetCurrentTimeStamp(),
                                                                    status: 'pending',
                                                                    type: 'leader_notify',
                                                                    title: title,
                                                                    content: content,
                                                                    to: memberList[j].uid,
                                                                    params: {
                                                                        leader: auth.info.profile
                                                                    }
                                                                };
                                                                //dispatch(pushNotifyImportance(dataNotify));
                                                            }
                                                        }
                                                    }
                                                }

                                                alert("Thông báo đã được gửi đến các lái xe");

                                            } else {
                                                alert("Tiêu đề > 3 và  nội dung phải > 10 ký tự");
                                            }

                                        }
                                    }
                                >
                                    {"Gửi thông báo"}
                                </Button>
                            </Grid>

                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="red"
                                    size="small"
                                    id={"cancelbutton_notify"}
                                    className={styles.submit}
                                    onClick={hideModal}
                                >
                                    {"Bỏ qua"}
                                </Button>
                            </Grid>
                        </Grid>
                        <AlertDialog titleConfirm={commonAlert.titleConfirm ? commonAlert.titleConfirm : null} open={commonAlert.open} onClose={commonAlert.onClose ? commonAlert.onClose : handleCommonAlertClose}
                                     onConfirm={commonAlert.onConfirm ? commonAlert.onConfirm : null}>{commonAlert.msg}</AlertDialog>
                    </FormControl>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
