import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect, useState} from "react";
import {FirebaseContext} from "common";
import {formatPriceRound, HiddenPhone} from "common/src/other/CommonFunctions";
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import {View} from "react-native-web";
import {formatNumber} from "react-native-currency-input";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {ConvertSecondToHm, GetCurrentMiniTimeStamp} from "common/src/other/DateFunctions";

const MaterialUISwitch = styled(Switch)(({theme}) => ({
    width: 130,
    height: 50,
    left: 10,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(15px) translateY(13px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(95px) translateY(13px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: "#6067EB",
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#001e3c',
        width: 20,
        height: 20,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 40 / 2,
    },
}));

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        width: 450
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        width: 450
    },
}));

function BootstrapDialogTitle(props) {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
export default function PopupBookingModal(props) {
    const {api} = useContext(FirebaseContext);
    const {
        updateTripPrice,
        updateTripCar,
        fetchDriversOutInGroup,
        addBookingV2,
        bookingDetailOn,
        updateTripGroup,
        bookingDetail
    } = api;
    const dispatch = useDispatch();
    const cartypes = useSelector((state) => state.cartypes.cars);
    const bookingDetailData = useSelector(state => state.bookingdata.bookingDetail);
    const tripdata = useSelector(state => state.tripdata);
    const [way, setWay] = useState({taxi: true, suv: true});
    const settings = useSelector(state => state.settingsdata.settings);
    const auth = useSelector(state => state.auth);
    const driverOutInGroup = useSelector(state => state.usersdata.driverOutInGroup);
    const [multicar, setMulticar] = useState(null);

    useEffect(() => {
        if (tripdata.groupApply && cartypes) {
            let carSelect = cartypes.filter((car) => car.type == 'taxi' && car.code == 'taxi')[0];
            dispatch(updateTripPrice(tripdata.groupApply.prices[carSelect.code]));
            dispatch(updateTripCar(carSelect));
            setMulticar({taxi: true});
        }
        if (tripdata.callCenterObj) {
            dispatch(bookingDetail(tripdata.callCenterObj.booking_id));
        }
    }, []);

    useEffect(() => {
        if (bookingDetailData && !tripdata.groupApply) {
            if (bookingDetailData.prices) {
                dispatch(updateTripPrice(bookingDetailData.prices));
            }
            if (cartypes && bookingDetailData.carTypeCode) {
                let carSelect = cartypes.filter((car) => car.type == 'taxi' && car.code == bookingDetailData.carTypeCode)[0];
                dispatch(updateTripCar(carSelect));
                setMulticar({taxi: bookingDetailData.carTypeCode == 'taxi', suv: bookingDetailData.carTypeCode == 'suv'});
            }

            if (bookingDetailData.driver_group) {
                dispatch(updateTripGroup(bookingDetailData.driver_group));
            }
        }
    }, [bookingDetailData]);

    useEffect(() => {
        if (tripdata && tripdata.callCenterObj && tripdata.carType && tripdata.groupApply && tripdata.price) {
            dispatch(fetchDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, tripdata.carType.code, tripdata.pickup, tripdata.groupApply.value));
        }
    }, [tripdata]);

    useEffect(() => {
        if (cartypes && !tripdata.carType) {
            let carSelect = cartypes.filter((car) => car.type == 'taxi' && car.code == 'taxi')[0]
            if (carSelect) {
                dispatch(updateTripCar(carSelect));
            }
        }
    }, [cartypes]);

    const formatPrice = (value) => {
        return formatPriceRound(value, formatNumber) + ("(đ)");
    }
    const handleChangeCheckboxCar = (event) => {
        let checked = event.target.checked;
        let value = event.target.value;
        multicar[value] = checked;
        setMulticar(multicar);
        if (tripdata.groupApply && checked) {
            let carSelect = cartypes.filter((car) => car.type == 'taxi' && car.code == value)[0];
            dispatch(updateTripPrice(tripdata.groupApply.prices[value]));
            dispatch(updateTripCar(carSelect));
        } else {
            dispatch(updateTripPrice(null));
            dispatch(updateTripCar(null));
        }
    };
    const loadBookingObj = () => {
        var bookingObject = {
            pickup: null,
            carDetails: null,
            userDetails: {
                uid: auth.info.uid,
                profile: {
                    firstName: "Trung Tâm",
                    lastName: "",
                    mobile: tripdata && tripdata.callCenterObj && tripdata.callCenterObj.source ? tripdata.callCenterObj.source : settings.panic,
                    note: tripdata && tripdata.callCenterObj && tripdata.callCenterObj.note ? tripdata.callCenterObj.note : "chưa có",
                    pushToken: auth.info.pushToken
                }
            },
            groupDetails: null,
            settings: settings,
            tripdate: GetCurrentMiniTimeStamp(),
            bookLater: false,
            bookingDate: GetCurrentMiniTimeStamp(),
            booking_type_web: true,
            source: "TT",
            type: "TT",
            callId: tripdata && tripdata.callCenterObj && tripdata.callCenterObj.firebase_id ? tripdata.callCenterObj.firebase_id : null,
            customer_note: tripdata && tripdata.callCenterObj && tripdata.callCenterObj.note ? tripdata.callCenterObj.note : null
        };

        if (tripdata.groupApply && tripdata.carType) {
            bookingObject.groupDetails = tripdata.groupApply;
            bookingObject.areaId = null;
            bookingObject.carDetails = tripdata.carType;
            bookingObject.carTypeCode = tripdata.carType.code;
            bookingObject.prices = tripdata.price ? tripdata.price : null;
        } else {
            alert("Lỗi dữ liệu đầu vào! Vui lòng liên hệ Admin!");
        }

        if (tripdata.pickup) {
            bookingObject.pickup = tripdata.pickup;
        }

        if (tripdata.drop) {
            bookingObject.drop = tripdata.drop;
        }

        bookingObject.isBooking = "OK";

        if (driverOutInGroup) {
            bookingObject.driverOutGroup = driverOutInGroup.driverOutGroup;
            bookingObject.driverInGroup = driverOutInGroup.driverInGroup;
            bookingObject.driverInAreaGroup = driverOutInGroup.resultInArea;
        }

        bookingObject.multicar = multicar;

        if (bookingObject.driverOutGroup || bookingObject.driverInGroup) {
            bookingObject.is_two_way = false;
            dispatch(addBookingV2(bookingObject));
            setTimeout(() => {
                props.handleCloseDialogBooking();
            }, 1000);
        } else {
            alert("Không tìm thấy lái xe phù hợp");
        }
    }

    return (
        <div>
            <BootstrapDialog
                onClose={props.handleCloseDialogBooking}
                aria-labelledby="customized-dialog-title"
                open={props.isOpen}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.handleCloseDialogBooking}>
                    {"Đặt cuốc khách "} {tripdata && tripdata.callCenterObj && tripdata.callCenterObj.source ? HiddenPhone(tripdata.callCenterObj.source) : ''}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <b>Ghi chú</b>: {tripdata && tripdata.callCenterObj && tripdata && tripdata.callCenterObj.note ? tripdata.callCenterObj.note : "chưa có"}
                    </Typography>
                    <Typography gutterBottom>
                        <b>Điểm kinh doanh</b>: {tripdata && tripdata.groupApply && tripdata && tripdata.groupApply.label ? tripdata.groupApply.label : "chưa có"}
                    </Typography>
                    {cartypes && cartypes.length > 0 ?
                        <FormGroup>
                            {cartypes.map((car) => {
                                if (car.type == 'taxi' && tripdata.groupApply) {
                                    let prices = tripdata.groupApply.prices[car.code];
                                    let price1 = prices ? prices.price1 : [];
                                    let price2 = prices ? prices.price2 : [];
                                    let openPrice = prices ? prices.open : 10000;
                                    let addPrice = prices ? prices.add : 60000;

                                    return (
                                        <View
                                            style={{
                                                width: '100%',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <View style={{
                                                width: '32%',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}>
                                                <img
                                                    height={150}
                                                    src={car.image}
                                                    alt={car.name}
                                                    style={{}}
                                                />
                                                {way ?
                                                    <FormControlLabel
                                                        control={
                                                            <>
                                                                <MaterialUISwitch name={car.code} sx={{m: 1}} defaultChecked={way[car.code] ? true : false} checked={way[car.code] ? true : false} onChange={(event) => {
                                                                    setWay({
                                                                        ...way,
                                                                        [event.target.name]: event.target.checked,
                                                                    });
                                                                }
                                                                }/>
                                                                <Typography style={{fontSize: 15, position: 'absolute', bottom: 22, left: way[car.code] ? "15%" : "30%", color: '#fff'}}>
                                                                    {way[car.code] ? "One Way" : "Two Way"}
                                                                </Typography>
                                                            </>
                                                        }
                                                        label=""
                                                    /> : null}

                                            </View>

                                            <View style={{
                                                width: "70%",
                                                alignItems: 'left',
                                                justifyContent: 'left',
                                                flexDirection: 'column',
                                            }}>
                                                <Typography align={"center"} style={{fontSize: 16}}>
                                                    <b style={{textTransform: "uppercase"}}>{car.name}</b>
                                                </Typography>
                                                <Typography align={"center"}>
                                                    {"Gồm: xe hợp đồng và cty"}
                                                </Typography>
                                                <Typography align={"left"}>
                                                    <b>Giá mở cửa</b> {formatPrice(openPrice)}
                                                </Typography>
                                                {way[car.code] ?
                                                    price1.map((price) => {
                                                        if (price.to) {
                                                            return (
                                                                <Typography align={"left"}>
                                                                    Từ km {price.from} đến km {price.to} : {formatPrice(price.price)}/km
                                                                </Typography>
                                                            )
                                                        } else {
                                                            return (
                                                                <Typography align={"left"}>
                                                                    Km tiếp : {formatPrice(price.price)}/km
                                                                </Typography>
                                                            )
                                                        }

                                                    })
                                                    : price2.map((price) => {
                                                        if (price.km) {
                                                            return (
                                                                <>
                                                                    <Typography align={"left"}>
                                                                        - Thuê bao {price.km} : {formatPrice(price.unitPrice)}/km
                                                                    </Typography>
                                                                    <Typography align={"left"}>
                                                                        &nbsp;&nbsp;&nbsp;{ConvertSecondToHm(price.time * 60)}
                                                                    </Typography>
                                                                </>
                                                            )
                                                        } else {
                                                            return (
                                                                <Typography align={"left"}>
                                                                    Km tiếp : {formatPrice(price.price)}/km
                                                                </Typography>
                                                            )
                                                        }

                                                    })
                                                }

                                                <FormControlLabel control={<Checkbox defaultChecked={multicar && multicar[car.code] ? true : false} value={car.code} onChange={handleChangeCheckboxCar}/>} label="" style={{marginLeft: "80%", marginTop: 20}}/>
                                            </View>
                                        </View>
                                    )
                                }
                            })}
                        </FormGroup>
                        : null}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={props.handleCloseDialogBooking} style={{color: "#fa0000"}}>
                        Huỷ
                    </Button>
                    <Button autoFocus onClick={() => {
                        if (tripdata.callCenterObj && tripdata.callCenterObj.booking_id) {
                            dispatch(bookingDetailOn(tripdata.callCenterObj.booking_id));
                            setTimeout(() => {
                                props.handleCloseDialogBooking();
                            }, 1000);
                        } else {
                            loadBookingObj();
                        }
                    }
                    }>
                        Đặt xe
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}