import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import MaterialTable from 'material-table';
import {useDispatch, useSelector} from "react-redux";
import {FirebaseContext} from 'common';
import {Button, View} from "react-native-web";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {ConvertTimeStampToDate, GetDateString, GetDateString00} from "common/src/other/DateFunctions";
import {formatNumber} from "react-native-currency-input";
import {formatPriceRound} from "common/src/other/CommonFunctions";
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {GpsFixed, StarTwoTone} from "@material-ui/icons";
import CustomerCareCallCenterComponent from "../components/CallCenter/CustomerCareCallCenterComponent";
import ButtonCallCenterDetail from "../components/CallCenter/ButtonCallCenterDetail";
import ShowCustomerCareDialog from "../components/CallCenter/ShowCustomerCareDialog";

const useStyles = makeStyles(theme => ({
    titleList: {
        fontSize: 14,
        marginTop: 10
    },
    bgLine: {
        backgroundColor: "rgba(101,99,99,0.74)",
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        height: 39,
    },
    backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)",
        flexDirection: 'column'
    },
    row: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "rgba(255,0,0,0.74)"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    input: {
        fontSize: 18,
        color: "#000"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    }
}));
export default function CustomerCare() {
    const {api, listBookingByDriverCodeRef} = useContext(FirebaseContext);
    const classes = useStyles();
    const {fetchCallDetailAll, stopFetchCallDetailAll} = api;
    const styles = useStyles();
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const [dateStart, setDateStart] = useState(new Date(GetDateString00()).getTime() / 1000);
    const [startDateValue, setStartDateValue] = useState(GetDateString00());
    const [dateEnd, setDateEnd] = useState(new Date(GetDateString()).getTime() / 1000);
    const [endDateValue, setEndDateValue] = useState(GetDateString());
    const [codes, setCodes] = useState(null);
    const tableRef = useRef();
    const [booking, setBooking] = useState(null);
    const settingsdata = useSelector((state) => state.settingsdata);
    const [dataSetting, setDataSetting] = useState({
        note: "",
        reasons: "",
    });
    const [popupData, setPopupData] = useState(null);
    const [callObj, setCallObj] = useState(null);
    const [showCustomerCareDialog, setShowCustomerCareDialog] = useState(false);

    useEffect(() => {
        dispatch(fetchCallDetailAll());
        return () => {
            dispatch(stopFetchCallDetailAll());
        };
    }, []);

    const formatPrice = (value) => {
        return formatPriceRound(value, formatNumber);
    }

    const callDetailData = useSelector(
        (state) => state.pacallcenterdata.callDetail
    );

    useEffect(() => {
        if (settingsdata.settings) {
            setDataSetting(settingsdata.settings);
        }
    }, [settingsdata.settings]);

    const showModalCustomerCareCallback = useCallback((rowDataInput, callObjInput) => {
        setTimeout(() => {
            if (rowDataInput) {
                setPopupData(rowDataInput);
            }
            if (callObjInput) {
                setCallObj(callObjInput);
            }
            setShowCustomerCareDialog(true);
        }, 1000);
    }, []);

    const hideModalCallback = useCallback(() => {
        setCallObj(null);
        setPopupData(null);
        setShowCustomerCareDialog(false);
        dispatch({
            type: 'SELECT_BOOKING_DETAIL_SUCCESS', payload: null
        });
    }, []);

    const columns = [
        {
            title: "Ngày giờ nhận",
            field: 'accept_time',
            render: rowData => <span>{ConvertTimeStampToDate(rowData.accept_time)}</span>
        },
        {
            title: "Ngày giờ khách lên xe",
            field: 'accept_time',
            render: rowData => <span>{ConvertTimeStampToDate(rowData.startTime)}</span>
        },
        {
            title: "Ngày giờ trả",
            field: 'trip_end_time',
            render: rowData => <span>{ConvertTimeStampToDate(rowData.trip_end_time * 1000)}</span>
        },
        {
            title: "Thanh toán",
            field: 'trip_end_time',
            render: rowData => <span>{formatPrice(rowData.customer_paid)}</span>
        },
        {
            title: "Báo cáo",
            field: "customer_contact",
            render: (rowData) => {
                let source = rowData.customer_contact;
                var callObj = {};
                if (callDetailData && callDetailData[source]) {
                    callObj = callDetailData[source];
                }

                //props.callObj.alias

                if (rowData.id) {
                    if (
                        rowData.codeDriver &&
                        dataSetting &&
                        dataSetting.driverCodeList
                    ) {
                        let listDriverCodeArray = dataSetting.driverCodeList.split(",");
                        if (listDriverCodeArray.includes(rowData.codeDriver)) {
                            return (
                                <View
                                    style={{
                                        justifyContent: "center",
                                        minWidth: 200,
                                        flexDirection: "row",
                                    }}
                                >
                                    <CustomerCareCallCenterComponent rowData={rowData} callObj={callObj}
                                                                     isBooking={true}
                                                                     showModalCustomerCareCallback={showModalCustomerCareCallback}
                                                                     hideModal={hideModalCallback}/>
                                </View>
                            );
                        } else {
                            return callObj && callObj.alias
                                ? callObj.alias
                                : "Cập nhật bí danh";
                        }
                    } else {
                        return callObj && callObj.alias
                            ? callObj.alias
                            : "Cập nhật bí danh";
                    }
                    //return callObj && callObj.alias ? callObj.alias : "Cập nhật bí danh";
                }
            },
        }
    ];

    const findBookingByDriverCode = () => {
        if (!dateStart || !dateEnd || !codes) {
            alert("Vui lòng nhập đầy đủ ngày giờ và mã lái xe.");
            return null;
        } else {
            listBookingByDriverCodeRef(codes).once("value", (snapshot) => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    const bookings = Object.keys(data)
                        .filter(i => data[i].bookingDate >= dateStart * 1000 && data[i].bookingDate <= dateEnd * 1000)
                        .map((i) => {
                            data[i].id = i;
                            return data[i];
                        });
                    if (bookings) {
                        setData(bookings.reverse());
                    } else {
                        setData([]);
                    }
                }
            });
        }
    }

    const loadSound2 = async () => {
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography component="h1" variant="h5" align="center" className={classes.marginBottom50}>
                    {"Chăm sóc khách hàng"}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} style={{marginTop: 50}}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <TextField
                            id="text_time_start"
                            label={"Ngày Giờ Bắt Đầu"}
                            variant="filled"
                            type="datetime-local"
                            fullWidth
                            value={startDateValue ? startDateValue : GetDateString00()}
                            inputProps={{
                                min: "2023-01-01T00:31",
                                max: "2023-12-31T00:59"
                            }}
                            onChange={(event) => {
                                let text = event.target.value;
                                setStartDateValue(text);
                                setDateStart(new Date(text).getTime() / 1000);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <TextField
                            id="text_time_end"
                            label={"Ngày giờ kết thúc"}
                            variant="filled"
                            fullWidth
                            type="datetime-local"
                            inputProps={{
                                min: "2023-01-01T00:31",
                                max: "2023-12-31T00:59"
                            }}
                            value={endDateValue ? endDateValue : GetDateString()}
                            onChange={(event) => {
                                let text = event.target.value;
                                setEndDateValue(text);
                                setDateEnd(new Date(text).getTime() / 1000);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                        <TextField
                            id="text_driver_code"
                            label={"Số đàm"}
                            variant="filled"
                            fullWidth
                            value={month}
                            onChange={(event) => {
                                let text = event.target.value;
                                setCodes(text);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Button
                            onPress={() => {
                                setTimeout(()=>{
                                    findBookingByDriverCode();
                                },1000);
                            }}
                            title={"Tìm"}
                            titleStyle={{color: "#FFF"}}
                            containerStyle={{
                                flex: 1,
                                alignSelf: 'flex-end',
                                paddingRight: 14
                            }}
                        />
                    </Grid>
                </Grid>

                <MaterialTable
                    title={"Danh sách chuyến đi thực hiện"}
                    columns={columns}
                    data={data ? data : []}
                    options={{
                        exportButton: true,
                        sorting: true,
                        filtering: true,
                        pageSize: 25,
                        pageSizeOptions: [10, 25, 50, 100]
                    }}
                    onRowClick={(event, rowData) => {
                        setBooking(rowData);
                    }}
                    tableRef={tableRef}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} style={{marginTop: 50}}>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <span>Chi tiết cuốc khách {booking ? booking.customer_note : ""}</span>
                    </Grid>
                    {booking ?
                        <Grid item xs={12} sm={12} md={12} lg={12} className={styles.bgLine}>
                            <VerticalTimeline animate={true}>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                                    contentArrowStyle={{borderRight: '7px solid  rgb(33, 150, 243)'}}
                                    date={ConvertTimeStampToDate(booking.bookingDate)}
                                    iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                                    icon={<GpsFixed/>}
                                >
                                    <h3 className="vertical-timeline-element-title">{"Đặt cuốc khách"}</h3>
                                    <h4 className="vertical-timeline-element-subtitle">{"Note: " + booking.customer_note}</h4>
                                    <p>{"Điểm bắn: " + booking.driver_group.label}</p>
                                </VerticalTimelineElement>

                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                                    contentArrowStyle={{borderRight: '7px solid  rgb(33, 150, 243)'}}
                                    date={booking.accept_time_stone ? ConvertTimeStampToDate(booking.accept_time_stone.at) : null}
                                    iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                                    icon={<GpsFixed/>}
                                >
                                    <h3 className="vertical-timeline-element-title">{"Nhận khách"}</h3>
                                    <h4 className="vertical-timeline-element-subtitle">{"Note: " + booking.customer_note}</h4>
                                    <p>{"Vị trí nhận: " + booking.pickupAddress}</p>
                                </VerticalTimelineElement>

                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    date={booking.trip_start_time}
                                    iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                                    icon={<GpsFixed/>}
                                >
                                    <h3 className="vertical-timeline-element-title">{"Bật đồng hồ"}</h3>
                                    <h4 className="vertical-timeline-element-subtitle">{"Lái xe bật đồng hồ"}</h4>
                                    <p>
                                        {"Vị trí: " + booking.pickupAddress}
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    date={booking.reached_time ? ConvertTimeStampToDate(booking.reached_time.at) : null}
                                    iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                                    icon={<GpsFixed/>}
                                >
                                    <h3 className="vertical-timeline-element-title">Tắt đồng hồ</h3>
                                    <h4 className="vertical-timeline-element-subtitle">{"Lái xe tắt đồng hồ"}</h4>
                                    <p>
                                        {"Vị trí: " + " ..."}
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    date={ConvertTimeStampToDate(booking.trip_end_time)}
                                    iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                                    icon={<GpsFixed/>}
                                >
                                    <h3 className="vertical-timeline-element-title">Hoàn thành cuốc khách</h3>
                                    <h4 className="vertical-timeline-element-subtitle">Lái xe thực hiện thanh toán</h4>
                                    <p>
                                        {"Vị trí: " + " ..."}
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    iconStyle={{background: 'rgb(16, 204, 82)', color: '#fff'}}
                                    icon={<StarTwoTone/>}
                                />
                            </VerticalTimeline>
                        </Grid> : null}
                </Grid>
            </Grid>
            {showCustomerCareDialog && popupData ?
                <ShowCustomerCareDialog showModal={showCustomerCareDialog} hideModal={hideModalCallback} isBooking={true}
                                        rowData={popupData} callObj={callObj ? callObj : null}/> : null}
        </Grid>
    );
}