import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Typography} from '@material-ui/core';
import Map from '../components/Map';
import {Google_Map_Key, language} from "config";
import {Modal, Platform, SafeAreaView, View} from "react-native-web";
import {makeStyles} from "@material-ui/core/styles";
import {FirebaseContext} from "common";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "@mui/material/IconButton";
import {colors} from "../config/theme";
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import {styled} from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from "@mui/material/InputBase";
import SegmentTwoToneIcon from "@mui/icons-material/SegmentTwoTone";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import {ConvertTimeStampPaUnique, ConvertTimeStampToDate, GetCurrentTimeStamp} from "common/src/other/DateFunctions";
import CallCenterRowComponent from "../components/Dashboard/CallCenterRowComponent";
import NotifyImportanceComponent from "../components/Dashboard/NotifyImportanceComponent";
import ChangeCarComponent from "../components/Dashboard/ChangeCarComponent";
import CskhComponent from "../components/Dashboard/CskhComponent";
import BookingCancelComponent from "../components/Dashboard/BookingCancelComponent";
import CallNowCenter from "../components/CallCenter/CallNowCenter";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import * as geolib from "geolib";
import {GetDistance} from "common/src/other/GeoFunctions";
import CancelCallCenter from "../components/CallCenter/CancelCallCenter";
import {SELECT_BOOKING_DETAIL_SUCCESS} from "common/src/store/types";

const CALL_CENTER = 'call_center';
const NOTIFY_IMPORTANCE = 'notify_importance';
const CHANGE_CALENDAR = 'change_calendar';
const CSHK = 'cskh';
const BOOKING_CANCEL = 'booking_cancel';

const inputStyle = {
    color: '#232323'
};
const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: 5,
    backgroundColor: "rgba(255,255,255,0.71)",
    '&:hover': {
        backgroundColor: "rgba(255,255,255,0.97)",
    },
    width: 250,
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const useStyles = makeStyles(theme => ({
    fullHeight: {
        height: Platform.OS === 'web' ? '100vh' : '100%'
    },
    container: {
        flex: 1,
        backgroundColor: "#ababab",
    },
    footer: {
        flexDirection: 'row',
        backgroundColor: 'white',
        borderBottomColor: '#666666',
    },
    input: {
        paddingHorizontal: 20,
        fontSize: 18,
        flex: 1
    },
    send: {
        alignSelf: 'center',
        color: 'lightseagreen',
        fontSize: 16,
        fontWeight: 'bold',
        padding: 20
    },
    diaglog: {
        right: 0
    }
}));

const Dashboard = (props) => {
    const {api} = useContext(FirebaseContext);
    const {
        fetchAllDriversQueue,
        fetchAllDrivers,
        fetchAllDriversOff,
        fetchAllDriversQueueOff,
        fetchCallsPending,
        stopFetchCallsPending,
        fetchReportDrivers,
        stopFetchReportDrivers,
        updateTripPickup,
        updateTripDrop,
        updateTripCallCenter,
        updateTripGroup
    } = api;
    const dispatch = useDispatch();

    const [mylocation, setMylocation] = useState(null);
    const classes = useStyles();
    const [callCenterDetail, setCallCenterDetail] = useState(null);
    const [bookingObj, setBookingObj] = useState(null);
    const [listCall, setListCall] = useState([]);
    const pacallcenterdatas = useSelector((state) => state.pacallcenterdata);
    const [soundAlert, setSoundAlert] = useState(null);
    const [datas, setDatas] = useState([]);
    const reportdriverdata = useSelector((state) => state.reportdriverdata);
    const [dataRpImportance, setDataRpImportance] = useState([]);
    const [soundAlert2, setSoundAlert2] = useState(null);
    const [soundAlertViPham, setSoundAlertViPham] = useState(null);
    const [soundAlertHuy, setSoundAlertHuy] = useState(null);
    const [soundAlertHoTro, setSoundAlertHoTro] = useState(null);
    const [soundAlertDoiCa, setSoundAlertDoiCa] = useState(null);
    const [showCallNow, setShowCallNow] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [showCallModal, setShowCallModal] = useState(false);
    const [searchAddress, setSearchAddress] = useState(null);
    const [polygons, setPolygons] = useState(null);
    const [group, setGroup] = useState(null);
    const [groupApply, setGroupApply] = useState(null);
    const [pickupFromMap, setPickupFromMap] = useState(null);
    const [dropFromMap, setDropFromMap] = useState(null);
    const [listGroup, setListGroup] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const groupDriverData = useSelector(
        (state) => state.groupdriverdata.group_kd
    );
    const [openDialogBooking, setOpenDialogBooking] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const catrucdems = useSelector(state => state.catrucdems.catrucdem);
    useEffect(() => {
        dispatch(fetchAllDriversQueue());
        dispatch(fetchAllDrivers());
        dispatch(fetchCallsPending());
        dispatch(fetchReportDrivers());
        loadSoundHoTro();
        loadSound2();
        loadSoundViPham();
        loadSoundDoiCa();
        return () => {
            dispatch(fetchAllDriversOff());
            dispatch(fetchAllDriversQueueOff());
            dispatch(stopFetchCallsPending());
            dispatch(stopFetchReportDrivers());
        };
    }, []);


    useEffect(() => {
        if (catrucdems && catrucdems.histories) {
            let dataChangeArray = [];
            let listCd = Object.keys(catrucdems.histories).map((key) => {
                let cdObj = catrucdems.histories[key];
                cdObj.id = key;
                if (cdObj.status == "pending") {
                    dataChangeArray.push({
                        at: GetCurrentTimeStamp(),
                        item: cdObj,
                        type: CHANGE_CALENDAR,
                        status: true
                    });
                }
            });

            if (dataChangeArray) {
                let newDatas = datas.filter((dt) => dt.type != CHANGE_CALENDAR);
                let dataSort = [...newDatas, ...dataChangeArray].sort(function (a, b) {
                    return (b.at - a.at);
                });
                setDatas(dataSort);
            }
        }
    }, [catrucdems]);

    useEffect(() => {
        if (groupDriverData && groupDriverData.length > 0) {
            setListGroup(
                groupDriverData.filter((g) => g.value != 11 && g.value != 10)
            );
        }
    }, [groupDriverData]);

    useEffect(() => {
        if (pacallcenterdatas.calls && pacallcenterdatas.calls.length > 0) {
            let resultCall = [];
            for (let i = 0; i < pacallcenterdatas.calls.length; i++) {
                if (
                    i > 0 &&
                    pacallcenterdatas.calls[i].source ===
                    pacallcenterdatas.calls[i - 1].source &&
                    ConvertTimeStampToDate(pacallcenterdatas.calls[i].time * 1000) ===
                    ConvertTimeStampToDate(
                        pacallcenterdatas.calls[i - 1].time * 1000
                    ) &&
                    pacallcenterdatas.calls[i].status ===
                    pacallcenterdatas.calls[i - 1].status
                ) {
                    continue;
                } else {
                    pacallcenterdatas.calls[i].date_formated = ConvertTimeStampToDate(
                        pacallcenterdatas.calls[i].time * 1000
                    );
                    pacallcenterdatas.calls[i].date_format_pa = ConvertTimeStampPaUnique(
                        pacallcenterdatas.calls[i].time * 1000
                    );
                    resultCall.push(pacallcenterdatas.calls[i]);
                }
            }

            let checkListCall1 = resultCall.filter(
                (item) =>
                    (!item.reason) &&
                    (item.status != "accept" && item.status != "paid") &&
                    (!item.note || (item.note && item.note != "Khách quen lái xe")) &&
                    (!item.type || (item.type && item.type != "KH")) &&
                    (!item.type || (item.type && item.type != "LX"))
            );
            let checkListCall2 = listCall.filter(
                (item) =>
                    (!item.reason) &&
                    (item.status != "accept" && item.status != "paid") &&
                    (!item.note || (item.note && item.note != "Khách quen lái xe")) &&
                    (!item.type || (item.type && item.type != "KH")) &&
                    (!item.type || (item.type && item.type != "LX"))
            );
            if (listCall) {
                if (checkListCall1.length > checkListCall2.length) {
                    playSound();
                }
            }

            setListCall(checkListCall1);
        } else {
            setListCall([]);
        }
    }, [pacallcenterdatas.calls]);

    useEffect(() => {
        let dataArray = [];
        if (listCall) {
            for (let i = 0; i < listCall.length; i++) {
                if (listCall[i].status == 'pending') {
                    dataArray.push({
                        at: listCall[i].time,
                        item: listCall[i],
                        type: CALL_CENTER,
                        status: true
                    });
                } else {
                    dataArray.push({
                        at: listCall[i].time,
                        item: listCall[i],
                        type: BOOKING_CANCEL,
                        status: true
                    });
                }
            }
        }

        let newDatas = datas.filter((dt) => dt.type != CALL_CENTER && dt.type != BOOKING_CANCEL);
        let dataSort = [...newDatas, ...dataArray].sort(function (a, b) {
            return (b.at - a.at);
        });
        setDatas(dataSort);
    }, [listCall]);

    useEffect(() => {
        if (reportdriverdata.reports) {
            let listAlert = reportdriverdata.reports.filter(
                (report) =>
                    report.content.includes("hủy 1") ||
                    report.content.includes("hủy 0") ||
                    report.content.includes("hủy 2") ||
                    report.content.includes("hủy 3") ||
                    report.content.includes("hỗ trợ") ||
                    report.content.includes("vi phạm") ||
                    report.content.includes("nhận xe") ||
                    report.content.includes("đã đồng ý thay") ||
                    report.content.includes("hủy đặt xe") ||
                    report.content.includes("điểm đón cuốc khách") ||
                    report.content.includes("tự động hủy")
            );

            if (dataRpImportance) {
                if (listAlert && listAlert.length > dataRpImportance.length) {
                    if (
                        listAlert[0] &&
                        (listAlert[0].content.includes("hủy 0") ||
                            listAlert[0].content.includes("hủy 0") ||
                            listAlert[0].content.includes("hủy 2") ||
                            listAlert[0].content.includes("hủy 3") ||
                            listAlert[0].content.includes("tự động hủy"))
                    ) {
                        playSound2();
                    }

                    if (listAlert[0] && listAlert[0].content.includes("vi phạm")) {
                        playSoundViPham();
                    }

                    if (listAlert[0] && listAlert[0].content.includes("hủy đặt xe")) {
                        playSoundHuy();
                    }

                    if (listAlert[0] && listAlert[0].content.includes("đã đồng ý thay")) {
                        playSoundDoiCa();
                    }

                    if (listAlert[0] && listAlert[0].content.includes("hỗ trợ")) {
                        playSoundHoTro();
                    }
                }
            } else {
                playSound2();
            }
            setDataRpImportance(listAlert);
        } else {
            setDataRpImportance([]);
        }
    }, [reportdriverdata.reports]);

    useEffect(() => {
        if (group) {
            if (group != 'Vùng tự do') {
                let groupApplys = listGroup.filter((g) => g.label == group);
                if (groupApplys && groupApplys.length > 0) {
                    let listAreaApply = groupApplys[0].area_ids;
                    let listAreas = groupApplys[0].areas;
                    let polygonList = [];
                    if (listAreas && listAreas.length > 0) {
                        for (let i = 0; i < listAreas.length; i++) {
                            if (listAreas[i]) {
                                let coors = Object.values(listAreas[i]);
                                for (let j = 0; j < coors.length; j++) {
                                    if (coors[j][0] && coors[j][1]) {
                                        polygonList.push({
                                            lat: coors[j][1], lng: coors[j][0]
                                        });
                                    }
                                }
                            }
                        }
                        setPolygons(polygonList);
                    }
                    if (listAreaApply) {
                        if (groupApplys[0] && groupApplys[0].coord) {
                            setMylocation({
                                lat: parseFloat(groupApplys[0].coord.split(',')[0]),
                                lng: parseFloat(groupApplys[0].coord.split(',')[1])
                            });
                        }

                        setGroupApply(groupApplys[0]);
                        /*if (groupApplys[0] && groupApplys[0].prices && tripdata.carType.code) {
                            setPrices(groupApplys[0].prices[tripdata.carType.code]);
                        }*/
                    } else {
                        //setListAreaSelect([]);
                        setGroupApply(null);
                    }
                } else {
                    setPolygons(null);
                    //setListAreaSelect([]);
                    setGroupApply(null);
                }

                dispatch(updateTripPickup(null));
                dispatch(updateTripDrop(null));
                setDropFromMap(null);
                setPickupFromMap(null);
            } else {
                dispatch(updateTripPickup(null));
                dispatch(updateTripDrop(null));
                setDropFromMap(null);
                setPickupFromMap(null);
                //setPolygons(null);
            }
        } else {
            dispatch(updateTripPickup(null));
            dispatch(updateTripDrop(null));
            setPolygons(null);
            //setListAreaSelect([]);
            setGroupApply(null);
        }
    }, [group]);

    useEffect(() => {
        if (groupApply) {
            dispatch(updateTripGroup(groupApply));
        }
    }, [groupApply]);

    const handleClickMenuGroup = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeGroup = useCallback((value) => {
        setGroup(value);
    }, []);

    const showCall = useCallback((rowData) => {
        setShowCallNow({
            time_call: rowData.time_call_out ? rowData.time_call_out : [],
            phone: rowData.source,
            uniqueid: rowData.uniqueid,
            firebase_id: rowData.firebase_id,
            time: rowData.time,
            audios: rowData.audios ? rowData.audios : [],
            source: rowData.source,
        });
        setModalVisible(true);
        setShowCallModal(true);
    }, []);

    const closeShowCall = useCallback(() => {
        setModalVisible(false);
        setShowCallNow(null);
        setShowCallModal(false);
    }, []);

    const loadSoundDoiCa = async () => {
        const sound = new Audio(
            require("../assets/sounds/xetduyetcadem.wav").default
        );
        sound.load();
        setSoundAlertDoiCa(sound);
    };

    const loadSoundHoTro = async () => {
        const sound = new Audio(
            require("../assets/sounds/hotrochiduong.wav").default
        );
        sound.load();
        setSoundAlertHoTro(sound);
    };

    const playSoundHoTro = async () => {
        if (soundAlertHoTro) {
            const audioPromise = soundAlertHoTro.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };
    const loadSound2 = async () => {
        const sound = new Audio(
            require("../assets/sounds/cuockhachhuydatlai.wav").default
        );
        sound.load();
        setSoundAlert2(sound);
    };

    const playSound2 = async () => {
        if (soundAlert2) {
            const audioPromise = soundAlert2.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    const loadSoundHuy = async () => {
        const sound = new Audio(
            require("../assets/sounds/cuokhachhuy.wav").default
        );
        sound.load();
        setSoundAlertHuy(sound);
    };

    const playSoundHuy = async () => {
        if (soundAlertHuy) {
            const audioPromise = soundAlertHuy.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    useEffect(() => {
        if (!soundAlertHuy) {
            loadSoundHuy();
        }
    }, [soundAlertHuy, setSoundAlertHuy]);


    const playSoundDoiCa = async () => {
        if (soundAlertDoiCa) {
            const audioPromise = soundAlertDoiCa.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    const loadSoundViPham = async () => {
        const sound = new Audio(require("../assets/sounds/loivipham.wav").default);
        sound.load();
        setSoundAlertViPham(sound);
    };

    const playSoundViPham = async () => {
        if (soundAlertViPham) {
            const audioPromise = soundAlertViPham.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    useEffect(() => {
        if (mylocation == null) {
            if (props.location && props.location.state && props.location.state.bookingObject) {
                if (props.location.state.bookingObject.groupDetails && props.location.state.bookingObject.groupDetails.coord) {
                    setMylocation({
                        lat: parseFloat(props.location.state.bookingObject.groupDetails.coord.split(",")[0]),
                        lng: parseFloat(props.location.state.bookingObject.groupDetails.coord.split(",")[1].toString().trim())
                    });
                }
            } else {
                navigator.geolocation.getCurrentPosition(
                    position => setMylocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }),
                    err => console.log(err)
                );
            }
        }
    }, [mylocation]);

    useEffect(() => {
        if (searchAddress) {

        }
    }, [searchAddress]);

    useEffect(() => {
        if (props.location.state && props.location.state.callDetail) {
            setCallCenterDetail(props.location.state.callDetail);
        } else {
            setCallCenterDetail(null);
        }
        if (props.location.state && props.location.state.bookingObject) {
            setBookingObj(props.location.state.bookingObject);
        } else {
            setBookingObj(null);
        }
    }, [props.location.state]);

    useEffect(() => {
        if (!soundAlert) {
            loadSound();
        }
    }, [soundAlert, setSoundAlert]);
    const loadSound = async () => {
        const sound = new Audio(
            require("../assets/sounds/cokhachhangmoi.wav").default
        );
        sound.load();
        setSoundAlert(sound);
    };

    const playSound = async () => {
        if (soundAlert) {
            const audioPromise = soundAlert.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    const closeCancelCallback = useCallback(
        () => {
            dispatch({
                type: 'SELECT_USER_SUCCESS',
                payload: null
            });
            dispatch({
                type: 'SELECT_BOOKING_DETAIL_SUCCESS',
                payload: null
            });
            setShowCallNow(null);
            setShowCancel(false);
            setModalVisible(false);
        },
        [],
    );

    const openCancelCallback = useCallback(
        (callDataObj) => {
            dispatch({
                type: 'SELECT_USER_SUCCESS',
                payload: null
            });
            dispatch({
                type: 'SELECT_BOOKING_DETAIL_SUCCESS',
                payload: null
            });
            setShowCallNow(callDataObj);
            setModalVisible(true);
            setShowCancel(true);
        },
        [],
    );

    const listLyDo = [
        'Số bị trượt máy',
        'Lái xe gọi',
        'Khách hỏi thông tin',
        'Không có phương tiện hỗ trợ',
        'Vấn đề khác'
    ];

    const lineOptions = [
        {value: "101", label: "Line 101"},
        {value: "102", label: "Line 102"},
        {value: "103", label: "Line 103"},
        {value: "104", label: "Line 104"},
    ];

    const handleClickOpenDialogBooking = useCallback((callData) => {
        dispatch(updateTripCallCenter(callData));
        setOpenDialogBooking(true);
    }, []);

    const handleCloseDialogBooking = useCallback(() => {
        //dispatch(clearTripPoints());
        setOpenDialogBooking(false);
    }, []);


    return (
        <SafeAreaView>
            <View style={{zIndex: 99, position: 'fixed', top: 10, right: 10, backgroundColor: 'white'}}>
                <IconButton size="small"
                            onClick={() => {
                                console.log('aaaa');
                            }
                            }
                            style={{
                                position: `absolute`,
                                right: `320px`,
                                top: '10px',
                                paddingLeft: 20,
                                paddingRight: 20,
                                zIndex: 999,
                                background: "rgba(96,89,167,0.72)",
                                borderRadius: 5,
                                shadowOffset: {
                                    height: 1,
                                    width: 0,
                                },
                                shadowColor: colors.BLACK,
                                shadowOpacity: 0.35,
                                shadowRadius: 5,
                            }}
                >
                    <img
                        height={"50"}
                        width={"28"}
                        src={require("../assets/img/password_icon.png").default}
                        alt="Password"
                    />
                </IconButton>

                <IconButton size="small"
                            onClick={() => {
                                console.log('aaaa');
                            }
                            }
                            style={{
                                position: `absolute`,
                                right: `210px`,
                                top: '10px',
                                paddingLeft: 10,
                                paddingRight: 10,
                                zIndex: 999,
                                background: "#6059a7",
                                borderRadius: 5,
                                shadowOffset: {
                                    height: 1,
                                    width: 0,
                                },
                                shadowColor: colors.BLACK,
                                shadowOpacity: 0.35,
                                shadowRadius: 5,
                            }}
                >
                    <ShareOutlinedIcon style={{fontSize: 50, color: '#ffffff'}}/>
                </IconButton>

                <IconButton size="small"
                            onClick={() => {
                                console.log('aaaa');
                            }
                            }
                            style={{
                                position: `absolute`,
                                right: `90px`,
                                top: '10px',
                                paddingLeft: 10,
                                paddingRight: 10,
                                zIndex: 999,
                                background: "rgba(96,89,167,0.66)",
                                borderRadius: 5,
                                shadowOffset: {
                                    height: 1,
                                    width: 0,
                                },
                                shadowColor: colors.BLACK,
                                shadowOpacity: 0.35,
                                shadowRadius: 5,
                            }}
                >
                    <OtherHousesIcon style={{fontSize: 50, color: '#ffffff'}}/>
                </IconButton>
            </View>

            {/*<View style={{
                zIndex: 99, position: 'fixed', top: 90, right: 100, borderRadius: 5,
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon color={'info'}/>
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Bạn muốn đón xe ở đâu…"
                        inputProps={{'aria-label': 'search'}}
                        style={inputStyle}
                        value={searchAddress}
                        onChange={(event) => {
                            setSearchAddress(event.target.value);
                        }
                        }
                        onEndEditing={(e) => {
                            let value = e.target.value;
                            console.log(value);
                            if (value.length >= 3) {
                                getAddressDefaultAutoCompleted(value).then((res) => {
                                    if (res) {
                                        setSearchResults(res);
                                        console.log(res);
                                    }
                                });
                            }
                        }}
                        onBlur={(e) => {
                            let value = e.target.value;
                            console.log(value);
                            if (value.length >= 3) {
                                getAddressDefaultAutoCompleted(value).then((res) => {
                                    if (res) {
                                        setSearchResults(res);
                                        console.log(res);
                                    }
                                });
                            }
                        }}

                    />

                </Search>
                <IconButton
                    aria-label="Thêm"
                    style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                        marginLeft: 5,
                        width: 50,
                        zIndex: 999,
                        background: "rgba(255,255,255,0.86)",
                        borderRadius: 5,
                        shadowOffset: {
                            height: 1,
                            width: 0,
                        },
                        shadowColor: colors.BLACK,
                        shadowOpacity: 0.35,
                        shadowRadius: 5,
                    }}
                    color="primary"
                    size="medium"
                    onClick={() => {

                    }}
                >
                    <SegmentTwoToneIcon fontSize="inherit"/>
                </IconButton>
            </View>*/}

            <View style={{
                zIndex: 99, position: 'fixed', top: 90, right: 100, borderRadius: 5,
                display: 'flex',
                flexDirection: 'row',
            }}>
                <SearchIcon color={'info'}/>
                <GoogleMapsAutoComplete
                    apiOptions={{language: 'vi', region: 'vn'}}
                    variant={"outlined"}
                    style={{
                        position: 'relative',
                        borderWidth: 0, borderColor: '#ffffff',
                        borderRadius: 5,
                        backgroundColor: "rgba(255,255,255,0.71)",
                        '&:hover': {
                            backgroundColor: "rgba(255,255,255,0.97)",
                        },
                        width: 250,
                        padding: 0,
                    }}
                    placeholder={language.pickup_location}
                    value={searchAddress}
                    listGroup={listGroup}
                    handleChangeGroup={handleChangeGroup}
                    group={group}
                    onChange={
                        (value) => {
                            if (value && value.label) {
                                setGroup(value.label)
                            } else {
                                if (value && value.coords) {
                                    groupDriverData.filter(g => g.lock_status === false).map(groupC => {
                                        if (groupC.coord && groupC.areas) {
                                            let coords = groupC.group_coords;
                                            let toado_tam = groupC.coord.split(",");
                                            if (toado_tam.length > 1) {
                                                let kc_tam = GetDistance(value.coords.lat, value.coords.lng, toado_tam[0], toado_tam[1]);
                                                if (kc_tam < 3) {
                                                    if (coords) {
                                                        coords.map(coord => {
                                                            if (coord.coord && coord.coord.split(",").length > 0) {
                                                                let toado_con = coord.coord.split(",");
                                                                let kc_con = GetDistance(value.coords.lat, value.coords.lng, toado_con[0], toado_con[1]);
                                                                if (kc_con <= parseFloat(coord.radius) / 1000) {
                                                                    setGroupApply(groupC)
                                                                    setGroup(groupC.label)
                                                                }
                                                            }
                                                        });
                                                    }
                                                }
                                            }
                                        } else {
                                            if (groupC.areas) {
                                                let resultPickup = geolib.isPointInPolygon({
                                                    latitude: value.coords.lat,
                                                    longitude: value.coords.lng
                                                }, group.areas[0]);
                                                if (resultPickup) {
                                                    setGroupApply(groupC)
                                                    setGroup(groupC.label)
                                                }
                                            }
                                        }
                                    })
                                } else {
                                    setGroup("");
                                    setGroupApply(null);
                                    dispatch(updateTripGroup(null));
                                }
                            }
                            setSearchAddress(value);
                        }
                    }
                />
                <IconButton
                    aria-label="Thêm"
                    style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                        marginLeft: 5,
                        width: 50,
                        zIndex: 999,
                        background: "rgba(255,255,255,0.86)",
                        borderRadius: 5,
                        shadowOffset: {
                            height: 1,
                            width: 0,
                        },
                        shadowColor: colors.BLACK,
                        shadowOpacity: 0.35,
                        shadowRadius: 5,
                    }}
                    color="primary"
                    size="medium"
                    onClick={handleClickMenuGroup}
                >
                    <SegmentTwoToneIcon fontSize="inherit"/>
                </IconButton>
            </View>

            <View style={{
                zIndex: 99, position: 'fixed', top: 140, right: 100, borderRadius: 5,
                height: 700,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'scroll'
            }}>
                {datas && datas.length > 0 ?
                    datas.map((obj) => {
                        if (obj.type == CALL_CENTER) {
                            return (
                                <CallCenterRowComponent groupApply={groupApply} group={group} callCenterObj={obj} showCall={showCall} closeShowCall={closeShowCall} handleClickOpenDialogBooking={handleClickOpenDialogBooking} openCancelCallback={openCancelCallback}/>
                            )
                        }

                        if (obj.type == NOTIFY_IMPORTANCE) {
                            return (
                                <NotifyImportanceComponent callCenterObj={obj}/>
                            )
                        }

                        if (obj.type == CHANGE_CALENDAR) {

                            console.log("obj.type", obj.type);

                            return (
                                <ChangeCarComponent callCenterObj={obj}/>
                            )
                        }

                        if (obj.type == CSHK) {
                            return (
                                <CskhComponent callCenterObj={obj}/>
                            )
                        }

                        if (obj.type == BOOKING_CANCEL) {
                            console.log("obj.type 2", obj.type);
                            return (
                                <BookingCancelComponent groupApply={groupApply} group={group} callCenterObj={obj} showCall={showCall} closeShowCall={closeShowCall} handleClickOpenDialogBooking={handleClickOpenDialogBooking} openCancelCallback={openCancelCallback}/>
                            )
                        }
                    })
                    : null}

            </View>
            {mylocation && callCenterDetail ?
                <Map
                    center={mylocation}
                    isPickup={true}
                    googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + Google_Map_Key + "&v=3.exp&libraries=geometry,drawing,places"}
                    loadingElement={<div className={classes.fullHeight}/>}
                    containerElement={<div className={classes.fullHeight}/>}
                    mapElement={<div className={classes.fullHeight}/>}
                    callDetail={callCenterDetail}
                    polygons={polygons}
                    group={group}
                    searchAddress={searchAddress}
                /> : null
            }

            {mylocation && bookingObj ?
                <Map
                    center={mylocation}
                    isPickup={true}
                    googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + Google_Map_Key + "&v=3.exp&libraries=geometry,drawing,places"}
                    loadingElement={<div className={classes.fullHeight}/>}
                    containerElement={<div className={classes.fullHeight}/>}
                    mapElement={<div className={classes.fullHeight}/>}
                    bookingObject={bookingObj}
                    searchAddress={searchAddress}
                    polygons={polygons}
                    group={group}
                /> : null
            }

            {mylocation && !callCenterDetail ?
                <Map
                    isPickup={true}
                    center={mylocation}
                    googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + Google_Map_Key + "&v=3.exp&libraries=geometry,drawing,places"}
                    loadingElement={<div className={classes.fullHeight}/>}
                    containerElement={<div className={classes.fullHeight}/>}
                    mapElement={<div className={classes.fullHeight}/>}
                    searchAddress={searchAddress}
                    polygons={polygons}
                    group={group}
                    groupApply={groupApply}
                    openDialogBooking={openDialogBooking}
                    handleCloseDialogBooking={handleCloseDialogBooking}
                /> : null
            }
            {!mylocation ?
                <Typography variant="h6" style={{
                    margin: "0",
                    color: '#FF0000'
                }}>{language.allow_location}</Typography> : null
            }
            {showCallNow ?
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                    onPress={() => {
                        setModalVisible(false);
                        setShowCallNow(null);
                        setShowCallModal(false);
                        setShowCancel(false);
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 22,
                        }}
                    >
                        <View
                            style={{
                                margin: 20,
                                backgroundColor: "white",
                                borderRadius: 10,
                                borderWidth: 1,
                                padding: 10,
                                alignItems: "center",
                                shadowColor: "#000",
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                                shadowOpacity: 0.25,
                                shadowRadius: 4,
                                elevation: 5,
                            }}
                        >
                            {showCallModal === true ?
                                <CallNowCenter
                                    lineOptions={lineOptions}
                                    time={showCallNow.time}
                                    source={showCallNow.source}
                                    time_call={showCallNow.time_call ? showCallNow.time_call : []}
                                    phone={showCallNow.phone}
                                    uniqueid={showCallNow.uniqueid}
                                    listAudio={showCallNow.audios ? showCallNow.audios : []}
                                    firebase_id={showCallNow.firebase_id}
                                    closeCallback={closeShowCall}
                                /> : null}

                            {showCancel === true ?
                                <CancelCallCenter
                                    destination={showCallNow.destination}
                                    time={ConvertTimeStampToDate(showCallNow.time * 1000)}
                                    phone={showCallNow.source}
                                    uniqueid={showCallNow.uniqueid}
                                    booking_id={showCallNow}
                                    listLyDo={listLyDo}
                                    firebase_id={showCallNow.firebase_id}
                                    closeCallback={closeCancelCallback}
                                    fromTT={true}
                                /> : null
                            }

                        </View>

                    </View>
                </Modal> : null}
        </SafeAreaView>
    );
}
export default Dashboard;
