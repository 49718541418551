import React, {useState, useEffect, useContext} from 'react';
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from '@material-ui/core';
import GoogleMapsAutoComplete from '../components/GoogleMapsAutoComplete';
import {useSelector, useDispatch} from "react-redux";
import AlertDialog from '../components/AlertDialog';
import {language} from 'config';
import {makeStyles} from '@material-ui/core/styles';
import {FirebaseContext} from 'common';
import {formatNumber} from 'react-native-currency-input';
import {Text} from "react-native-web";
import {formatPriceRound} from "common/src/other/CommonFunctions";

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        alignContent: 'center'
    },
    title: {
        color: "#000",
    },
    gridcontainer: {
        alignContent: 'center'
    },
    items: {
        margin: 0,
        width: '100%'
    },
    input: {
        fontSize: 18,
        color: "#000"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
    buttonStyle: {
        margin: 0,
        width: '100%',
        height: '100%'
    }
}));

export default function AddBookings3(props) {
    const {api} = useContext(FirebaseContext);
    const {
        getEstimate,
        clearEstimate,
        addReport,
        updateBooking,
        clearBooking
    } = api;

    const classes = useStyles();
    const cartypes = useSelector(state => state.cartypes.cars);
    const estimatedata = useSelector(state => state.estimatedata);
    const bookingdata = useSelector(state => state.bookingdata);
    const userdata = useSelector(state => state.usersdata);
    const settings = useSelector(state => state.settingsdata.settings);
    const [carType, setCarType] = useState(language.select_car);
    const [pickupAddress, setPickupAddress] = useState(null);
    const [dropAddress, setDropAddress] = useState(null);
    const [estimateModalStatus, setEstimateModalStatus] = useState(false);
    const [selectedCarDetails, setSelectedCarDetails] = useState(null);
    const [users, setUsers] = useState(null);
    const [drivers, setDrivers] = useState(null);
    const [commonAlert, setCommonAlert] = useState({open: false, msg: ''});
    const [userCombo, setUserCombo] = useState(null);
    const [driverCombo, setDriverCombo] = useState(null);
    const [estimateRequested, setEstimateRequested] = useState(false);
    const [bookingType, setBookingType] = useState('Book Now');
    const dispatch = useDispatch();
    const getDateString = (date) => {
        let d = null;
        d = date ? new Date(date) : new Date();

        let month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hours = d.getHours(),
            mins = d.getMinutes();
        if (month >= 1 & month <= 9)
            month = '0' + month.toString();
        if (day >= 0 & day <= 9)
            day = '0' + day.toString();
        if (hours >= 0 & hours <= 9)
            hours = '0' + hours.toString();
        if (mins >= 0 & mins <= 9)
            mins = '0' + mins.toString();

        return [year, month, day].join('-') + 'T' + [hours, mins].join(':');
    }
    const [selectedDate, setSelectedDate] = useState(getDateString());

    const formatPrice = (value) => {
        return formatPriceRound(value, formatNumber) + "(đ)";
    }

    useEffect(() => {
        if (props.bookingDriver) {
            let driverC = {
                'firstName': props.bookingDriver.firstName,
                'lastName': props.bookingDriver.lastName,
                'mobile': props.bookingDriver.mobile,
                'email': props.bookingDriver.email ? props.bookingDriver.email : ' ',
                'uid': props.bookingDriver.key,
                'desc': props.bookingDriver.firstName + ' ' + props.bookingDriver.lastName + ' (' + props.bookingDriver.mobile + ') ' + props.bookingDriver.email ? props.bookingDriver.email : ' ',
                'pushToken': props.bookingDriver.pushToken,
                'codeDriver': props.bookingDriver.codeDriver
            }
            setDriverCombo(props.bookingDriver)
        }

        if (props.address) {
            setPickupAddress(props.address);
        }
    }, [props.bookingDriver, props.address]);

    useEffect(() => {
        if (estimatedata.estimate && estimateRequested) {
            setEstimateRequested(false);
            setEstimateModalStatus(true);
        }
        if (userdata.users) {
            let arr = [];
            let arr_driver = [];
            for (let i = 0; i < userdata.users.length; i++) {
                let user = userdata.users[i];
                if (user.usertype === 'rider' && user.approved && user.center) {
                    arr.push({
                        'firstName': user.firstName,
                        'lastName': user.lastName,
                        'mobile': user.mobile,
                        'email': user.email ? user.email : ' ',
                        'uid': user.id,
                        'desc': user.firstName + ' ' + user.lastName + ' (' + user.mobile + ') ' + user.email ? user.email : ' ',
                        'pushToken': user.pushToken,
                        'codeDriver': user.codeDriver
                    });
                } else if (user.usertype === 'driver' && user.approved && user.driverActiveStatus) {
                    arr_driver.push({
                        'firstName': user.firstName,
                        'lastName': user.lastName,
                        'mobile': user.mobile,
                        'email': user.email ? user.email : ' ',
                        'uid': user.id,
                        'desc': user.firstName + ' ' + user.lastName + ' (' + user.mobile + ') ' + user.email ? user.email : ' ',
                        'pushToken': user.pushToken,
                        'codeDriver': user.codeDriver
                    });
                }
            }
            setUsers(arr);
            setDrivers(arr_driver);
        }
    }, [estimatedata.estimate, userdata.users, estimateRequested]);


    const handleGetEstimate = (e) => {
        e.preventDefault();
        setEstimateRequested(true);
        let selectedCarDetail = setSelectedCarDetails;
        for (let i = 0; i < cartypes.length; i++) {
            if (cartypes[i].name === props.bookingDriver.carType) {
                selectedCarDetail = cartypes[i];
            }
        }
        if (dropAddress && selectedCarDetail) {
            if (bookingType === 'Book Now') {
                dispatch(getEstimate({
                    pickup: props.bookingApply.pickup,
                    drop: dropAddress,
                    carDetails: selectedCarDetail
                }));
            }
        } else {
            setCommonAlert({open: true, msg: language.select_proper})
        }
    };

    const confirmBooking = (e) => {
        e.preventDefault();
        setEstimateModalStatus(false);
        props.bookingApply.drop = {'add': dropAddress.description, 'lat': dropAddress.coords.lat, 'lng': dropAddress.coords.lng};
        props.bookingApply.estimate = estimatedata.estimate.estimateFare;
        props.bookingApply.estimateDistance = estimatedata.estimate.estimateDistance;
        props.bookingApply.estimateTime = estimatedata.estimate.estimateTime;
        props.bookingApply.notify_to_center = 'Đã cập nhật điểm đến cho tài số mã số# ' + props.bookingDriver.codeDriver + 'mã chuyến đi #' + props.bookingApply.id;
        props.bookingApply.status = 'ACCEPTED';
        dispatch(updateBooking(props.bookingApply));
    };

    const finishBooking = (e) => {
        e.preventDefault();
        setEstimateModalStatus(false);
        props.bookingApply.status = 'COMPLETE';
        dispatch(updateBooking(props.bookingApply));
    };

    const handleEstimateModalClose = (e) => {
        e.preventDefault();
        setEstimateModalStatus(false);
        dispatch(clearEstimate());
        setEstimateRequested(false);
    };

    const handleEstimateErrorClose = (e) => {
        e.preventDefault();
        dispatch(clearEstimate());
        setEstimateRequested(false);
    };

    const handleBookingAlertClose = (e) => {
        e.preventDefault();
        dispatch(clearBooking());
        dispatch(clearEstimate());
        clearForm();
    };

    const clearForm = () => {
        setUserCombo(null);
        setDriverCombo(null);
        setPickupAddress(null);
        setDropAddress(null);
        setSelectedCarDetails(null);
        setCarType(language.select_car);
        setBookingType(language.book_now);
        setEstimateRequested(false);
    }

    const handleBookingErrorClose = (e) => {
        e.preventDefault();
        dispatch(clearBooking());
        setEstimateRequested(false);
    };

    const handleCommonAlertClose = (e) => {
        e.preventDefault();
        setCommonAlert({open: false, msg: ''})
    };

    return (
        <div className={classes.container}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Text>Điểm đón: {props.bookingApply.pickupAddress}</Text>
                        <Typography component="h1" variant="h5" className={classes.title}>
                            Cập nhật điểm đi
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <GoogleMapsAutoComplete
                            placeholder={language.drop_location}
                            variant={"outlined"}
                            value={dropAddress}
                            className={classes.items}
                            onChange={
                                (value) => {
                                    setDropAddress(value);
                                }
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Button
                            size="large"
                            onClick={handleGetEstimate}
                            variant="contained"
                            color="primary"
                            className={classes.buttonStyle}
                        >
                            <i className="fas fa-car"/>
                            Cập nhật
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            size="large"
                            onClick={finishBooking}
                            variant="contained"
                            color="primary"
                            className={classes.buttonStyle}
                        >
                            <i className="fas fa-car"/>
                            Hoàn thành
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                open={estimateModalStatus}
                onClose={handleEstimateModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{language.estimate}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {estimatedata.estimate ? formatPrice(estimatedata.estimate.estimateFare) : null}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEstimateModalClose} color="primary">
                        {language.cancel}
                    </Button>
                    <Button onClick={confirmBooking} color="primary" autoFocus>
                        {language.book_now}
                    </Button>
                </DialogActions>
            </Dialog>
            <AlertDialog open={bookingdata.booking ? true : false}
                         onClose={handleBookingAlertClose}>{bookingdata.booking ? language.booking_success + bookingdata.booking.booking_id : null}</AlertDialog>
            <AlertDialog open={bookingdata.error.flag}
                         onClose={handleBookingErrorClose}>{bookingdata.error.msg}</AlertDialog>
            <AlertDialog open={estimatedata.error.flag}
                         onClose={handleEstimateErrorClose}>{estimatedata.error.msg}</AlertDialog>
            <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
        </div>
    );
}
