import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useDispatch, useSelector} from "react-redux";
import {language} from 'config';
import {FirebaseContext} from 'common';

export default function ReportTotalCKDrivers() {
    const {api, pricesRef} = useContext(FirebaseContext);
    const {
        editUser,
        updatePer,
        fetchOnceUsers
    } = api;
    const usersdata = useSelector(state => state.usersdata.once_users);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(fetchOnceUsers());
    }, []);

    useEffect(() => {
        if (usersdata) {
            setData(usersdata.filter((u) => u.vehicleNumber && u.vehicleNumber != '' && u.vehicleNumber != null && u.vehicleNumber != ' ' && u.codeDriver && u.codeDriver != ' '));
        }
    }, [usersdata]);

    const columns = [
        {title: language.code_driver, field: 'codeDriver', initialEditValue: ''},
        {title: language.vehicle_reg_no, field: 'vehicleNumber', initialEditValue: ''},
        {title: language.total_booking_day, field: 'totalBook', initialEditValue: '1'}
    ];

    return (
        <MaterialTable
            title={language.drivers}
            columns={columns}
            data={data}
            options={{
                exportButton: false,
                sorting: true,
                pageSize: 50,
                pageSizeOptions: [10, 25, 50, 100]
            }}
            actions={[
                {
                    icon: "refresh",
                    tooltip: "Reset Cuốc khách",
                    position: "toolbar",
                    onClick: () => {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i] && data[i].id) {
                                let prices = {
                                    suv: {
                                        add: "60000",
                                        open: "15000",
                                        price1: [
                                            {
                                                from: 0.1,
                                                id: "0",
                                                price: 20000,
                                                to: 20
                                            },
                                            {
                                                from: 20,
                                                id: "1",
                                                price: 15000,
                                                to: 50
                                            },
                                            {
                                                from: 50,
                                                id: "2",
                                                price: 14000,
                                                to: 100
                                            },
                                            {
                                                from: 100,
                                                id: "3",
                                                price: 13000
                                            }
                                        ],
                                        price2: [
                                            {
                                                id: "0",
                                                km: 20,
                                                messageKm: "",
                                                messageTime: "",
                                                time: 50,
                                                unitPrice: 20000
                                            },
                                            {
                                                id: "1",
                                                km: 100,
                                                messageKm: "",
                                                messageTime: "",
                                                time: 250,
                                                unitPrice: 8500
                                            },
                                            {
                                                id: "2",
                                                km: 200,
                                                messageKm: "",
                                                messageTime: "",
                                                time: 500,
                                                unitPrice: 7500
                                            },
                                            {
                                                id: "3",
                                                km: 300,
                                                messageKm: "",
                                                messageTime: "",
                                                time: 750,
                                                unitPrice: 7000
                                            }
                                        ]
                                    },
                                    taxi: {
                                        add: 60000,
                                        open: 10000,
                                        price1: [
                                            {
                                                from: 0.1,
                                                id: "0",
                                                price: 13000,
                                                to: 10
                                            },
                                            {
                                                from: 10,
                                                id: "1",
                                                price: 11000,
                                                to: 30
                                            },
                                            {
                                                from: 30,
                                                id: "2",
                                                price: 12000
                                            }
                                        ],
                                        price2: [
                                            {
                                                id: "0",
                                                km: 20,
                                                messageKm: "",
                                                messageTime: "",
                                                time: 50,
                                                unitPrice: 10500
                                            },
                                            {
                                                id: "1",
                                                km: 30,
                                                messageKm: "",
                                                messageTime: "",
                                                time: 75,
                                                unitPrice: 9000
                                            },
                                            {
                                                id: "2",
                                                km: 40,
                                                messageKm: "",
                                                messageTime: "",
                                                time: 100,
                                                unitPrice: 8000
                                            },
                                            {
                                                id: "3",
                                                km: 50,
                                                messageKm: "",
                                                messageTime: "",
                                                time: 125,
                                                unitPrice: 7500
                                            },
                                            {
                                                id: "4",
                                                km: 60,
                                                messageKm: "",
                                                messageTime: "",
                                                time: 150,
                                                unitPrice: 7000
                                            },
                                            {
                                                id: "5",
                                                km: 61,
                                                messageKm: "",
                                                messageTime: "",
                                                time: 152.5,
                                                unitPrice: 7000
                                            }
                                        ]
                                    }
                                }
                                //pricesRef(data[i].id).set(prices);
                                dispatch(updatePer(data[i].id, 0));
                            }
                        }
                        dispatch(fetchOnceUsers());
                    }
                }
            ]}
            editable={{
                onRowUpdate: (newData, oldData) =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();
                            dispatch(editUser(oldData.id, newData));
                            alert("Cập nhật Tổng số cuốc thành công");
                            dispatch(fetchOnceUsers());
                        }, 600);
                    })

            }}
        />
    );
}
