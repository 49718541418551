import React, {useEffect, useContext} from 'react';
import CircularLoading from "../components/CircularLoading";
import {useSelector, useDispatch} from "react-redux";
import {language} from 'config';
import {FirebaseContext} from 'common';
import {GetCurrentDateCaTrucDem, GetCurrentTimeStamp} from "common/src/other/DateFunctions";

function AuthLoading(props) {
    const {api, userLoginHistoryRef} = useContext(FirebaseContext);
    const {
        fetchUser,
        fetchCarTypes,
        fetchCaTrucDem,
        fetchGroupDrivers,
        fetchReportDrivers,
        fetchSettings,
        fetchBookings,
        fetchCancelReasons,
        fetchPromos,
        fetchDriverEarnings,
        fetchUsers,
        fetchAllDrivers,
        fetchAllDriversQueue,
        fetchOnceUsers,
        fetchNotifications,
        fetchMistakes,
        fetchEarningsReport,
        signOut,
        fetchWithdraws,
        fetchAllBookings,
        fetchCallsPending,
        fetchMistakeConfig,
        fetchGiaoXe,
        fetchForceNotify,
        fetchGroupArea,
        fetchWalletManage,
        fetchCallDetailAll,
        fetchFleetUsersOnline,
        fetchEtoWallet,
        fetchAreas,
        setUserLoginHistoryApi,
        fetchDriverCaTrucDem
    } = api;
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const fleets = useSelector(state => state.usersdata.fleets);
    useEffect(() => {
        dispatch(fetchUser());
        dispatch(fetchCarTypes());
        //dispatch(fetchCaTrucDem());
        //dispatch(fetchGiaoXe());
        dispatch(fetchSettings());
        dispatch(fetchGroupDrivers());
        //dispatch(fetchMistakeConfig());
        dispatch(fetchDriverCaTrucDem(GetCurrentDateCaTrucDem()));
        dispatch(fetchFleetUsersOnline());
    }, [dispatch, fetchCarTypes, fetchCaTrucDem, fetchGiaoXe, fetchSettings, fetchGroupDrivers, fetchMistakeConfig, fetchFleetUsersOnline]);

    useEffect(() => {
        if (auth.info) {
            if (auth.info.profile) {
                let role = auth.info.profile.usertype;
                if (role === 'rider') {
                    dispatch(fetchBookings(auth.info.uid, role));
                    dispatch(fetchCancelReasons());
                } else if (role === 'driver') {
                    dispatch(fetchBookings(auth.info.uid, role));
                    dispatch(fetchGroupDrivers());
                    dispatch(fetchNotifications());
                    dispatch(fetchCancelReasons());
                } else if (role === 'admin') {
                    //dispatch(fetchForceNotify());
                    //dispatch(fetchUsers());
                    //dispatch(fetchOnceUsers());
                    //dispatch(fetchBookings(auth.info.uid, role));
                    //dispatch(fetchAllBookings());
                    //dispatch(fetchPromos());
                    //dispatch(fetchDriverEarnings(auth.info.uid, role));
                    //dispatch(fetchEtoWallet());
                    //dispatch(fetchNotifications());
                    //dispatch(fetchEarningsReport());
                    //dispatch(fetchCancelReasons());
                    dispatch(fetchGroupDrivers());
                    dispatch(fetchCancelReasons());
                    //dispatch(fetchGroupArea());
                    //dispatch(fetchReportDrivers());
                    //dispatch(fetchWithdraws());
                    //dispatch(fetchAllDrivers());
                    //dispatch(fetchCallsPending());
                    //dispatch(fetchAllDriversQueue());
                    //dispatch(fetchMistakes());
                    //dispatch(fetchCallDetailAll());
                    //dispatch(fetchAreas());
                } else if (role === 'fleetadmin') {
                    if (auth && auth.info && auth.info.profile && !auth.info.profile.isOnline) {
                        if (setUserLoginHistoryApi) {
                            /*navigator.geolocation.getCurrentPosition(
                                position => {
                                    setUserLoginHistoryApi(auth.info.uid, GetCurrentTimeStamp(), {
                                        type: 'login', at: GetCurrentTimeStamp(), lat: position.coords.latitude,
                                        lng: position.coords.longitude
                                    });
                                },
                                err => console.log(err)
                            );*/
                            api.setUserLoginHistoryApi(auth.info.uid, GetCurrentTimeStamp(), {
                                type: 'login', at: GetCurrentTimeStamp(), lat: "temp",
                                lng: "temp"
                            });
                        }
                        api.updateProfileRef(auth.info.uid).update({isOnline: true});
                    }
                    dispatch(fetchGroupDrivers());
                    dispatch(fetchCancelReasons());
                    /*dispatch(fetchCancelReasons());
                    dispatch(fetchForceNotify());
                    //dispatch(fetchUsers());
                    dispatch(fetchOnceUsers());
                    dispatch(fetchAllBookings());
                    /!*dispatch(fetchBookings(auth.info.uid, role));
                    dispatch(fetchDriverEarnings(auth.info.uid, role));*!/
                    dispatch(fetchGroupDrivers());
                    dispatch(fetchReportDrivers());
                    dispatch(fetchAllDrivers());
                    //dispatch(fetchAllDriversQueue());
                    dispatch(fetchCallsPending());
                    dispatch(fetchMistakes());
                    dispatch(fetchCallDetailAll());*/
                } else {
                    alert(language.not_valid_user_type);
                    dispatch(signOut());
                }
            } else {
                alert(language.user_issue_contact_admin);
                dispatch(signOut());
            }
        }
    }, [auth.info, dispatch, fetchBookings, fetchCallDetailAll, fetchForceNotify, fetchGroupArea, fetchAllBookings, fetchMistakes, fetchCallsPending, fetchCancelReasons, fetchGroupDrivers, fetchReportDrivers, fetchAllDrivers, fetchAllDriversQueue, fetchEarningsReport, fetchNotifications, fetchPromos, fetchUsers, fetchOnceUsers, fetchWithdraws, signOut, fetchEtoWallet]);

    return (
        auth.loading ? <CircularLoading/> : props.children
    )
}

export default AuthLoading;
