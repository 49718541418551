import {store} from '../store/store';
import {GetDistance} from "./GeoFunctions";
import {ConvertSecondToHms} from "./DateFunctions";

export function congthuc1chieu(distance, time, rateDetails = null, driverRateBase = null) {
    if (distance && distance > 0) {
    } else {
        distance = 0;
    }
    let settings = store.getState().settingsdata.settings;
    var driverPrices = null;
    if (driverRateBase !== null) {
        driverPrices = driverRateBase;
    }
    if (store.getState().pricesdata && driverPrices === null) {
        driverPrices = store.getState().pricesdata.prices;
    }

    let gia_mo_cua = driverPrices && driverPrices.open ? driverPrices.open : (rateDetails && rateDetails.min_fare ? rateDetails.min_fare : 0);
    var price_estimate = gia_mo_cua;
    var basePrice = 0;
    let dis_cal = settings && settings.convert_to_mile ? 1000 : 1;
    let pricePerTime = driverPrices && driverPrices.add ? driverPrices.add : (rateDetails && rateDetails.rate_per_hour ? rateDetails.rate_per_hour : 0);
    let khoangcach = parseFloat(distance);
    if (driverPrices && driverPrices.price1) {
        let price1 = Object.values(driverPrices.price1);
        let firstKm = parseFloat(price1[0].price);
        /*if (khoangcach >= 1) {
            price_estimate = parseFloat(price_estimate) + firstKm;
        }*/
        for (let i = 0; i < price1.length; i++) {
            let from = parseFloat(price1[i].from);
            let to = parseFloat(price1[i].to);
            let price = parseFloat(price1[i].price);
            if (khoangcach >= (from * dis_cal)) {
                var distanceCal = khoangcach - (from * dis_cal);
                if (distanceCal > (to * dis_cal)) {
                    price_estimate = parseFloat(price_estimate) + parseFloat(price * (to * dis_cal) / (1 * dis_cal));
                } else {
                    if (distanceCal > (to * dis_cal - from * dis_cal)) {
                        price_estimate = parseFloat(price_estimate) + parseFloat(price * (to * dis_cal - from * dis_cal) / (1 * dis_cal));
                    } else {
                        price_estimate = parseFloat(price_estimate) + parseFloat(price * distanceCal / (1 * dis_cal));
                    }
                }
                basePrice = price;
            }
        }
        if (basePrice === 0) {
            basePrice = firstKm;
        }
        /*if (price1 && price1.length > 0 && khoangcach > (price1[price1.length - 1].to * dis_cal)) {
            var distance = khoangcach - (price1[price1.length - 1].to * dis_cal);
            price_estimate = parseFloat(price_estimate) + parseFloat(price1[price1.length - 1].price * distance / (1 * dis_cal));
        }*/
    } else {
        let gia_1km_10 = rateDetails ? parseFloat(rateDetails.rate_per_kilometer) : 0;
        let gia_1km_10_15 = rateDetails ? parseFloat(rateDetails.rate_per_kilometer_10_15) : 0;
        let gia_1km_15_30 = rateDetails ? parseFloat(rateDetails.rate_per_kilometer_15_30) : 0;
        let gia_1km_30_ = rateDetails ? parseFloat(rateDetails.rate_per_kilometer_30) : 0;

        if (khoangcach >= (1 * dis_cal)) {
            var distance_1 = khoangcach - (1 * dis_cal);
            if (distance_1 <= (9 * dis_cal)) {
                price_estimate = parseFloat(price_estimate) + parseFloat(gia_1km_10 * distance_1 / (1 * dis_cal));
            } else {
                price_estimate = parseFloat(price_estimate) + parseFloat(gia_1km_10 * (9 * dis_cal) / (1 * dis_cal));
            }
        }

        if (khoangcach > (10 * dis_cal)) {
            var distance_2 = khoangcach - (10 * dis_cal);
            if (distance_2 <= (5 * dis_cal)) {
                price_estimate = parseFloat(price_estimate) + parseFloat(gia_1km_10_15 * distance_2 / (1 * dis_cal));
            } else {
                price_estimate = parseFloat(price_estimate) + parseFloat(gia_1km_10_15 * (5 * dis_cal) / (1 * dis_cal));
            }
            basePrice = gia_1km_10_15;
        }
        if (khoangcach > (15 * dis_cal)) {
            var distance_3 = khoangcach - (15 * dis_cal);
            if (distance_3 <= (15 * dis_cal)) {
                price_estimate = parseFloat(price_estimate) + parseFloat(gia_1km_15_30 * distance_3 / (1 * dis_cal));
            } else {
                price_estimate = parseFloat(price_estimate) + parseFloat(gia_1km_15_30 * (15 * dis_cal) / (1 * dis_cal));
            }
            basePrice = gia_1km_15_30;
        }
        if (khoangcach > (30 * dis_cal)) {
            var distance_4 = khoangcach - (30 * dis_cal);
            price_estimate = parseFloat(price_estimate) + parseFloat(gia_1km_30_ * distance_4 / (1 * dis_cal));
            basePrice = gia_1km_30_;
        }
    }

    // let baseCalculated = ((parseFloat(rateDetails.rate_per_kilometer) * (parseFloat(distance) / 1000))) + ((parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600)));
    var priceTimeAdditional = 0;
    if (time) {
        let timeArray = ConvertSecondToHms(time).split(":");
        if (timeArray && timeArray.length === 3) {
            let totalMin = parseFloat(timeArray[0]) * 60 + parseFloat(timeArray[1]);
            priceTimeAdditional = totalMin * (parseFloat(pricePerTime) / 60);
        }
    }

    let baseCalculated = parseFloat(price_estimate) + parseFloat(priceTimeAdditional);
    let total = baseCalculated;
    let convenienceFee = 0;
    /*if (rateDetails !== null && rateDetails.convenience_fee_type && rateDetails.convenience_fee_type == 'flat') {
        convenienceFee = rateDetails.convenience_fees;
    } else {
        if (rateDetails !== null && rateDetails.convenience_fees) {
            convenienceFee = (total * parseFloat(rateDetails.convenience_fees ? rateDetails.convenience_fees : 0) / 100);
        }
    }*/

    let grand = total;

    return {
        totalCost: total ? parseFloat(total).toFixed(0) : 0,
        grandTotal: grand ? parseFloat(grand).toFixed(0) : 0,
        convenience_fees: convenienceFee ? parseFloat(convenienceFee).toFixed(0) : 0,
        distance: distance,
        basePrice: basePrice
    }
}


/**
 *
 * @param distance : km
 * @param time: second
 * @param rateDetails
 * @param kmquaydau
 * @param driverRateBase
 * @param timeWaiting
 * @returns {{kmQuayDau: number, convenience_fees: (number|number), distance, addTime: (*|number), grandTotal: number, thuebao: (boolean|string|*), price: (*|number), emptyPrice: (*|number), time_phutroi: number, time, totalCost: number, basePrice: number}|null|{convenience_fees: (number|number), distance, grandTotal: number, totalCost: number, basePrice: number}}
 */
export function congthuc2chieu(distance, time, rateDetails = null, kmquaydau = 0, driverRateBase = null, timeWaiting = 0) {
    if (distance && distance > 0) {
    } else {
        distance = 0;
    }
    var driverPrices = null;
    if (driverRateBase !== null) {
        driverPrices = driverRateBase;
    }
    if (store.getState().pricesdata && driverPrices === null) {
        driverPrices = store.getState().pricesdata.prices;
    }

    let gia_mo_cua = 0;
    var basePrice = 0;
    var price_estimate = gia_mo_cua;
    var emptyPrice = 0;
    let khoangcach = parseFloat(distance);
    let pricePerTime = driverPrices && driverPrices.add ? driverPrices.add : (rateDetails && rateDetails.rate_per_hour ? rateDetails.rate_per_hour : 0);
    let listPrice = driverPrices && driverPrices.price2 ? Object.values(driverPrices.price2) : (rateDetails && rateDetails.price2 ? rateDetails.price2 : null);
    var time_phu_troi = 0;
    let kmapplycheckprice = khoangcach;

    if (parseFloat(kmquaydau) > 0) {
        kmapplycheckprice = parseFloat(kmquaydau) + khoangcach;
    }

    var banggiaValidArray = [];

    if (listPrice) {
        banggiaValidArray = listPrice.filter(banggia =>
            ((parseFloat(banggia.km) - kmapplycheckprice) <= 0)
        );

    }

    var banggiaValid = null;
    if (banggiaValidArray.length > 0) {
        banggiaValid = banggiaValidArray[banggiaValidArray.length - 1];
    } else {
        if (listPrice && listPrice.length > 0 && khoangcach >= parseFloat(listPrice[0].km)) {
            banggiaValid = listPrice[listPrice.length - 1]
        } else {
            if (rateDetails !== null) {
                if (driverRateBase !== null) {
                    return congthuc1chieu(distance, timeWaiting, rateDetails, driverRateBase);
                } else {
                    return congthuc1chieu(distance, timeWaiting, rateDetails);
                }
            } else {
                if (driverRateBase !== null) {
                    return congthuc1chieu(distance, timeWaiting, null, driverRateBase);
                }
            }
        }
    }

    if (banggiaValid) {
        if (banggiaValid && kmapplycheckprice > banggiaValid.km) {
            // km_phu_troi = kmapplycheckprice - banggiaValid.km;
            /*if (km_phu_troi >= 5) {
                return {
                    thuebao: banggiaValid.km,
                    price: banggiaValid.price,
                    time: banggiaValid.time,
                    distance: distance,
                    time_phutroi: banggiaValid.time,
                    km_phutroi: parseFloat((banggiaValid.add ? banggiaValid.add : banggiaValid.priceperkm)),
                    totalCost: banggiaValid.price,
                    grandTotal: banggiaValid.price,
                    convenience_fees: convenienceFee ? parseFloat(convenienceFee.toFixed(0)) : 0,
                    basePrice: parseFloat((banggiaValid.add ? banggiaValid.add : banggiaValid.priceperkm)),
                    warning: true
                }
            }*/
        }
        const sec = parseInt(time, 10); // convert value to number if it's string
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
        let minuteTotal = parseInt((hours * 60 + minutes));
        if (banggiaValid && banggiaValid.unitPrice) {
            if (banggiaValid.km <= distance) {
                banggiaValid.time = distance * 2.5;
            }
            basePrice = parseFloat(banggiaValid.unitPrice);
            if (minuteTotal > banggiaValid.time) {
                time_phu_troi = minuteTotal - banggiaValid.time;
            }
            price_estimate = parseFloat(price_estimate) + (kmapplycheckprice * parseFloat(banggiaValid.unitPrice)) + (parseFloat(time_phu_troi) * (parseFloat(pricePerTime) / 60));
            if (banggiaValid.emptyPrice && parseFloat(banggiaValid.emptyPrice) > 0) {
                emptyPrice = parseFloat(banggiaValid.emptyPrice);
                let checkPriceEmpty = parseFloat(banggiaValid.unitPrice) - emptyPrice;
                if (checkPriceEmpty > 0) {
                    price_estimate = price_estimate - (parseFloat(kmquaydau) * checkPriceEmpty);
                }
            }
        }
    } else {
        if (rateDetails !== null) {
            if (driverRateBase !== null) {
                return congthuc1chieu(distance, timeWaiting, rateDetails, driverRateBase);
            } else {
                return congthuc1chieu(distance, timeWaiting, rateDetails);
            }
        } else {
            if (driverRateBase !== null) {
                return congthuc1chieu(distance, timeWaiting, null, driverRateBase);
            } else {
                return null;
            }
        }
    }

    let baseCalculated = price_estimate;
    let total = baseCalculated;
    let convenienceFee = 0;
    /*if (rateDetails !== null && rateDetails.convenience_fee_type && rateDetails.convenience_fee_type == 'flat') {
        convenienceFee = rateDetails.convenience_fees ? rateDetails.convenience_fees : 0;
    } else {
        if (rateDetails !== null && rateDetails.convenience_fees) {
            convenienceFee = (total * parseFloat(rateDetails.convenience_fees ? rateDetails.convenience_fees : 0) / 100);
        }
    }*/

    let grand = total;
    return {
        thuebao: banggiaValid.km,
        price: banggiaValid.unitPrice ? banggiaValid.unitPrice : 0,
        time: banggiaValid.time,
        emptyPrice: banggiaValid.emptyPrice ? banggiaValid.emptyPrice : 0,
        distance: distance,
        addTime: pricePerTime,
        kmQuayDau: kmquaydau,
        time_phutroi: time_phu_troi,
        totalCost: total ? parseFloat(total).toFixed(0) : 0,
        grandTotal: grand ? parseFloat(grand).toFixed(0) : 0,
        convenience_fees: convenienceFee ? parseFloat(convenienceFee).toFixed(0) : 0,
        basePrice: basePrice
    }
}

export function FareCalculator(distance, time, rateDetails, kmquaydau = 0, driverPrices) {
    let result1chieu = congthuc1chieu(distance, time, rateDetails, driverPrices);
    let result2chieu = congthuc2chieu(distance, time, rateDetails, kmquaydau, driverPrices);
    if (parseFloat(result1chieu.totalCost) > parseFloat(result2chieu.totalCost)) {
        // thue bao 2 chieu
        return {
            totalCost: result2chieu.totalCost,
            grandTotal: result2chieu.grandTotal,
            convenience_fees: result2chieu.convenience_fees,
            typePrice: "2"
        }
    } else {
        return {
            totalCost: result1chieu.totalCost,
            grandTotal: result1chieu.grandTotal,
            convenience_fees: result1chieu.convenience_fees,
            typePrice: "1"
        }
    }
}

export function FareCalculator2(distance, time, rateDetails = null, kmquaydau = 0, timeWaiting = 0) {
    let result1chieu = congthuc1chieu(distance, timeWaiting, rateDetails);
    let result2chieu = congthuc2chieu(distance, time, rateDetails, kmquaydau, null, timeWaiting);
    return [{
        totalCost: result1chieu.totalCost,
        grandTotal: result1chieu.grandTotal,
        convenience_fees: result1chieu.convenience_fees,
        typePrice: "1"
    }, {
        totalCost: result2chieu.totalCost,
        grandTotal: result2chieu.grandTotal,
        convenience_fees: result2chieu.convenience_fees,
        typePrice: "2"
    }];
}

export function fastEstimate(pickup, lastLocation, carType, total_diff, total_time, customerState = false, driver_group = null, timeWaiting = 0) {
    var kmQuayDau = 0;

    if (pickup && pickup.lat) {
        kmQuayDau = GetDistance(lastLocation.lat, lastLocation.lng, pickup.lat, pickup.lng);
    } else {
        if (driver_group) {
            kmQuayDau = GetDistance(lastLocation.lat, lastLocation.lng, driver_group.coord.split(",")[0], driver_group.coord.split(",")[1].trim());
        }
    }

    if (customerState) {
        let fareCalculation = FareCalculator2(total_diff, total_time, carType, kmQuayDau, timeWaiting);
        return fareCalculation;
    } else {
        let fareCalculation = FareCalculator2(total_diff, total_time, carType, kmQuayDau, timeWaiting);
        return fareCalculation;
    }
}
