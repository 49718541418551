import React, {useState, useEffect, useContext} from 'react';
import MaterialTable from 'material-table';
import {useSelector, useDispatch} from "react-redux";
import {
    features,
    dateStyle,
    language, timeZone
} from 'config';
import {FirebaseContext} from 'common';

export default function Users() {
    const {api, walletBalRef, walletHistoryRef} = useContext(FirebaseContext);
    const {
        addUser,
        editUser,
        deleteUser,
        checkUserExists,
        fetchOnceUsers
    } = api;
    const [cars, setCars] = useState({});
    const [groups, setGroups] = useState({});
    const usersdata = useSelector(state => state.usersdata.once_users);
    const cartypes = useSelector(state => state.cartypes);
    const groupdriverdata = useSelector(state => state.groupdriverdata);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchOnceUsers());
    }, []);

    /*useEffect(() => {
        let arrayCode = '62,04,05,36,07,39,94,99,11,93,86,95,72,58,96,23,82,55,66,89,59,91,79,45,69,88,75,20,68,18,52,87,65,31,56,95,15,26,35,58,22,38,80,85,29,06,28,02,17,41,47'.split(',');
        let arrayMoney = '6000000,6000000,6000000,6000000,6000000,6000000,6000000,6000000,2000000,6000000,6000000,2000000,6000000,2000000,6000000,6000000,6000000,6000000,6000000,6000000,3000000,6000000,6000000,6000000,6000000,6000000,4000000,6000000,6000000,3000000,5000000,5000000,1000000,5000000,4000000,5000000,5000000,5000000,3000000,5000000,3000000,2000000,3000000,4000000,2000000,2000000,2000000,1000000,2000000,2000000,2000000'.split(',');
        if (usersdata) {
            let stringCode = [];
            usersdata.map((driver) => {
                if (driver && driver.codeDriver && driver.codeDriver.length > 1 && driver.usertype == 'driver') {
                    if (driver.id) {
                        if (driver.carType == 'TAXI MY SƠN' || driver.carType == 'TAXI') {
                            api.setCartypeUserApi(driver.id, "4 Ghế");
                            api.setCartypeCodeUserApi(driver.id, "taxi");
                        } else {
                            if (driver.carType == 'SEDAN') {
                                api.setCartypeUserApi(driver.id, "7 Ghế");
                                api.setCartypeCodeUserApi(driver.id, "suv");
                            }
                        }
                        api.setCodeDriverUserApi(driver.id, driver.codeDriver.toString().trim());
                    }
                    stringCode.push(driver.codeDriver.toString().trim());
                    if (driver.walletHistory) {
                        walletHistoryRef(driver.id).set(null);
                    }
                    if (arrayCode.includes(driver.codeDriver)) {
                        let driverIndex = arrayCode.indexOf(driver.codeDriver);
                        if (driverIndex && arrayMoney[driverIndex]) {
                            walletBalRef(driver.id).set(parseFloat(arrayMoney[driverIndex]));
                        }
                        console.log("Khởi tạo " + driver.codeDriver + " số dư " + parseFloat(arrayMoney[driverIndex]));
                    } else {
                        walletBalRef(driver.id).set(0);
                        console.log("Trừ tiền " + driver.codeDriver + " số dư 0");
                    }
                }
            });
            api.setCodeDriverApi(stringCode);
        }
    }, [usersdata]);*/

    useEffect(() => {
        if (cartypes.cars) {
            let obj = {};
            cartypes.cars.map((car) => obj[car.name] = car.name)
            setCars(obj);
        }
    }, [cartypes.cars]);

    useEffect(() => {
        if (groupdriverdata.complex) {
            let obj = {};
            groupdriverdata.complex.map((group) => obj[group.value] = group.label)
            setGroups(obj);
        } else {
            setGroups([]);
        }
    }, [groupdriverdata.complex]);

    const columns = [
        {
            title: language.createdAt,
            field: 'createdAt',
            editable: 'never',
            defaultSort: 'desc',
            render: rowData => rowData.createdAt ? new Date(rowData.createdAt).toLocaleString(dateStyle, {timeZone: timeZone}) : null
        },
        {title: language.first_name, field: 'firstName', initialEditValue: ''},
        {title: language.last_name, field: 'lastName', initialEditValue: ''},
        {title: language.code_driver, field: 'codeDriver', initialEditValue: ''},
        {title: "Kiểu LX", field: 'driverType', lookup: {noi_bo: "Nội bộ", dong_dam: "Đóng đàm", cong_nghe: "Công nghệ"}},
        {title: language.mobile, field: 'mobile'}, // , editable: 'onAdd'
        {
            title: language.profile_image,
            field: 'profile_image',
            render: rowData => rowData.profile_image ?
                <img alt='Profile' src={rowData.profile_image} style={{width: 50, borderRadius: '50%'}}/> : null,
            editable: 'never'
        },
        /* {title: language.vehicle_model_name, field: 'vehicleMake', initialEditValue: ''},
         {title: language.vehicle_model_no, field: 'vehicleModel', initialEditValue: ''},*/
        {title: language.vehicle_reg_no, field: 'vehicleNumber', initialEditValue: ''},
        /*{
            title: language.cmnd,
            field: 'cmnd',
            render: rowData => rowData.cmnd ? <img alt='License' src={rowData.cmnd} style={{width: 100}}/> : null,
            editable: 'never'
        },
        {
            title: language.cmnd_s,
            field: 'cmndS',
            render: rowData => rowData.cmndS ? <img alt='License' src={rowData.cmndS} style={{width: 100}}/> : null,
            editable: 'never'
        },
        {
            title: language.lisence_image,
            field: 'licenseImage',
            render: rowData => rowData.licenseImage ?
                <img alt='License' src={rowData.licenseImage} style={{width: 100}}/> : null,
            editable: 'never'
        },
        {
            title: language.lisence_image_s,
            field: 'licenseSImage',
            render: rowData => rowData.licenseSImage ?
                <img alt='License' src={rowData.licenseSImage} style={{width: 100}}/> : null,
            editable: 'never'
        },
        {
            title: language.anh_bien_so_xe,
            field: 'anhBienSoXe',
            render: rowData => rowData.anhBienSoXe ?
                <img alt='License' src={rowData.anhBienSoXe} style={{width: 100}}/> : null,
            editable: 'never'
        },*/
        {title: language.car_type, field: 'carType', lookup: cars},
        {title: language.group_driver_title, field: 'groupDriver', lookup: groups},
        {title: "GPS ĐT?", field: 'gpsdt', type: 'boolean', initialEditValue: false},
        {title: "Contermet?", field: 'contermet', type: 'boolean', initialEditValue: false},
        {title: language.account_approve, field: 'approved', type: 'boolean', initialEditValue: true},
        {title: language.driver_active, field: 'driverActiveStatus', type: 'boolean', initialEditValue: true},
        {
            title: language.wallet_balance,
            field: 'walletBalance',
            type: 'numeric',
            editable: 'never',
            initialEditValue: 0
        },
        {title: language.signup_via_referral, field: 'signupViaReferral', editable: 'never'},
        {title: language.referralId, field: 'referralId', editable: 'never', initialEditValue: ''},
        {title: language.bankName, field: 'bankName', initialEditValue: ''},
        {title: language.bankCode, field: 'bankCode', initialEditValue: ''},
        {title: language.bankAccount, field: 'bankAccount', initialEditValue: ''},
        {title: "Leader", field: 'vehicleNumberLeader', initialEditValue: ''},
        {title: language.queue, field: 'queue', type: 'boolean', initialEditValue: false},
    ];

    return (
        <MaterialTable
            title={language.drivers}
            columns={columns}
            data={usersdata ? usersdata.filter((u) => u.usertype == 'driver') : []}
            options={{
                exportButton: true,
                sorting: true,
                pageSize: 50,
                pageSizeOptions: [10, 25, 50, 100]
            }}
            editable={{
                onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            checkUserExists(newData).then((res) => {
                                if (res.users && res.users.length > 0) {
                                    alert(language.user_exists);
                                    reject();
                                } else if (res.error) {
                                    alert(language.email_or_mobile_issue);
                                    reject();
                                } else {
                                    newData['createdByAdmin'] = true;
                                    newData['lock_driver_status'] = false;
                                    newData['usertype'] = 'driver';
                                    newData['createdAt'] = new Date().toISOString();
                                    newData['referralId'] = newData.firstName.toLowerCase() + Math.floor(1000 + Math.random() * 9000).toString();
                                    let role = auth.info.profile.usertype;
                                    if (role === 'fleetadmin') {
                                        newData['fleetadmin'] = auth.info.uid;
                                    }
                                    dispatch(addUser(newData));
                                    resolve();
                                }
                            });
                        }, 600);
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();

                            let car = cartypes.cars.filter((car) => car.name == newData.carType && car.type == "taxi")[0];
                            if (car) {
                                newData["carTypeCode"] = car.code;
                            }
                            if (newData.codeDriver) {
                                newData.codeDriver = newData.codeDriver.toString().trim();
                            }
                            dispatch(editUser(oldData.id, newData));
                            dispatch(fetchOnceUsers());
                        }, 600);
                    }),
                onRowDelete: oldData =>
                    features.AllowCriticalEditsAdmin ?
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                dispatch(deleteUser(oldData.id));
                            }, 600);
                        })
                        :
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                alert(language.demo_mode);
                            }, 600);
                        })
                ,
            }}
        />
    );
}
