import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {FirebaseContext} from "common";
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    titleList: {
        fontSize: 14,
        marginTop: 10
    },
    submit: {
        backgroundColor: "#343434",
        color: "#E8E8E8"
    },
    backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)",
        flexDirection: 'column'
    },
    row: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "rgba(255,0,0,0.74)"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#E8E8E8",
        fontSize: 16,
        padding: '10px 2px 10px 10px',
        color: "#000"
    },
    commonInputStyle: {
        borderRadius: 4,
        backgroundColor: "#E8E8E8",
    },
    title: {color: "#000"}
}));

export default function ButtonCallCenterDetail(props) {
    const styles = useStyles();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    id={"bookingbuttonmap_detail_" + (props.callObj && props.callObj.phone ? props.callObj.phone : "")}
                    className={styles.submit}
                    onClick={
                        () => {
                            props.showModal(props.popupData, props.callObj);
                        }
                    }
                >
                    {props.titleButton ? props.titleButton : (props.callObj && props.callObj.alias ? props.callObj.alias : "Cập nhật bí danh")}
                </Button>
            </Grid>
        </Grid>
    );
}
