import React, {useState, useEffect, useContext, useCallback} from 'react';
import MaterialTable from 'material-table';
import {useSelector, useDispatch} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import {
    features,
    language
} from 'config';
import {FirebaseContext} from 'common';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import {Button, Text, View} from "react-native-web";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        alignContent: 'center'
    },
    gridcontainer: {
        alignContent: 'center'
    },
    items: {
        margin: 0,
        width: '100%'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        width: 192,
        height: 192
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        height: 39,
    },
    marginTop50: {
        marginTop: 50,
    },
    borderLeftCallCenter: {
        borderRightColor: "#0078ff",
        borderRightWidth: 2,
        backgroundColor: "rgba(255,255,255,0.2)"
    },
    backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)",
        flexDirection: 'column'
    },
    marginBottom50: {
        marginBottom: 50
    },
    titleList: {
        fontSize: 14,
        marginTop: 10
    },
    input: {
        height: 25,
        borderRadius: 5
    },
    row: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid"
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
}));

export default function ReportDrivers(callback, deps) {
    const {api} = useContext(FirebaseContext);
    const {
        fetchReportDrivers,
        stopFetchReportDrivers
    } = api;

    const reportdriverdata = useSelector(state => state.reportdriverdata);
    const dispatch = useDispatch();
    const [dataRp, setDataRp] = useState([]);
    const [searchInit, setSearchInit] = useState(null);
    const [dataRpImportance, setDataRpImportance] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        dispatch(fetchReportDrivers());
        return () => {
            dispatch(stopFetchReportDrivers());
        };
    }, []);

    useEffect(() => {
        if (reportdriverdata.reports) {
            setDataRp(reportdriverdata.reports.filter(report => report.content !== ''));
            let listAlert = reportdriverdata.reports.filter(report => report.content.includes("hủy 1")
                || report.content.includes("hủy 0")
                || report.content.includes("hủy 2")
                || report.content.includes("hủy 3")
                || report.content.includes("hỗ trợ")
                || report.content.includes("vi phạm")
                || report.content.includes("nhận xe")
                || report.content.includes("đã đồng ý thay")
                || report.content.includes("hủy đặt xe")
                || report.content.includes("điểm đón cuốc khách")
                || report.content.includes("tự động hủy"));

            if (dataRpImportance) {
                if (listAlert && listAlert.length > dataRpImportance.length) {
                    if (listAlert[0]
                        && (listAlert[0].content.includes("hủy 0")
                            || listAlert[0].content.includes("hủy 0")
                            || listAlert[0].content.includes("hủy 2")
                            || listAlert[0].content.includes("hủy 3")
                            || listAlert[0].content.includes("tự động hủy")
                        )
                    ) {
                        //playSound2();
                    }

                    if (listAlert[0]
                        && (listAlert[0].content.includes("vi phạm")
                        )
                    ) {
                        //playSoundViPham();
                    }

                    if (listAlert[0]
                        && (listAlert[0].content.includes("hủy đặt xe")
                        )
                    ) {
                        //playSoundHuy();
                    }

                    if (listAlert[0]
                        && (listAlert[0].content.includes("đã đồng ý thay")
                        )
                    ) {
                        //playSoundDoiCa();
                    }

                    if (listAlert[0]
                        && (listAlert[0].content.includes("hỗ trợ")
                        )
                    ) {
                        //playSoundHoTro();
                    }
                }
            } else {
                //playSound2();
            }
            setDataRpImportance(listAlert);
        } else {
            setDataRp([]);
            setDataRpImportance([]);
        }
    }, [reportdriverdata.reports, searchInit]);

    const searchCallback = useCallback((searchText) => {
        setSearchInit(null);
        setDataRp(null);
        if (searchText === searchInit) {
            searchText = '';
        }
        if (searchText) {
            //tableRef.current.options.searchText(searchText);
            setSearchInit(searchText);
        }
    }, []);


    const columns = [
        {title: language.notification_title, field: 'content', render: rowData => <InfoReport content={rowData.content} hasButton={true} search={searchCallback}/>}
    ];
    const columns_rp = [
        {title: "Nhật ký cuốc khách", field: 'content', render: rowData => <InfoReport content={rowData.content} hasButton={false}/>}
    ];
    const InfoReport = (props) => {
        let content = props.content;
        var bookingKey = null;
        let contentArray = content.split("#");
        if (contentArray.length > 0) {
            bookingKey = contentArray[contentArray.length - 1];
            if (bookingKey) {
                bookingKey = bookingKey.trim();
            }
        }
        var colorButton = 'rgb(0, 147, 227)';
        if (content.includes("hủy")) {
            colorButton = 'red';
        }
        if (content.includes("vi phạm")) {
            colorButton = 'rgb(208, 188, 0)';
        }
        if (content.includes("hỗ trợ")) {
            colorButton = 'rgb(208, 188, 0)';
        }
        return (
            <View style={{
                flex: 0.5,
                justifyContent: 'center',
                marginHorizontal: 10,
            }}>
                <Text style={{fontSize: 13}}>
                    {content}
                </Text>
                {props.hasButton && props.search ?
                    <Button
                        onPress={() => {
                            props.search(bookingKey);
                        }}
                        title={bookingKey}
                        titleStyle={{color: "#FFF"}}
                        color={colorButton}
                        containerStyle={{
                            flex: 1,
                            alignSelf: 'flex-end',
                            paddingRight: 14,
                        }}
                    /> : null}
            </View>
        );
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography component="h1" variant="h5" align="center" className={classes.marginBottom50}>
                    {"Trung tâm báo cáo cuốc khách"}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: 50}}>
                {dataRpImportance ?
                    <MaterialTable
                        title={""}
                        columns={columns}
                        data={dataRpImportance}
                        options={{
                            exportButton: false,
                            columnsButton: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 25, 50, 100, 200, 500]
                        }}
                        style={{
                            flexGrow: 0
                        }}
                    /> : null}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: 50}}>
                {dataRp ?
                    searchInit ?
                        <MaterialTable
                            title={""}
                            columns={columns_rp}
                            data={dataRp}
                            options={{
                                exportButton: false,
                                columnsButton: true,
                                pageSize: 10,
                                pageSizeOptions: [10, 25, 50, 100, 200, 500],
                                searchText: searchInit ? searchInit : null
                                //filtering: true
                            }}
                            style={{
                                flexGrow: 0
                            }}
                        /> : null
                    : null}


                {dataRp && !searchInit ?
                    <MaterialTable
                        title={""}
                        columns={columns_rp}
                        data={dataRp}
                        options={{
                            exportButton: false,
                            columnsButton: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 25, 50, 100, 200, 500]
                        }}
                        style={{
                            flexGrow: 0
                        }}
                    /> : null}
            </Grid>
        </Grid>
    );
}
