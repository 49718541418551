import {
    CLEAR_BOOKING,
    CONFIRM_BOOKING,
    CONFIRM_BOOKING_FAILED,
    CONFIRM_BOOKING_SUCCESS, FETCH_BOOKING_DETAIL_BY_CODE, FETCH_BOOKING_DETAIL_BY_CODE_FAILED, FETCH_BOOKING_DETAIL_BY_CODE_STOP, FETCH_BOOKING_DETAIL_BY_CODE_SUCCESS,
    SELECT_BOOKING_DETAIL,
    SELECT_BOOKING_DETAIL_FAILED,
    SELECT_BOOKING_DETAIL_ON, SELECT_BOOKING_DETAIL_ON_FAILED,
    SELECT_BOOKING_DETAIL_ON_SUCCESS,
    SELECT_BOOKING_DETAIL_SUCCESS,
    SELECT_BOOKING_DRIVER,
    SELECT_BOOKING_DRIVER_SUCCESS
} from "../store/types";

const INITIAL_STATE = {
    booking: null,
    loading: false,
    bookingDetail: null,
    bookingOn: null,
    booking_uid: null,
    fetchBooking: null,
    error: {
        flag: false,
        msg: null
    }
}

export const bookingreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONFIRM_BOOKING:
            return {
                ...state,
                loading: true
            };
        case CONFIRM_BOOKING_SUCCESS:
            return {
                ...state,
                booking: action.payload,
                loading: false
            };
        case CONFIRM_BOOKING_FAILED:
            return {
                ...state,
                booking: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case SELECT_BOOKING_DRIVER:
            return {
                ...state,
                loading: true
            };
        case SELECT_BOOKING_DRIVER_SUCCESS:
            return {
                ...state,
                booking_uid: action.booking_uid,
                loading: false
            };

        case SELECT_BOOKING_DETAIL:
            return {
                ...state,
                loading: true
            };
        case SELECT_BOOKING_DETAIL_SUCCESS:
            return {
                ...state,
                bookingDetail: action.bookingDetail,
                loading: false
            };

        case SELECT_BOOKING_DETAIL_FAILED:
            return {
                ...state,
                bookingDetail: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case SELECT_BOOKING_DETAIL_ON:
            return {
                ...state,
                loading: true
            };
        case SELECT_BOOKING_DETAIL_ON_SUCCESS:
            return {
                ...state,
                bookingOn: action.bookingOn,
                loading: false
            };

        case SELECT_BOOKING_DETAIL_ON_FAILED:
            return {
                ...state,
                bookingOn: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_BOOKING_DETAIL_BY_CODE:
            return {
                ...state,
                loading: true
            };

        case FETCH_BOOKING_DETAIL_BY_CODE_SUCCESS:
            return {
                ...state,
                fetchBooking: action.payload,
                loading: false
            };

        case FETCH_BOOKING_DETAIL_BY_CODE_FAILED:
            return {
                ...state,
                fetchBooking: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case CLEAR_BOOKING:
            return INITIAL_STATE;
            
        case FETCH_BOOKING_DETAIL_BY_CODE_STOP:
            return INITIAL_STATE;
        default:
            return state;
    }
};