import React from "react";
import IconButton from "@mui/material/IconButton";
import Delete from '@mui/icons-material/Delete';
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import {useTreeItem} from "@mui/lab";
import TreeItem from "@mui/lab/TreeItem";
import Switch from '@mui/material/Switch';
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
        classes,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
        onDelete,
        onChangeStatus,
        item,
        type,
        parentId
    } = props;
    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;
    const handleDelete = () => {
        if (onDelete) {
            parentId ? onDelete(parentId, nodeId) : onDelete(nodeId)
        }
    };
    const handleChange = (event, status) => {
        if (onChangeStatus) {
            parentId ? onChangeStatus(parentId, nodeId, status) : onChangeStatus(nodeId, status)
        }
    };
    return (
        <div
            className={clsx(classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled
            })}
            onMouseDown={preventSelection}
            style={{
                display: 'flex',
                flexDirection: 'row',
            }}
            ref={ref}
        >
            <div onClick={handleExpansion} className={classes.iconContainer}>
                {icon}
            </div>
            {parentId ?
                <IconButton size="small" onClick={handleDelete}
                            style={{
                                padding: 7,
                                color: '#ff0000'
                            }}
                >
                    <HorizontalRuleIcon fontSize="small"/>
                </IconButton> : null}
            <Typography
                onClick={handleSelection}
                component="div"
                className={classes.label}
                style={{
                    padding: 7
                }}
            >
                {label}
            </Typography>
            {type == "parent" ?
                <IconButton size="small" onClick={handleDelete}
                            style={{
                                padding: 7,
                                color: '#ff0000'
                            }}
                >
                    <Delete fontSize="small"/>
                </IconButton> : null}
            <div style={{
                padding: 7
            }}>
                <Switch size="small" onChange={handleChange} defaultChecked={item ? item.status : false}/>
            </div>
        </div>
    );
});
export default function CustomTreeView(props) {
    return (
        <TreeItem
            {...props}
            ContentComponent={CustomContent}
            ContentProps={{
                onDelete: props.onDelete,
                onChangeStatus: props.onChangeStatus,
                item: props.item,
                type: props.type,
                parentId: props.parentId
            }}
        />
    );
}
