import {
    FETCH_CALL_ACTIVE,
    FETCH_CALL_ACTIVE_FAILED,
    FETCH_CALL_ACTIVE_SUCCESS,
    FETCH_CALLS_DETAIL,
    FETCH_CALLS_DETAIL_FAILED,
    FETCH_CALLS_DETAIL_SUCCESS,
    FETCH_CALLS_PENDING,
    FETCH_CALLS_PENDING_FAILED,
    FETCH_CALLS_PENDING_SUCCESS,
    PUSH_CALL_BY_ID,
    PUSH_CALL_BY_ID_FAILED,
    PUSH_CALL_BY_ID_SUCCESS,
    REPORT_FLEET_ADMIN,
    REPORT_FLEET_ADMIN_SUCCESS,
    STOP_FETCH_CALLS_DETAIL,
    STOP_FETCH_CALLS_PENDING,
} from "../store/types";

const INITIAL_STATE = {
    loading: false,
    calls: null,
    callCenter: null,
    callDetail: null,
    loading_report: false,
    call: null,
    error: {
        flag: false,
        msg: null
    },
    report_fleet: []
}


export const pacallcenterreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PUSH_CALL_BY_ID:
            return {
                ...state,
                loading: true
            };
        case PUSH_CALL_BY_ID_SUCCESS:
            return {
                ...state,
                call: action.payload,
                loading: false
            };
        case PUSH_CALL_BY_ID_FAILED:
            return {
                ...state,
                call: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case FETCH_CALLS_PENDING:
            return {
                ...state,
                loading: true
            };
        case FETCH_CALLS_PENDING_SUCCESS:
            return {
                ...state,
                calls: action.payload,
                loading: false
            };
        case FETCH_CALLS_PENDING_FAILED:
            return {
                ...state,
                calls: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case STOP_FETCH_CALLS_PENDING:
            return {
                ...state,
                calls: null,
                loading: false
            };

        case FETCH_CALLS_DETAIL:
            return {
                ...state,
                loading: true
            };
        case FETCH_CALLS_DETAIL_SUCCESS:
            return {
                ...state,
                callDetail: action.payload,
                loading: false
            };
        case FETCH_CALLS_DETAIL_FAILED:
            return {
                ...state,
                callDetail: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case STOP_FETCH_CALLS_DETAIL:
            return {
                ...state,
                callDetail: null,
                loading: false,
            };

        case FETCH_CALL_ACTIVE:
            return {
                ...state,
                loading: true
            };
        case FETCH_CALL_ACTIVE_SUCCESS:
            return {
                ...state,
                callCenter: action.payload,
                loading: false
            };
        case FETCH_CALL_ACTIVE_FAILED:
            return {
                ...state,
                callCenter: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case REPORT_FLEET_ADMIN:
            return {
                ...state,
                loading_report: true
            };
        case REPORT_FLEET_ADMIN_SUCCESS:
            return {
                ...state,
                report_fleet: action.payload,
                loading_report: false
            };

        default:
            return state;
    }
};
