import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useDispatch, useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import {dateStyle, features, language} from 'config';
import {FirebaseContext} from 'common';
import Button from "../components/CustomButtons/Button";
import {View} from "react-native-web";
import {useHistory} from "react-router-dom";

export default function Promos() {
    const {api} = useContext(FirebaseContext);
    const {
        editPromo,
    } = api;
    let history = useHistory();

    const [data, setData] = useState([]);
    const promodata = useSelector(state => state.promodata);
    const dispatch = useDispatch();
    const columns = [
        {title: language.promo_name, field: 'promo_name'},
        {title: language.description, field: 'promo_description'},
        {
            title: language.title,
            field: 'promo_discount_type',
            lookup: {flat: language.flat, percentage: language.percentage},
        },
        {title: language.promo_discount_value, field: 'promo_discount_value', type: 'numeric'},
        {title: language.max_limit, field: 'max_promo_discount_value', type: 'numeric'},
        {title: language.min_limit, field: 'min_order', type: 'numeric'},
        {
            title: language.start_date,
            field: 'promo_start',
            render: rowData => rowData.promo_start ? new Date(rowData.promo_start).toLocaleDateString(dateStyle) : null
        },
        {
            title: language.end_date,
            field: 'promo_validity',
            render: rowData => rowData.promo_validity ? new Date(rowData.promo_validity).toLocaleDateString(dateStyle) : null
        },
        {title: language.promo_usage, field: 'promo_usage_limit', type: 'numeric'},
        {title: language.promo_used_by, field: 'promo_used_by', type: 'numeric'}, //, editable:'never'
        {title: language.promo_driver, field: 'promo_driver'},
        {
            title: language.promo_locations, field: 'promo_location', render: rowData => (

                <View>
                    <Button variant="outlined" color="primary" onClick={(e) => {
                        e.preventDefault();
                        history.push({
                            pathname: `/promolocation`,
                            search: `?promo_id=${rowData.id}`,
                            state: {rowData: rowData}
                        })
                    }}>
                        Cập nhật
                    </Button>
                </View>
            )
        },
        {title: language.promo_status, field: 'promo_status', type: 'boolean', initialEditValue: false}
    ];

    useEffect(() => {
        if (promodata.promos) {
            setData(promodata.promos);
        } else {
            setData([]);
        }
    }, [promodata.promos]);

    return (
        promodata.loading ? <CircularLoading/> :
            <MaterialTable
                title={language.promo_offer}
                columns={columns}
                data={data}
                editable={features.AllowCriticalEditsAdmin ? {
                    onRowAdd: newData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                const tblData = data;
                                tblData.push(newData);
                                dispatch(editPromo(newData), "Add");
                            }, 600);
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                const tblData = data;
                                tblData[tblData.indexOf(oldData)] = newData;
                                dispatch(editPromo(newData), "Update");
                            }, 600);
                        }),
                    onRowDelete: newData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                dispatch(editPromo(newData), "Delete");
                            }, 600);
                        })
                } : null}
            />
    );
}
