import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useDispatch, useSelector} from "react-redux";
import {Dimensions, Text} from "react-native";
import {FirebaseContext} from "common";
import {RequestPushMsg} from "common/src/other/NotificationFunctions";
import {View} from "react-native-web";
import {language} from "config";
import {Button, IconButton, MenuItem, Select} from "@material-ui/core";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import MenuIcon from "@material-ui/icons/Menu";
import {ConvertTimeStampToDate, GetCurrentTimeStamp} from "common/src/other/DateFunctions";

var {height} = Dimensions.get('window');
export default function GroupDrivers(props) {
    const {api} = useContext(FirebaseContext);
    const {
        updateUserLockStatus,
        fetchAllDrivers,
        getBookingByDriver,
        editGroupDriver,
        updateUserGroup,
    } = api;
    const dispatch = useDispatch();
    const [groupDriver, setGroupDriver] = useState(language.select_group_driver);
    const groupdriverdata = useSelector(state => state.groupdriverdata);
    const [groups, setGroups] = useState([]);
    const [groupLocks, setGroupLocks] = useState([]);
    const drivers = useSelector(state => state.usersdata.drivers);
    const [listDriver, setListDriver] = useState(drivers ? drivers : null);
    const [showMenu, setShowMenu] = useState(true);

    useEffect(() => {
        if (groupdriverdata.complex && groupdriverdata.complex.length > 0) {
            let array = [];
            let arrayLock = [];
            groupdriverdata.complex.map((group) => {
                array[group.value] = group.label;
                arrayLock[group.value] = group.lock_status;
            });

            setGroups(array);
            setGroupLocks(arrayLock);
        } else {
            setGroups([]);
        }
    }, [groupdriverdata.complex]);

    const columns = [
        {
            title: " ST ",
            field: "carStatus",
            render: rowData => rowData.carStatus ?
                <img title={"Click to view"} src={require(`../../assets/img/` + rowData.carStatus).default} style={{width: 25, cursor: 'pointer'}} onClick={() => {
                    dispatch(getBookingByDriver(rowData.id));
                    handleRowClick(rowData).then(r => {
                        if (rowData.location) {
                            props.loadCarInfo(rowData);
                        } else {
                            alert("Không xác định được vị trí lx");
                        }

                    });
                }}/> : null,
            editable: 'never',
            cellStyle: {
                fontSize: 12,
                padding: 5,
            },
            headerStyle: {
                fontSize: 12,
                padding: 5,
            },
            width: "10%"
        },
        {
            title: "Đàm", field: 'codeDriver', initialEditValue: '',
            render: rowData =>
                <label title={"Click to lock"}
                       style={{cursor: 'pointer', color: rowData.lock_driver_status ? "red" : "black"}}
                       onClick={() => {
                           confirmAlert({
                               title: 'Xác nhận',
                               message: 'Bạn muốn thay đổi trạng thái Lx ' + rowData.codeDriver + " ?",
                               buttons: [
                                   {
                                       label: 'Đúng',
                                       onClick: () => {
                                           if (rowData.lock_driver_status === false) {
                                               dispatch(updateUserLockStatus(rowData.id, true));
                                               RequestPushMsg(rowData.pushToken, "Bạn đã bị tâm khóa -> không thể nhận cuốc khách");
                                           } else {
                                               dispatch(updateUserLockStatus(rowData.id, false));
                                           }
                                           setTimeout(() => {
                                               dispatch(fetchAllDrivers());
                                           }, 600);
                                       }
                                   },
                                   {
                                       label: 'Không',
                                       onClick: () => console.log('NO Pressed')
                                   }
                               ]
                           });

                       }}
                >
                    {rowData.codeDriver}
                </label>,
            cellStyle: {
                fontSize: 12,
                padding: 5
            },
            headerStyle: {
                fontSize: 12,
                padding: 5
            }

        },
        {
            title: "Nhóm", field: 'groupDriver',
            cellStyle: {
                fontSize: 12,
                padding: 5
            },
            headerStyle: {
                fontSize: 12,
                padding: 5
            },
            render: rowData =>
                <label title={"Click to Lock"}
                       style={{cursor: 'pointer', color: groupLocks[rowData.groupDriver] ? 'red' : "black", fontSize: 12}}
                       onClick={() => {
                           confirmAlert({
                               title: 'Xác nhận',
                               message: "Bạn muốn thay đổi trạng thái điểm kinh doanh " + groups[rowData.groupDriver] + " ?",
                               buttons: [
                                   {
                                       label: 'Đúng',
                                       onClick: () => {
                                           const tblData = groupdriverdata.complex;
                                           groupdriverdata.complex.map((group, index) => {
                                               if (group.value === rowData.groupDriver) {
                                                   var newData = group;
                                                   newData.lock_status = !groupLocks[rowData.groupDriver];
                                                   tblData[index] = newData;
                                                   if (newData.lock_status === true) {
                                                       listDriver.map((driverUpdate) => {
                                                           if (driverUpdate.groupDriver === rowData.groupDriver) {
                                                               dispatch(updateUserGroup(driverUpdate.id, 0));
                                                               RequestPushMsg(driverUpdate.pushToken, 'Tâm đã Khóa Nhóm', 'Nhóm ' + (groups[rowData.groupDriver] ? groups[rowData.groupDriver] : " của bạn") + " đã bị khóa từ Tâm. Bạn được chuyển về nhóm chờ.");
                                                           }
                                                       });
                                                   }
                                                   dispatch(editGroupDriver(tblData, "Update"));
                                               }
                                           });
                                       }
                                   },
                                   {
                                       label: 'Không',
                                       onClick: () => console.log('NO Pressed')
                                   }
                               ]
                           })
                       }}
                >
                    {groups[rowData.groupDriver]}
                </label>
        },
        {
            title: "BKS", field: 'vehicleNumber',
            cellStyle: {
                fontSize: 12,
                padding: 5
            },
            headerStyle: {
                fontSize: 12,
                padding: 5
            }
        },
        {
            title: "Thời gian trong điểm", field: 'timestampingroup',
            render: rowData => <label>{ConvertTimeStampToDate(rowData.timestampingroup * 1000)}</label>,
            cellStyle: {
                fontSize: 12,
                padding: 5
            },
            headerStyle: {
                fontSize: 12,
                padding: 5
            }
        }
    ];

    async function handleRowClick(driver) {
        if (props.loadCarInfo) {
            props.loadCarInfo(driver);
        }
    };

    const handleGroupDriver = (event) => {
        setGroupDriver(event.target.value);
        if (language.select_group_driver !== event.target.value) {
            let driverArray = drivers.filter((driver) => {
                if (Number(driver.groupDriver) === Number(event.target.value)) {
                    return true;
                }
            });
            if(driverArray){
                driverArray = driverArray.sort(function (a, b) {
                    if (!b.timestampingroup) {
                        b.timestampingroup = GetCurrentTimeStamp();
                    }
                    if (!a.timestampingroup) {
                        a.timestampingroup = GetCurrentTimeStamp();
                    }
                    return (a.timestampingroup - b.timestampingroup);
                });
                setListDriver(driverArray);
            }
        } else {
            setListDriver(drivers);
        }
    };

    useEffect(() => {
        if (drivers) {
            if (language.select_group_driver !== groupDriver) {
                let driverArray = drivers.filter(driverG => Number(driverG.groupDriver) === Number(groupDriver));
                if(driverArray){
                    driverArray = driverArray.sort(function (a, b) {
                        if (!b.timestampingroup) {
                            b.timestampingroup = GetCurrentTimeStamp();
                        }
                        if (!a.timestampingroup) {
                            a.timestampingroup = GetCurrentTimeStamp();
                        }
                        return (a.timestampingroup - b.timestampingroup);
                    });
                    setListDriver(driverArray);
                }
            } else {
                setListDriver(drivers);
            }
        }
    }, [drivers]);
    return (
        <View style={{zIndex: 99, position: 'fixed', top: 10, right: 10, backgroundColor: 'white'}}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => {
                    setShowMenu(!showMenu);
                }}
                style={{
                    position: `absolute`,
                    right: showMenu ? `240px` : '10px',
                    top: showMenu ? '40px' : '10px',
                    zIndex: 999,
                    background: "blanchedalmond"
                }}
            >
                <Text style={{color: '#e17a00', fontWeight: 'bold'}}> </Text>
                <MenuIcon/>
            </IconButton>
            {groupdriverdata.complex && showMenu ?
                <View style={{flex: 1, flexDirection: 'row'}}>
                    <Button
                        size="large"
                        onClick={() => {
                            if (groupDriver != language.select_group_driver) {
                                confirmAlert({
                                    title: 'Xác nhận',
                                    message: "Bạn muốn thay đổi trạng thái điểm kinh doanh " + groups[groupDriver] + " ?",
                                    buttons: [
                                        {
                                            label: 'Đúng',
                                            onClick: () => {
                                                const tblData = groupdriverdata.complex;
                                                groupdriverdata.complex.map((group, index) => {
                                                    if (group.value === groupDriver) {
                                                        var newData = group;
                                                        newData.lock_status = !groupLocks[groupDriver];
                                                        tblData[index] = newData;
                                                        if (newData.lock_status === true) {
                                                            listDriver.map((driverUpdate) => {
                                                                if (driverUpdate.groupDriver === groupDriver) {
                                                                    dispatch(updateUserGroup(driverUpdate.id, 0));
                                                                    RequestPushMsg(driverUpdate.pushToken, 'Tâm đã Khóa Nhóm', 'Nhóm ' + (groups[groupDriver] ? groups[groupDriver] : " của bạn") + " đã bị khóa từ Tâm. Bạn được chuyển về nhóm chờ.");
                                                                }
                                                            });
                                                        }

                                                        dispatch(editGroupDriver(tblData, "Update"));
                                                    }
                                                });
                                            }
                                        },
                                        {
                                            label: 'Không',
                                            onClick: () => console.log('NO Pressed')
                                        }
                                    ]
                                })
                            }
                        }}
                        variant="contained"
                        color="primary"
                        className={{
                            marginLeft: 5,
                            width: '30px',
                            height: '25px'
                        }}
                    >
                        {groupDriver != language.select_group_driver ?
                            groupLocks[groupDriver] === true ? "Mở" : "Khóa"
                            : null}

                    </Button>
                    <Select
                        id="group-driver-native"
                        value={groupDriver}
                        onChange={handleGroupDriver}
                        variant="outlined"
                        fullWidth
                        style={{padding: 5, fontSize: 13, height: 35}}
                    >
                        <MenuItem value={language.select_group_driver} key={language.select_group_driver}
                                  style={{padding: 5, fontSize: 13}}>
                            {language.select_group_driver}
                        </MenuItem>
                        {
                            groupdriverdata.complex.map((groupItem) =>
                                <MenuItem key={groupItem.label} value={groupItem.value}
                                          style={{padding: 5, fontSize: 13}}>
                                    {groupItem.label}
                                </MenuItem>
                            )
                        }
                    </Select>
                </View>
                : null
            }
            {drivers && showMenu ?
                <MaterialTable
                    title={""}
                    columns={columns}
                    data={listDriver}
                    style={{
                        width: 280,
                        zIndex: 90,
                        padding: 0
                    }}
                    options={{
                        maxBodyHeight: height - 150,
                        sorting: false,
                        grouping: false,
                        pageSize: 25,
                        pageSizeOptions: [10, 25, 50, 100]
                    }}
                    editable={{}}
                /> : null}
        </View>
    );
}
