import React, {useContext, useEffect} from 'react';
import {View} from "react-native-web";
import {colors} from "../../config/theme";
import IconButton from "@mui/material/IconButton";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import {GetCurrentDateCaTrucDem} from "common/src/other/DateFunctions";
import {useDispatch, useSelector} from "react-redux";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import {FirebaseContext} from "common";

export default function ChangeCarComponent(props) {
    const {api} = useContext(FirebaseContext);
    const {
        fetchDriverCaTrucDem,
        stopFetchDriverCaTrucDem
    } = api;
    const dispatch = useDispatch();
    const catrucdems = useSelector(state => state.catrucdems.catrucdem);

    useEffect(() => {
        /*dispatch(fetchDriverCaTrucDem(GetCurrentDateCaTrucDem()));
        return () => {
            dispatch(stopFetchDriverCaTrucDem());
        };*/
    }, []);

    const obj = props.callCenterObj;
    console.log("catrucdems", catrucdems);
    return (
        <View style={{
            maxWidth: 310, backgroundColor: 'rgba(255,255,255,0.77)',
            borderRadius: 10,
            marginTop: 5,
            shadowOffset: {
                height: 1,
                width: 0,
            },
            shadowColor: colors.BLACK,
            shadowOpacity: 0.35,
            shadowRadius: 10,
            border: 1,
            borderStyle: 'solid',
            borderColor: "#c7c7c7",
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: "100%",
                padding: 0,
                margin: 0
            }}>
                <IconButton
                    aria-label="Ghi chú"
                    style={{
                        display: 'flex',
                        alignItems: 'normal',
                        justifyContent: 'left',
                        flexGrow: 1
                    }}
                    color="primary"
                    size="medium"
                    onClick={() => {

                    }}
                >
                    <CalendarMonthOutlinedIcon fontSize="inherit" style={{color: "#6059a7"}}/>
                    <span style={{color: "#232323", fontSize: 15, marginTop: 5, marginLeft: 10}}>{"Thời gian gọi đến"}</span>
                </IconButton>
            </div>


            <div style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 10,
                width: "100%",
                paddingBottom: 10
            }}>
                <img
                    height={"50"}
                    src={require("../../assets/img/car_change_icon.png").default}
                    alt="Password"
                    style={{
                        position: 'absolute', top: 50, right: 30
                    }}
                />
                <FiberManualRecordIcon fontSize="medium" style={{color: "#6059a7", margin: 0, padding: 0, position: 'absolute', top: 35, left: 10}}/>
                <span style={{marginLeft: 80, fontSize: 25, fontWeight: 'bold'}}>08</span>
                <span style={{marginLeft: 80, fontSize: 25, fontWeight: 'bold'}}>253</span>
                <FiberManualRecordOutlinedIcon fontSize="medium" style={{color: "#a9d7dc", margin: 0, padding: 0, position: 'absolute', top: 90, left: 10}}/>
            </div>
        </View>
    )
}
