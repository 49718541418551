import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AppMenu from "../views/AppMenu";
import {AppDetails} from 'config';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    /*marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },*/
    width: drawerWidth,
    left: 0
  },
  menuButton: {
    marginRight: theme.spacing(1),
    /*[theme.breakpoints.up('sm')]: {
      display: 'none',
    },*/
    display: 'block'
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    marginTop: 63,
  },
  content: {
    /*flexGrow: 1,
    padding: theme.spacing(1),*/
  },
}));

function ResponsiveDrawer(props) {
  const {container} = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
      <div className={classes.root}>
        <CssBaseline/>
        {<AppBar position="fixed" className={classes.appBar}>
          <Toolbar style={{backgroundColor: '#444444'}}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
            >
              <MenuIcon/>
            </IconButton>
            <Typography variant="h6" noWrap>
              {AppDetails.app_name}
            </Typography>
          </Toolbar>
        </AppBar>}
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
            >
              <AppMenu/>
            </Drawer>
          </Hidden>
          {/*<Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <AppMenu/>
                    </Drawer>
                </Hidden>*/}
        </nav>
        <main >
          <div />
          {props.children}
        </main>
      </div>
  );
}

ResponsiveDrawer.propTypes = {
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default ResponsiveDrawer;
