import React, {useContext, useEffect, useState} from 'react';
import {Button, Grid, TextField, Typography} from '@material-ui/core';
import GoogleMapsAutoComplete from '../components/GoogleMapsAutoComplete';
import {useDispatch, useSelector} from "react-redux";
import {language} from 'config';
import {makeStyles} from '@material-ui/core/styles';
import {FirebaseContext} from 'common';
import {GetDistance} from "common/src/other/GeoFunctions";

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        alignContent: 'center'
    },
    title: {
        color: "#000",
    },
    gridcontainer: {
        alignContent: 'center'
    },
    items: {
        margin: 0,
        width: '100%'
    },
    input: {
        fontSize: 18,
        color: "#000"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
    buttonStyle: {
        margin: 0,
        width: '100%',
        height: '100%'
    }
}));

export default function UpdatePickupBooking(props) {
    const {api} = useContext(FirebaseContext);
    const {
        updateBooking,
        updateCallCancelById,
        addReport,
        addBookingV2,
        fetchDriversOutInGroup
    } = api;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [noteRider, setNoteRider] = useState(null);
    const [pickupAddress, setPickupAddress] = useState(null);
    const [driver, setDriver] = useState(null);
    const groups = useSelector(state => state.groupdriverdata.complex);
    const [booking, setBooking] = useState(null);
    const usersdata = useSelector(state => state.usersdata);
    const settings = useSelector(state => state.settingsdata.settings);
    const [groupApply, setGroupApply] = useState(null);
    const [coordApply, setCoordApply] = useState(null);
    const [bankinh, setBankinh] = useState(null);

    useEffect(() => {
        if (settings && settings.bankinh) {
            setBankinh(settings.bankinh);
        } else {
            setBankinh(3);
        }

        if (props.driverActive) {
            setDriver(props.driverActive);
        }

        if (props.booking) {
            setBooking(props.booking);
            if (props.booking.customer_note) {
                setNoteRider(props.booking.customer_note);
            }
        }

        if (props.bookingObj && props.bookingObj.userDetails && props.bookingObj.userDetails.profile && props.bookingObj.userDetails.profile.note) {
            setNoteRider(props.bookingObj.userDetails.profile.note);
        }

        if (props.address) {
            setPickupAddress(props.address);
            if (groups && groups.length > 0) {
                let bankinhCheck = settings && settings.bankinh ? settings.bankinh : 3;
                var kmcheck = 0;
                var coordApplyCheck = null;
                var groupApplyCheck = null;
                groups.filter(g => g.lock_status === false).map(group => {
                    // Check toa do tam
                    if (group.coord && group.value != 11 && group.value != 0 && group.value != 10 && group.value != 12 && group.value != 1 && group.lock_status === false) {
                        let coords = group.group_coords;
                        let toado_tam = group.coord.split(",");
                        if (toado_tam.length > 0) {
                            let kc_tam = GetDistance(props.address.coords.lat, props.address.coords.lng, parseFloat(toado_tam[0].toString().trim()), parseFloat(toado_tam[1].toString().trim()));
                            if (kc_tam < 10) {
                                if (coords) {
                                    coords.map(coord => {
                                        if (coord.coord && coord.coord.split(",").length > 0) {
                                            let toado_con = coord.coord.split(",");
                                            let kc_con = GetDistance(props.address.coords.lat, props.address.coords.lng, parseFloat(toado_con[0].toString().trim()), parseFloat(toado_con[1].toString().trim()));
                                            let bkc = coord.radius / 1000;
                                            if (kmcheck === 0) {
                                                kmcheck = kc_con;
                                                if (parseFloat(bkc) + parseFloat(bankinhCheck) >= parseFloat(kmcheck)) {
                                                    setCoordApply(coord);
                                                    setGroupApply(group);
                                                    coordApplyCheck = coord;
                                                    groupApplyCheck = group;
                                                }
                                            } else {
                                                if (kc_con <= kmcheck) {
                                                    kmcheck = kc_con;
                                                    if (parseFloat(bkc) + parseFloat(bankinhCheck) >= parseFloat(kmcheck)) {
                                                        setCoordApply(coord);
                                                        setGroupApply(group);
                                                        coordApplyCheck = coord;
                                                        groupApplyCheck = group;
                                                    }
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    }
                });

                if (coordApplyCheck && groupApplyCheck && props.address && props.bookingObj && bankinhCheck) {
                    dispatch(fetchDriversOutInGroup(bankinhCheck, props.bookingObj.carDetails.name, {lat: props.address.coords.lat, lng: props.address.coords.lng}, groupApplyCheck.value.toString().trim()));
                } else {
                    if (props.address && props.bookingObj && bankinhCheck && groups && groups.length > 0) {
                        var groupKd3Km = groups.filter(gp => parseInt(gp.value) === 11)[0];
                        if (groupKd3Km) {
                            dispatch(fetchDriversOutInGroup(bankinhCheck, props.bookingObj.carDetails.name, {lat: props.address.coords.lat, lng: props.address.coords.lng}, groupKd3Km));
                        }
                    }
                }
            }
        }

    }, [props, settings]);

    const handleNoteRider = (event) => {
        setNoteRider(event.target.value);
    };

    const updatePickupAddress = (e) => {
        e.preventDefault();
        if (booking && pickupAddress) {
            let bookingUpdate = {...booking};
            if (pickupAddress.coords && pickupAddress.coords.lat) {
                bookingUpdate.pickup = {add: pickupAddress.description, lat: pickupAddress.coords.lat, lng: pickupAddress.coords.lng}
            }
            bookingUpdate.pickupAddress = pickupAddress.description;
            if (noteRider) {
                bookingUpdate.customer_note = noteRider;
            }
            dispatch(updateBooking(bookingUpdate));
            if (booking.callId) {
                dispatch(addReport({content: "Đã cập nhật điểm đón cuốc khách [" + bookingUpdate.customer_note ? bookingUpdate.customer_note : '' + "] #" + booking.id}));
                dispatch(updateCallCancelById(booking.callId, {requestHelper: "pickup_added"}));
            }
            setTimeout(props.closeCallback, 2000);
            alert("Thông tin điểm đón đã được cập nhật");
        } else {
            alert("Thiếu thông tin cuốc khách!");
        }
    };

    const bookingAdd = (e) => {
        e.preventDefault();
        if (pickupAddress) {
            if (props.bookingObj) {
                let bookingUpdate = props.bookingObj;
                let bankinhCheck = settings && settings.bankinh ? settings.bankinh : 3;
                if (usersdata && usersdata.driverOutInGroup && props.address && props.bookingObj && bankinhCheck) {
                    if (coordApply && groupApply) {
                        bookingUpdate.driver_group = groupApply;
                        bookingUpdate.groupDetails = groupApply;
                    } else {
                        var groupKd3Km = groups.filter(gp => parseInt(gp.value) === 11)[0];
                        bookingUpdate.driver_group = groupKd3Km;
                        bookingUpdate.groupDetails = groupKd3Km;
                    }
                    if (usersdata.driverOutInGroup.driverInGroup) {
                        bookingUpdate.driverInGroup = usersdata.driverOutInGroup.driverInGroup;
                    }
                    if (usersdata.driverOutInGroup.driverOutGroup) {
                        bookingUpdate.driverOutGroup = usersdata.driverOutInGroup.driverOutGroup;
                    }
                }
                bookingUpdate.pickup = {add: pickupAddress.description, lat: pickupAddress.coords.lat, lng: pickupAddress.coords.lng}
                bookingUpdate.pickupAddress = pickupAddress.description;

                dispatch(addBookingV2(bookingUpdate));
            }
            setTimeout(props.closeCallback, 1000);
            alert("Cuốc khách đã được tạo");
        } else {
            alert("Thiếu thông tin cuốc khách!");
        }
    };

    return (
        <div className={classes.container}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h5" className={classes.title}>
                            {"Cập nhật điểm đón"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <GoogleMapsAutoComplete
                            apiOptions={{language: 'vi', region: 'vn'}}
                            variant={"outlined"}
                            placeholder={language.pickup_location}
                            value={props.address ? props.address : pickupAddress}
                            className={classes.items}
                            onChange={
                                (value) => {
                                    setPickupAddress(value);
                                }
                            }
                        />
                    </Grid>
                    {/*<Grid item xs={12}>
                        <GoogleMapsAutoComplete
                            apiOptions={{language: 'vi', region: 'vn'}}
                            placeholder={language.drop_location}
                            variant={"outlined"}
                            value={dropAddress}
                            className={classes.items}
                            onChange={
                                (value) => {
                                    setDropAddress(value);
                                }
                            }
                        />
                    </Grid>*/}

                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="note-river-native"
                            label={"Ghi chú cuốc xe"}
                            variant="outlined"
                            fullWidth
                            className={classes.commonInputStyle}
                            InputProps={{
                                className: classes.input
                            }}
                            value={noteRider}
                            onChange={handleNoteRider}
                        />
                    </Grid>
                    {props.bookingObj ?
                        <Grid item xs={12} sm={6}>
                            <Button
                                size="large"
                                onClick={bookingAdd}
                                variant="contained"
                                color="primary"
                                className={classes.buttonStyle}
                            >
                                <i className="fas fa-car"/>
                                {"Đặt cuốc xe"}
                            </Button>
                        </Grid> : <Grid item xs={12} sm={6}>
                            <Button
                                size="large"
                                onClick={updatePickupAddress}
                                variant="contained"
                                color="primary"
                                className={classes.buttonStyle}
                            >
                                <i className="fas fa-car"/>
                                {"Chỉ đường đón khách"}
                            </Button>
                        </Grid>}
                </Grid>
            </Grid>
        </div>
    );
}
