import React, {useContext, useEffect, useState} from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {makeStyles} from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import {Google_Map_Key, language} from "config";
import {Platform, SafeAreaView, View} from "react-native-web";
import Button from "components/CustomButtons/Button.js";
import {colors} from "../config/theme";
import {FormControl, MenuItem, Paper, Select} from "@material-ui/core";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import {useDispatch, useSelector} from "react-redux";
import Map from "../components/Map";
import {FirebaseContext} from "common";
import TextField from "@material-ui/core/TextField";
import {GetDistance} from "common/src/other/GeoFunctions";
import {fetchAddressfromCoords, getRouteDetails} from "common/src/other/GoogleAPIFunctions";
import {congthuc1chieu, congthuc2chieu} from "common/src/other/FareCalculator";
import {formatDisplayAddress, formatPriceRound} from "common/src/other/CommonFunctions";
import {formatNumber} from "react-native-currency-input";
import {Text, TouchableOpacity} from "react-native";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {useHistory, useLocation} from "react-router-dom";

const dashboardRoutes = [];

const useStyles = makeStyles(theme => ({
    fullHeight: {
        height: Platform.OS === 'web' ? '50vh' : '50%'
    },
    container: {
        flex: 1,
        backgroundColor: "#ababab",
        flexDirection: 'column',
    },
    footer: {
        flexDirection: 'row',
        backgroundColor: 'white',
        borderBottomColor: '#666666',
    },
    input: {
        paddingHorizontal: 20,
        fontSize: 18,
        flex: 1
    },
    send: {
        alignSelf: 'center',
        color: 'lightseagreen',
        fontSize: 16,
        fontWeight: 'bold',
        padding: 20
    },
    diaglog: {
        right: 0
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    gridContainer: {
        marginLef: 5,
        marginRight: 5,
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
}));

export default function BookingWeb(props) {
    const {api} = useContext(FirebaseContext);
    const {
        addBookingV2,
        bookingDetail,
        fetchDriversOutInGroup,
        updateTripPhone,
        updateTripCar,
        updatSelPointType,
        updateTripPickup,
        updateTripDrop
    } = api;
    const dispatch = useDispatch();
    const classes = useStyles();
    const {...rest} = props;
    const auth = useSelector(state => state.auth);
    const cartypes = useSelector(state => state.cartypes.cars);
    const estimatedata = useSelector(state => state.estimatedata);
    const bookingdata = useSelector(state => state.bookingdata);
    const settings = useSelector(state => state.settingsdata.settings);
    const [carType, setCarType] = useState(language.select_car);
    const [pickupAddress, setPickupAddress] = useState(null);
    const [dropAddress, setDropAddress] = useState(null);
    const [mylocation, setMylocation] = useState(null);
    const [selectedCarDetails, setSelectedCarDetails] = useState(null);
    const tripdata = useSelector(state => state.tripdata);
    const [note, setNote] = useState(null);
    const [phone, setPhone] = useState(null);
    const [groupApply, setGroupApply] = useState(null);
    const [prices, setPrices] = useState(null);
    const [checkPrice1, setCheckPrice1] = useState(null);
    const [resultCheckPrice1, setResultCheckPrice1] = useState(null);
    const [isTwoWay, setIsTwoWay] = useState(0);
    const [estimateTemp, setEstimateTemp] = useState(null);
    const [group, setGroup] = useState(null);
    const groupDriverData = useSelector(state => state.groupdriverdata.group_kd);
    const [step, setStep] = useState(1);
    const [showDiaglog, setShowDiaglog] = useState(false);

    let history = useHistory();

    const location = useLocation();

    useEffect(() => {
        if (location && location.state && location.state.step) {
            setStep(location.state.step);
        }
    }, [location]);

    useEffect(() => {
        if (mylocation == null) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    let latlng = position.coords.latitude + ',' + position.coords.longitude;
                    fetchAddressfromCoords(latlng).then((res) => {
                        dispatch(updateTripPickup(
                            {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                                add: res,
                                from: "search"
                            }
                        ));
                    });
                    setMylocation({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        }
                    )
                },
                (error) => {
                    console.log(error);
                }
            )
        }
    }, [mylocation]);

    useEffect(() => {
        if (step == 2 && tripdata) {
            dispatch(updatSelPointType('pickup'));
            if (tripdata.pickup && tripdata.pickup.add) {
                setMylocation({
                    lat: tripdata.pickup.lat,
                    lng: tripdata.pickup.lng
                });
            }
        }
        if (step == 3 && tripdata) {
            dispatch(updatSelPointType('drop'));
            if (tripdata.drop && tripdata.drop.add) {
                setMylocation({
                    lat: tripdata.drop.lat,
                    lng: tripdata.drop.lng
                });
            }
        }
    }, [step]);

    const handleCarSelect = (event) => {
        setCarType(event.target.value);
        let carDetails = null;
        for (let i = 0; i < cartypes.length; i++) {
            if (cartypes[i].name === event.target.value) {
                carDetails = cartypes[i];
            }
        }
        dispatch(updateTripCar(carDetails));
        setSelectedCarDetails(carDetails);
    };

    useEffect(() => {
        var startLoc = null;
        var destLoc = null;
        var pricesTmp = null;
        if (tripdata.pickup) {
            setPickupAddress(tripdata.pickup.add);
            startLoc = tripdata.pickup.lat + ',' + tripdata.pickup.lng;

            if (groupDriverData && groupDriverData.length > 0) {
                let listGroup = groupDriverData.filter(g => g.value != 11 && g.value != 10);
                let groups = listGroup;
                let bankinhCheck = settings && settings.bankinh ? settings.bankinh : 3;
                var kmcheck = 0;
                var coordApplyCheck = null;
                var groupApplyCheck = null;
                groups.map(group => {
                    // Check toa do tam
                    if (group.coord && group.lock_status === false) {
                        let coords = group.group_coords;
                        let toado_tam = group.coord.split(",");
                        if (toado_tam.length > 0) {
                            let kc_tam = GetDistance(tripdata.pickup.lat, tripdata.pickup.lng, parseFloat(toado_tam[0].toString().trim()), parseFloat(toado_tam[1].toString().trim()));
                            if (kc_tam < 10) {
                                if (coords) {
                                    coords.map(coord => {
                                        if (coord.coord && coord.coord.split(",").length > 0) {
                                            let toado_con = coord.coord.split(",");
                                            let kc_con = GetDistance(tripdata.pickup.lat, tripdata.pickup.lng, parseFloat(toado_con[0].toString().trim()), parseFloat(toado_con[1].toString().trim()));
                                            let bkc = coord.radius / 1000;
                                            if (kmcheck === 0) {
                                                kmcheck = kc_con;
                                                if (parseFloat(bkc) + parseFloat(bankinhCheck) >= parseFloat(kmcheck)) {
                                                    //setCoordApply(coord);
                                                    setGroupApply(group);
                                                    coordApplyCheck = coord;
                                                    groupApplyCheck = group;
                                                    if (group.prices && tripdata && tripdata.carType && tripdata.carType.code && group.prices[tripdata.carType.code]) {
                                                        pricesTmp = group.prices[tripdata.carType.code];
                                                        setPrices(pricesTmp);
                                                    }
                                                }
                                            } else {
                                                if (kc_con <= kmcheck) {
                                                    kmcheck = kc_con;
                                                    if (parseFloat(bkc) + parseFloat(bankinhCheck) >= parseFloat(kmcheck)) {
                                                        //setCoordApply(coord);
                                                        setGroupApply(group);
                                                        coordApplyCheck = coord;
                                                        groupApplyCheck = group;
                                                        if (group.prices && tripdata && tripdata.carType && tripdata.carType.code && group.prices[tripdata.carType.code]) {
                                                            pricesTmp = group.prices[tripdata.carType.code];
                                                            setPrices(pricesTmp);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    }
                });
            }
            setGroupApply(groupApplyCheck);
        } else {
            setPickupAddress(null);
        }

        if (tripdata.drop && tripdata.drop.add) {
            setDropAddress(tripdata.drop.add);
            destLoc = tripdata.drop.lat + ',' + tripdata.drop.lng;
        } else {
            if (dropAddress) {
                setDropAddress(null);
            }
        }

        if (startLoc && destLoc && pricesTmp) {
            getRouteDetails(startLoc, destLoc).then((res) => {
                if (res && res.distance) {
                    setCheckPrice1(res.distance);
                    var result = null;
                    let text = res.distance;
                    let time = res.duration ? res.duration : parseFloat(text) / 40 / 60;
                    if (isTwoWay && pricesTmp.price2) {
                        result = congthuc2chieu(parseFloat(text) * 2, time, null, 0, pricesTmp);
                    } else {
                        if (pricesTmp.price1) {
                            result = congthuc1chieu(parseFloat(text), 0, null, pricesTmp);
                        }
                    }
                    if (result && result.totalCost) {
                        setResultCheckPrice1(formatPrice(result.totalCost));
                        setEstimateTemp({
                            distance_in_km: parseFloat(text),
                            time_in_secs: time
                        });
                    } else {
                        setEstimateTemp(null);
                    }
                }
            });
        } else {
            setEstimateTemp(null);
        }

        if (groupApply && groupApply.prices && tripdata.carType && tripdata.carType.code) {
            setPrices(groupApply.prices[tripdata.carType.code]);

            if (tripdata && tripdata.pickup && tripdata.pickup.lat) {
                dispatch(fetchDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, tripdata.carType.code, tripdata.pickup, groupApply.value));
            } else {
                if (groupApply.coord) {
                    let coordArray = groupApply.coord.split(',');
                    let pickup = {
                        lat: coordArray[0].toString().trim(),
                        lng: coordArray[1].toString().trim()
                    }
                    dispatch(fetchDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, tripdata.carType.code, pickup, groupApply.value));
                }
            }
        } else {
            if (group && tripdata.carType.code) {
                if (group.coord) {
                    let coordArray = group.coord.split(',');
                    let pickup = {
                        lat: coordArray[0].toString().trim(),
                        lng: coordArray[1].toString().trim()
                    }
                    dispatch(fetchDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, tripdata.carType.code, pickup, group.value));
                }
            }
        }

        if (tripdata) {
            if (tripdata.carType && tripdata.carType.code) {
            } else {
                if (cartypes) {
                    let carDetails = null;
                    for (let i = 0; i < cartypes.length; i++) {
                        if (cartypes[i].code === 'taxi') {
                            carDetails = cartypes[i];
                        }
                    }
                    if (carDetails) {
                        setCarType(carDetails.name);
                        dispatch(updateTripCar(carDetails));
                        setSelectedCarDetails(carDetails);
                    }
                }
            }
        }

    }, [tripdata, groupApply, step]);

    const formatPrice = (value) => {
        return formatPriceRound(value, formatNumber) + "(đ)";
    }

    return (
        <SafeAreaView style={{
            flex: 1,
            flexDirection: 'column',
            padding: 5
        }}>
            <Header
                color={colors.BACKGROUND_DARK}
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks/>}
                changeColorOnScroll={{
                    color: "white"
                }}
                {...rest}
            />
            <div className={classes.container}>
                <GridContainer spacing={2}>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <h1 style={{fontSize: 16, fontWeight: 'bold', textAlign: 'center'}}>{"Đặt nhanh chóng - Đón khẩn trương - Đi chuẩn giá"}</h1>
                    </GridItem>
                </GridContainer>
                <GridContainer spacing={1}>
                    {step == 1 ?
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                            <Paper style={{margin: 10}}>
                                <TouchableOpacity style={{
                                    backgroundColor: colors.GREEN.prime,
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: 10,
                                    elevation: 2,
                                    borderRadius: 2,
                                    shadowOffset: {
                                        height: 1,
                                        width: 0
                                    },
                                    shadowColor: colors.BLACK,
                                    shadowOpacity: 0.35,
                                    shadowRadius: 5
                                }}
                                                  disabled={true}
                                                  onPress={() => {
                                                  }}>
                                    <Text style={{
                                        color: colors.WHITE,
                                        fontSize: 16,
                                    }}>{"Chọn xe"}</Text>
                                </TouchableOpacity>
                            </Paper>
                        </GridItem> : null}
                    {step > 1 ?
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                            <Paper style={{margin: 10}}>
                                <TouchableOpacity style={{
                                    backgroundColor: colors.GREEN.prime,
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: 10,
                                    elevation: 2,
                                    borderRadius: 2,
                                    shadowOffset: {
                                        height: 1,
                                        width: 0
                                    },
                                    shadowColor: colors.BLACK,
                                    shadowOpacity: 0.35,
                                    shadowRadius: 5
                                }}
                                                  onPress={() => {
                                                      setStep(step - 1);
                                                  }}>
                                    <Text style={{
                                        color: colors.WHITE,
                                        fontSize: 16,
                                    }}>{"Quay lại"}</Text>
                                </TouchableOpacity>
                            </Paper>
                        </GridItem> : null}
                    <GridItem xs={6} sm={6} md={6} lg={6}>
                        <Paper style={{margin: 10}}>
                            <TouchableOpacity
                                style={{
                                    backgroundColor: colors.MAIN,
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: 10,
                                    elevation: 2,
                                    borderRadius: 2,
                                    shadowOffset: {
                                        height: 1,
                                        width: 0
                                    },
                                    shadowColor: colors.BLACK,
                                    shadowOpacity: 0.35,
                                    shadowRadius: 5
                                }}
                                onPress={() => {
                                    if (step == 1) {
                                        if (!carType || carType == "Chọn Loại Xe") {
                                            alert("Vui lòng chọn loại xe")
                                        } else {
                                            setStep(step + 1);
                                        }
                                    } else {
                                        if (step == 2) {
                                            if (!tripdata.drop) {
                                                setShowDiaglog(true);
                                            } else {
                                                setStep(step + 1);
                                            }
                                        } else if (step == 3) {
                                            setShowDiaglog(true);
                                        } else {
                                            setStep(step + 1);
                                        }
                                    }
                                }}>
                                <Text style={{
                                    color: colors.WHITE,
                                    fontSize: 16,
                                }}>{"Tiếp tục"}</Text>
                            </TouchableOpacity>
                        </Paper>
                    </GridItem>

                    {step == 1 ?
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Paper style={{marginLeft: 10, marginRight: 10}}>
                                <FormControl style={{width: '100%'}}>
                                    <Select
                                        id="car-type-native"
                                        value={carType}
                                        onChange={handleCarSelect}
                                        className={carType === language.select_car ? classes.inputdimmed : classes.input}
                                    >
                                        <MenuItem value={language.select_car} key={language.select_car}>
                                            {language.select_car}
                                        </MenuItem>
                                        {cartypes ?
                                            cartypes.filter((carDetail) => carDetail.code != 'test' && carDetail.type != 'hop_dong').map((car) =>
                                                <MenuItem key={car.name} value={car.name}>
                                                    <img src={car.image} className={classes.carphoto}
                                                         alt="car types"/>{car.name} {"(" + car.extra_info + ")"}
                                                </MenuItem>
                                            ) : null
                                        }
                                    </Select>
                                </FormControl>
                            </Paper>
                        </GridItem>
                        : null}
                    {step == 2 ?
                        <>
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <Paper style={{marginLeft: 10, marginRight: 10}}>
                                    <GoogleMapsAutoComplete
                                        placeholder={language.pickup_location}
                                        variant={"filled"}
                                        value={pickupAddress}
                                        onChange={
                                            (value) => {
                                                setPickupAddress(value);
                                                setMylocation({
                                                    lat: value.coords.lat,
                                                    lng: value.coords.lng
                                                });
                                                dispatch(updateTripPickup({
                                                    lat: value.coords.lat,
                                                    lng: value.coords.lng,
                                                    add: value.description,
                                                    source: "search"
                                                }));
                                            }
                                        }
                                    />
                                </Paper>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <Paper style={{marginLeft: 10, marginRight: 10}}>
                                    {mylocation ?
                                        <Map
                                            isPickup={true}
                                            loadingElement={<div className={classes.fullHeight}/>}
                                            containerElement={<div className={classes.fullHeight}/>}
                                            mapElement={<div className={classes.fullHeight}/>}
                                            center={mylocation}
                                            googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + Google_Map_Key + "&v=3.exp&libraries=geometry,drawing,places"}
                                        /> : null}
                                </Paper>
                            </GridItem></>
                        : null}

                    {step == 3 ?
                        <>
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <Paper style={{marginLeft: 10, marginRight: 10}}>
                                    <GoogleMapsAutoComplete
                                        placeholder={language.drop_location + " (Không bắt buộc)"}
                                        variant={"filled"}
                                        value={dropAddress}
                                        onChange={
                                            (value) => {
                                                setDropAddress(value);
                                                setMylocation({
                                                    lat: value.coords.lat,
                                                    lng: value.coords.lng
                                                });
                                                dispatch(updateTripDrop({
                                                    lat: value.coords.lat,
                                                    lng: value.coords.lng,
                                                    add: value.description,
                                                    source: "search"
                                                }));
                                            }
                                        }
                                    />
                                </Paper>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <Paper style={{marginLeft: 10, marginRight: 10}}>
                                    {mylocation ?
                                        <Map
                                            isPickup={true}
                                            loadingElement={<div className={classes.fullHeight}/>}
                                            containerElement={<div className={classes.fullHeight}/>}
                                            mapElement={<div className={classes.fullHeight}/>}
                                            center={mylocation}
                                            googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + Google_Map_Key + "&v=3.exp&libraries=geometry,drawing,places"}
                                        /> : null}
                                </Paper>
                            </GridItem></>
                        : null}

                    {/*<GridItem xs={6} sm={6} md={6} lg={6}>
                        <Paper style={{marginLeft: 10, marginRight: 10}}>
                            <TextField
                                fullWidth
                                onChange={(event) => {
                                    let text = event.target.value
                                    setPhone(text);
                                }}
                                value={phone ? phone : (auth && auth.info && auth.info.profile ? auth.info.profile.mobile.replace("+84", '') : '')}
                                label="Số điện thoại"/>
                        </Paper>
                    </GridItem>*/}
                </GridContainer>
            </div>
            <Footer/>
            <Dialog
                open={showDiaglog}
                onClose={() => {
                    setShowDiaglog(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Xác nhận cuốc khách"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <GridContainer spacing={2}>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                {auth && auth.info ?
                                    <>
                                        {tripdata && tripdata.pickup ?
                                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                                <Paper style={{marginLeft: 10, marginRight: 10}}>
                                                    <h3>Thông tin cuốc khách</h3>
                                                    <h4>Điểm đón: {tripdata.pickup.add ? formatDisplayAddress(tripdata.pickup.add) : ''}</h4>
                                                    <h4>Điểm trả: {tripdata.drop && tripdata.drop.add ? formatDisplayAddress(tripdata.drop.add) : 'Chọn điểm trả để biết giá ước tính'}</h4>
                                                    {estimateTemp ?
                                                        <>
                                                            <h4>Khoảng cách ước tính {estimateTemp && estimateTemp.distance_in_km ? estimateTemp.distance_in_km + " (km)" : "Vui lòng chọn điểm tra để biết"}</h4>
                                                            <h4>Thời gian ước tính {estimateTemp && estimateTemp.time_in_secs ? Math.abs(Math.round((estimateTemp.time_in_secs / 60))) + " (phút)" : "Vui lòng chọn điểm tra để biết"}</h4>
                                                            <h4>Chi phí ước tính {resultCheckPrice1 ? resultCheckPrice1 : "Vui lòng chọn điểm đến để biết"}</h4>
                                                        </> : null}
                                                </Paper>
                                            </GridItem> : null}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <Paper style={{marginLeft: 10, marginRight: 10}}>
                                                <TextField
                                                    fullWidth
                                                    onChange={(event) => {
                                                        let text = event.target.value
                                                        setNote(text);
                                                    }}
                                                    value={note}
                                                    label="Lời nhắn tới lái xe: Vị trí đón khách"/>
                                            </Paper>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <Paper style={{marginLeft: 10, marginRight: 10}}>
                                                {mylocation ?
                                                    <Map
                                                        isPickup={true}
                                                        loadingElement={<div className={classes.fullHeight}/>}
                                                        containerElement={<div className={classes.fullHeight}/>}
                                                        mapElement={<div className={classes.fullHeight}/>}
                                                        center={mylocation}
                                                        googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + Google_Map_Key + "&v=3.exp&libraries=geometry,drawing,places"}
                                                    /> : null}
                                            </Paper>
                                        </GridItem>
                                    </>
                                    :
                                    <h4 style={{fontSize: 15, color: 'black'}}>{"Vui lòng đăng nhập để tiếp tục đặt xe và xem lại ước tính chi phí"}</h4>
                                }
                            </GridItem>
                        </GridContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <View style={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: "center"
                    }}>
                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: "center"
                        }}>
                            <Button
                                disabled={false}
                                onClick={(e) => {
                                    setShowDiaglog(false);
                                    if (auth && auth.info) {
                                        console.log(tripdata);
                                        alert("Dang xay dung");
                                    } else {
                                        e.preventDefault();
                                        history.push({
                                            pathname: `/login`,
                                            state: {redirect: 'booking_web'}
                                        });
                                    }
                                }} autoFocus style={{backgroundColor: 'green'}}>
                                {auth && auth.info ? "Đặt ngay" : "Đăng nhập"}
                            </Button>
                            {!tripdata.drop ?
                                <Button
                                    disabled={false}
                                    onClick={(e) => {
                                        setShowDiaglog(false);
                                        setStep(3);
                                    }} autoFocus style={{backgroundColor: 'green'}}>
                                    {"Chọn điểm trả"}
                                </Button> : null
                            }
                        </View>
                        <View style={{
                            flex: 1,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: "center"
                        }}>
                            <Button onClick={() => {
                                setShowDiaglog(false);
                            }} style={{backgroundColor: 'red'}}>
                                {"Đóng"}
                            </Button>
                        </View>
                    </View>
                </DialogActions>
            </Dialog>

        </SafeAreaView>
    );
}
