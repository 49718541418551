import React from "react";
import {createBrowserHistory} from "history";
import {Route, Router, Switch} from "react-router-dom";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import 'firebase/app-check';
import LandingPage from "./views/LandingPage.js";
import LoginPage from "./views/LoginPage.js";
import PrivacyPolicy from "./views/PrivacyPolicy.js";
import DriverPrivacyPolicy from "./views/DriverPrivacyPolicy.js";
import RiderPrivacyPolicy from "./views/RiderPrivacyPolicy.js";
import BookingWeb from "./views/BookingWeb.js";
import AboutUs from "./views/AboutUs";
import AuthLoading from './views/AuthLoading';
import {Provider} from "react-redux";
import ProtectedRoute from './views/ProtectedRoute';
import MyProfile from './views/MyProfile';
import BookingHistory from './views/BookingHistory';
import Dashboard from './views/Dashboard';
import CarTypes from './views/CarTypes';
import CaTrucDem from './views/CaTrucDem';
import CaTrucDemConfig from './views/CaTrucDemConfig';
import GiaoXe from './views/GiaoXe';
import ForceNotify from './views/ForceNotify';
import GroupDrivers from './views/GroupDrivers';
import AddBookings_bak from './views/AddBookings';
import Promos from './views/Promos';
import PromoLocations from './views/PromoLocations';
import GroupCoords from './views/GroupCoords';
import Riders from './views/Riders';
import Drivers from './views/Drivers';
import FleetAdmins from './views/FleetAdmins';
import Notifications from './views/Notifications';
import DriverEarning from './views/DriverEarning';
import Earningreports from './views/Earningreports';
import Settings from './views/Settings';
import Withdraws from './views/Withdraws';
import CancellationReasons from './views/CancellationReasons';
import RegisterPage from './views/RegisterPage';
import AddMoney from "./views/AddMoney";
import {FirebaseProvider, store} from 'common';
import {features} from 'config';
import ReportDrivers from "./views/ReportDrivers";
import CustomerCare from "./views/CustomerCare";
import CallCenter from "./views/CallCenter";
import Mistakes from "./views/Mistakes";
import MistakeConfig from "./views/MistakeConfig";
import Invite from "./views/Invite";
import ReportTotalCKDrivers from "./views/ReportTotalCKDrivers";
import WalletManage from './views/WalletManage';
import RolePermission from './views/RolePermission';

var hist = createBrowserHistory();


function App() {
    return (
        <Provider store={store}>
            <FirebaseProvider>
                <AuthLoading>
                    <Router history={hist}>
                        <Switch>
                            <ProtectedRoute exact component={BookingHistory} path="/bookings" permit={"rider,admin,driver,fleetadmin"}/>
                            <ProtectedRoute exact component={CallCenter} path="/callcenter" permit={"admin,fleetadmin"}/>
                            <ProtectedRoute exact component={MyProfile} path="/profile" permit={"rider,admin,driver,fleetadmin"}/>
                            <ProtectedRoute exact component={CaTrucDem} path="/catrucdems" permit={"admin,fleetadmin"}/>
                            <ProtectedRoute exact component={GiaoXe} path="/giaoxe" permit={"admin,fleetadmin"}/>
                            <ProtectedRoute exact component={ForceNotify} path="/force_notify" permit={"admin,fleetadmin"}/>
                            <ProtectedRoute exact component={Mistakes} path="/mistakes" permit={"admin,fleetadmin"}/>
                            <ProtectedRoute exact component={WalletManage} path="/walletmanage" permit={"admin"}/>
                            <ProtectedRoute exact component={PromoLocations} path="/promolocation" permit={"rider,admin,driver,fleetadmin"}/>
                            <ProtectedRoute exact component={GroupCoords} path="/groupcoord" permit={"rider,admin,driver,"}/>
                            <ProtectedRoute exact component={Dashboard} path="/dashboard" permit={"admin,fleetadmin"}/>
                            <ProtectedRoute exact component={CarTypes} path="/cartypes" permit={"admin"}/>
                            <ProtectedRoute exact component={GroupDrivers} path="/groupdrivers" permit={"admin"}/>
                            <ProtectedRoute exact component={ReportDrivers} path="/reportdrivers" permit={"admin,fleetadmin"}/>
                            <ProtectedRoute exact component={CustomerCare} path="/customer_care" permit={"admin,fleetadmin"}/>
                            <ProtectedRoute exact component={CancellationReasons} path="/cancelreasons" permit={"admin"}/>
                            <ProtectedRoute exact component={AddBookings_bak} path="/addbookings" permit={"admin"}/>
                            <ProtectedRoute exact component={Promos} path="/promos" permit={"admin"}/>
                            <ProtectedRoute exact component={Riders} path="/riders" permit={"admin"}/>
                            <ProtectedRoute exact component={Drivers} path="/drivers" permit={"admin,fleetadmin"}/>
                            <ProtectedRoute exact component={FleetAdmins} path="/fleetadmins" permit={"admin"}/>
                            <ProtectedRoute exact component={DriverEarning} path="/driverearning" permit={"admin,fleetadmin"}/>
                            <ProtectedRoute exact component={Notifications} path="/notifications" permit={"admin"}/>
                            <ProtectedRoute exact component={Earningreports} path="/earningreports" permit={"admin"}/>
                            <ProtectedRoute exact component={ReportTotalCKDrivers} path="/report_total_booking_day" permit={"admin"}/>
                            <ProtectedRoute exact component={AddMoney} path="/addtowallet" permit={"admin"}/>
                            <ProtectedRoute exact component={Withdraws} path="/withdraws" permit={"admin"}/>
                            <ProtectedRoute exact component={MistakeConfig} path="/mistake_config" permit={"admin"}/>
                            <ProtectedRoute exact component={CaTrucDemConfig} path="/catrucdem_config" permit={"admin,fleetadmin"}/>
                            <ProtectedRoute exact component={Settings} path="/settings" permit={"admin"}/>
                            <ProtectedRoute exact component={RolePermission} path="/role_permission" permit={"admin"}/>
                            <Route path="/about-us" component={AboutUs}/>
                            <Route path="/privacy-policy" component={PrivacyPolicy}/>
                            <Route path="/driver-privacy-policy" component={DriverPrivacyPolicy}/>
                            <Route path="/rider-privacy-policy" component={RiderPrivacyPolicy}/>
                            <Route path="/booking_web" component={BookingWeb}/>
                            <Route path="/invite" component={Invite}/>
                            {features.WebsitePagesEnabled ?
                                <Route path="/register" component={RegisterPage}/>
                                : null}
                            {features.WebsitePagesEnabled ?
                                <Route path="/login" component={LoginPage}/>
                                : null}
                            {features.WebsitePagesEnabled ?
                                <Route path="/" component={LandingPage}/>
                                :
                                <Route path="/" component={LoginPage}/>
                            }
                        </Switch>
                    </Router>
                </AuthLoading>
            </FirebaseProvider>
        </Provider>
    );
}

export default App;
