import {createStore, combineReducers, applyMiddleware} from "redux";
import thunk from "redux-thunk";
//import logger from "redux-logger";

import {authreducer as auth} from "../reducers/authreducer";
import {cartypesreducer as cartypes} from "../reducers/cartypesreducer";
import {catrucdemreducer as catrucdems} from "../reducers/catrucdemreducer";
import {catrucdemconfigreducer as catrucdemsconfig} from "../reducers/catrucdemconfigreducer";
import {groupdriverreducer as groupdriverdata} from "../reducers/groupdriverreducer";
import {areasreducer as areasdata} from "../reducers/areasreducer";
import {notifyimportancereducer as notifyimportancedata} from "../reducers/notifyimportancereducer";
import {votesreducer as votesdata} from "../reducers/votesreducer";
import {reportdriverreducer as reportdriverdata} from "../reducers/reportdriverreducer";
import {bookingslistreducer as bookinglistdata} from "../reducers/bookingslistreducer";
import {estimatereducer as estimatedata} from "../reducers/estimatereducer";
import {bookingreducer as bookingdata} from "../reducers/bookingreducer";
import {cancelreasonreducer as cancelreasondata} from "../reducers/cancelreasonreducer";
import {promolocationreducer as promolocationdata} from "../reducers/promolocationreducer";
import {groupcoordreducer as groupcoorddata} from "../reducers/groupcoordreducer";
import {promoreducer as promodata} from "../reducers/promoreducer";
import {banneduidsreducer as banneduidsdata} from "../reducers/banneduidsreducer";
import {usersreducer as usersdata} from "../reducers/usersreducer";
import {notificationreducer as notificationdata} from "../reducers/notificationreducer";
import {mistakereducer as mistakedata} from "../reducers/mistakereducer";
import {mistakeconfigreducer as mistakeconfigdata} from "../reducers/mistakeconfigreducer";
import {pacallcenterreducer as pacallcenterdata} from "../reducers/pacallcenterreducer";
import {notelistreducer as notelistdata} from "../reducers/notelistreducer";
import {driverearningreducer as driverearningdata} from '../reducers/driverearningreducer';
import {walletmanagereducer as walletmanagedata} from '../reducers/walletmanagereducer';
import {earningreportsreducer as earningreportsdata} from '../reducers/earningreportsreducer';
import {settingsreducer as settingsdata} from '../reducers/settingsreducer';
import {paymentreducer as paymentmethods} from '../reducers/paymentreducer';
import {tripreducer as tripdata} from '../reducers/tripreducer';
import {tasklistreducer as taskdata} from '../reducers/tasklistreducer';
import {locationreducer as locationdata} from '../reducers/locationreducer';
import {chatreducer as chatdata} from '../reducers/chatreducer';
import {withdrawreducer as withdrawdata} from '../reducers/withdrawreducer';
import {gpsreducer as gpsdata} from '../reducers/gpsreducer';
import {datacarreducer as datacar} from '../reducers/datacarreducer';
import {routenamereducer as routenamedata} from '../reducers/routenamereducer';
import {sessionreducer as sessiondata} from '../reducers/sessionreducer';
import {trackingreducer as trackingdata} from '../reducers/trackingreducer';
import {giaoxereducer as giaoxedata} from "../reducers/giaoxereducer";
import {taxiteamsreducer as taxiteamsdata} from "../reducers/taxiteamsreducer";
import {forcenotifyreducer as forcenotifydata} from "../reducers/forcenotifyreducer";
import {polygonreducer as polygondata} from '../reducers/polygonreducer';
import {newsreducer as newsdata} from "../reducers/newsreducer";
import {pricesreducer as pricesdata} from "../reducers/pricesreducer";
import {driverfaviouritereducer as driverfaviouritedata} from "../reducers/driverfaviouritereducer";
import {pointsreducer as pointsdata} from "../reducers/pointsreducer";
import {tradesreducer as tradesdata} from "../reducers/tradesreducer";
import {marketsreducer as marketsdata} from "../reducers/marketsreducer";
import {walletsreducer as walletsdata} from "../reducers/walletsreducer";
import {reportsreducer as reportsdata} from "../reducers/reportsreducer";
import {localreportsreducer as localreportsdata} from "../reducers/localreportsreducer";
import {friendsreducer as friendsdata} from "../reducers/friendsreducer";
import {rolepermissionreducer as rolepermissiondata} from "../reducers/rolepermissionreducer";
const reducers = combineReducers({
    auth,
    cartypes,
    catrucdems,
    catrucdemsconfig,
    groupdriverdata,
    reportdriverdata,
    bookinglistdata,
    estimatedata,
    bookingdata,
    cancelreasondata,
    promodata,
    banneduidsdata,
    usersdata,
    notificationdata,
    driverearningdata,
    earningreportsdata,
    settingsdata,
    paymentmethods,
    tripdata,
    taskdata,
    locationdata,
    chatdata,
    withdrawdata,
    gpsdata,
    datacar,
    promolocationdata,
    groupcoorddata,
    routenamedata,
    pacallcenterdata,
    sessiondata,
    mistakedata,
    mistakeconfigdata,
    trackingdata,
    giaoxedata,
    taxiteamsdata,
    forcenotifydata,
    polygondata,
    newsdata,
    pricesdata,
    driverfaviouritedata,
    walletmanagedata,
    areasdata,
    notifyimportancedata,
    votesdata,
    pointsdata,
    tradesdata,
    marketsdata,
    walletsdata,
    reportsdata,
    localreportsdata,
    friendsdata,
    notelistdata,
    rolepermissiondata
});

let middleware = [thunk];

export const store = createStore(reducers, {}, applyMiddleware(...middleware));
