import {FETCH_AREAS, FETCH_AREAS_FAILED, FETCH_AREAS_SUCCESS, REG_LEAD_AREA, REG_LEAD_AREA_FAILED, REG_LEAD_AREA_SUCCESS, STOP_FETCH_AREAS} from "../store/types";

const INITIAL_STATE = {
    friend: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const areasreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_AREAS:
            return {
                ...state,
                loading: true
            };
        case FETCH_AREAS_SUCCESS:
            return {
                ...state,
                areas: action.payload.areas,
                loading: false
            };
        case FETCH_AREAS_FAILED:
            return {
                ...state,
                areas: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case STOP_FETCH_AREAS:
            return {
                ...state,
                areas: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case REG_LEAD_AREA:
            return {
                ...state,
                loading: true
            };
        case REG_LEAD_AREA_SUCCESS:
            return {
                ...state,
                area: action.payload.area,
                loading: false
            };
        case REG_LEAD_AREA_FAILED:
            return {
                ...state,
                area: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        default:
            return state;
    }
};
