export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const USER_SIGN_IN = "USER_SIGN_IN";
export const USER_SIGN_IN_SUCCESS = "USER_SIGN_IN_SUCCESS";
export const USER_SIGN_IN_FAILED = "SIGN_IN_FAILED";
export const USER_SIGN_OUT = "USER_SIGN_OUT";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const USER_NOT_REGISTERED = "USER_NOT_REGISTERED";
export const USER_DELETED = "USER_DELETED";
export const REQUEST_OTP = "REQUEST_OTP";
export const REQUEST_OTP_SUCCESS = "REQUEST_OTP_SUCCESS";
export const REQUEST_OTP_FAILED = "REQUEST_OTP_FAILED";

export const FETCH_USER_DETAIL = "FETCH_USER_DETAIL";
export const FETCH_USER_DETAIL_SUCCESS = "FETCH_USER_DETAIL_SUCCESS";
export const FETCH_USER_DETAIL_FAILED = "FETCH_USER_DETAIL_FAILED";

export const FETCH_CAR_TYPES = "FETCH_CAR_TYPES";
export const FETCH_CAR_TYPES_SUCCESS = "FETCH_CAR_TYPES_SUCCESS";
export const FETCH_CAR_TYPES_FAILED = "FETCH_CAR_TYPES_FAILED";
export const EDIT_CAR_TYPE = "EDIT_CAR_TYPE";
export const EDIT_CAR_TYPE_PRICE = "EDIT_CAR_TYPE_PRICE";

export const FETCH_CATRUCDEM = "FETCH_CATRUCDEM";
export const FETCH_CATRUCDEM_SUCCESS = "FETCH_CATRUCDEM_SUCCESS";
export const FETCH_CATRUCDEM_FAILED = "FETCH_CATRUCDEM_FAILED";
export const STOP_FETCH_CATRUCDEM = "STOP_FETCH_CATRUCDEM";

export const FETCH_CATRUCDEM_CONFIG = "FETCH_CATRUCDEM_CONFIG";
export const FETCH_CATRUCDEM_CONFIG_SUCCESS = "FETCH_CATRUCDEM_CONFIG_SUCCESS";
export const FETCH_CATRUCDEM_CONFIG_FAILED = "FETCH_CATRUCDEM_CONFIG_FAILED";
export const STOP_FETCH_CATRUCDEM_CONFIG = "STOP_FETCH_CATRUCDEM_CONFIG";
export const EDIT_CATRUCDEM_CONFIG = "EDIT_CATRUCDEM_CONFIG";

export const EDIT_CATRUCDEM = "EDIT_CATRUCDEM";
export const SET_CATRUCDEM = "SET_CATRUCDEM";
export const FETCH_CATRUCDEM_DRIVER = "FETCH_CATRUCDEM_DRIVER";
export const FETCH_CATRUCDEM_DRIVER_SUCCESS = "FETCH_CATRUCDEM_DRIVER_SUCCESS";
export const FETCH_CATRUCDEM_DRIVER_FAILED = "FETCH_CATRUCDEM_DRIVER_FAILED";
export const UPDATE_CATRUCDEM_HISTORY = "UPDATE_CATRUCDEM_HISTORY";

export const STOP_FETCH_CATRUCDEM_DRIVER = "STOP_FETCH_CATRUCDEM_DRIVER";
export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";
export const FETCH_REPORTS_FAILED = "FETCH_REPORTS_FAILED";

export const INIT_REPORT_BY_DATE = "INIT_REPORT_BY_DATE";
export const INIT_REPORT_BY_DATE_SUCCESS = "INIT_REPORT_BY_DATE_SUCCESS";
export const INIT_REPORT_BY_DATE_FAILED = "INIT_REPORT_BY_DATE_FAILED";

export const SET_REPORT_ITEM = "SET_REPORT_ITEM";
export const SET_REPORT_ITEM_SUCCESS = "SET_REPORT_ITEM_SUCCESS";
export const SET_REPORT_ITEM_FAILED = "SET_REPORT_ITEM_FAILED";


export const FETCH_LOCAL_REPORTS = "FETCH_LOCAL_REPORTS";
export const FETCH_LOCAL_REPORTS_SUCCESS = "FETCH_LOCAL_REPORTS_SUCCESS";
export const FETCH_LOCAL_REPORTS_FAILED = "FETCH_LOCAL_REPORTS_FAILED";

export const INIT_LOCAL_REPORT_BY_DATE = "INIT_LOCAL_REPORT_BY_DATE";
export const INIT_LOCAL_REPORT_BY_DATE_SUCCESS = "INIT_LOCAL_REPORT_BY_DATE_SUCCESS";
export const INIT_LOCAL_REPORT_BY_DATE_FAILED = "INIT_LOCAL_REPORT_BY_DATE_FAILED";

export const SET_LOCAL_REPORT_ITEM = "SET_LOCAL_REPORT_ITEM";
export const SET_LOCAL_REPORT_ITEM_SUCCESS = "SET_LOCAL_REPORT_ITEM_SUCCESS";
export const SET_LOCAL_REPORT_ITEM_FAILED = "SET_LOCAL_REPORT_ITEM_FAILED";


export const FETCH_GIAOXE = "FETCH_GIAOXE";
export const FETCH_GIAOXE_SUCCESS = "FETCH_GIAOXE_SUCCESS";
export const FETCH_GIAOXE_FAILED = "FETCH_GIAOXE_FAILED";
export const STOP_FETCH_GIAOXE = "STOP_FETCH_GIAOXE";


export const FETCH_GIAOXE_BIENSO = "FETCH_GIAOXE_BIENSO";
export const FETCH_GIAOXE_BIENSO_SUCCESS = "FETCH_GIAOXE_BIENSO_SUCCESS";
export const FETCH_GIAOXE_BIENSO_FAILED = "FETCH_GIAOXE_BIENSO_FAILED";

export const FETCH_FORCE_NOTIFY = "FETCH_FORCE_NOTIFY";
export const FETCH_FORCE_NOTIFY_SUCCESS = "FETCH_FORCE_NOTIFY_SUCCESS";
export const FETCH_FORCE_NOTIFY_FAILED = "FETCH_FORCE_NOTIFY_FAILED";
export const EDIT_FORCE_NOTIFY = "EDIT_FORCE_NOTIFY";
export const PUSH_FORCE_NOTIFY = "PUSH_FORCE_NOTIFY";
export const STOP_FETCH_FORCE_NOTIFY = "STOP_FETCH_FORCE_NOTIFY";

export const FETCH_NEWS = "FETCH_NEWS";
export const FETCH_NEWS_SUCCESS = "FETCH_NEWS_SUCCESS";
export const FETCH_NEWS_FAILED = "FETCH_NEWS_FAILED";
export const EDIT_NEWS = "EDIT_NEWS";
export const PUSH_NEWS = "PUSH_NEWS";

export const EDIT_GIAOXE = "EDIT_GIAOXE";
export const PUSH_GIAOXE = "PUSH_GIAOXE";
export const UPDATE_GIAOXE_CONTENT = "UPDATE_GIAOXE_CONTENT";

export const STOP_FETCH_BOOKINGS = "STOP_FETCH_BOOKINGS";
export const FETCH_BOOKINGS = "FETCH_BOOKINGS";
export const FETCH_BOOKINGS_SUCCESS = "FETCH_BOOKINGS_SUCCESS";
export const FETCH_BOOKINGS_FAILED = "FETCH_BOOKINGS_FAILED";

export const FETCH_NEWS_BOOKINGS = "FETCH_NEWS_BOOKINGS";
export const FETCH_NEWS_BOOKINGS_SUCCESS = "FETCH_NEWS_BOOKINGS_SUCCESS";
export const FETCH_NEWS_BOOKINGS_FAILED = "FETCH_NEWS_BOOKINGS_FAILED";

export const FETCH_BOOKINGS_LATER = "FETCH_BOOKINGS_LATER";
export const FETCH_BOOKINGS_LATER_SUCCESS = "FETCH_BOOKINGS_LATER_SUCCESS";
export const FETCH_BOOKINGS_LATER_FAILED = "FETCH_BOOKINGS_LATER_FAILED";

export const FETCH_ONCE_ALL_BOOKINGS = "FETCH_ONCE_ALL_BOOKINGS";
export const FETCH_ONCE_ALL_BOOKINGS_SUCCESS = "FETCH_ONCE_ALL_BOOKINGS_SUCCESS";
export const FETCH_ONCE_ALL_BOOKINGS_FAILED = "FETCH_ONCE_ALL_BOOKINGS_FAILED";

export const FETCH_TOTAL_BOOKINGS_CANCEL = "FETCH_TOTAL_BOOKINGS_CANCEL";
export const FETCH_TOTAL_BOOKINGS_CANCEL_SUCCESS = "FETCH_TOTAL_BOOKINGS_CANCEL_SUCCESS";
export const FETCH_TOTAL_BOOKINGS_CANCEL_FAILED = "FETCH_TOTAL_BOOKINGS_CANCEL_FAILED";


export const FETCH_BOOKINGS_TEAM = "FETCH_BOOKINGS_TEAM";
export const FETCH_BOOKINGS_TEAM_SUCCESS = "FETCH_BOOKINGS_TEAM_SUCCESS";
export const FETCH_BOOKINGS_TEAM_FAILED = "FETCH_BOOKINGS_TEAM_FAILED";

export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const CANCEL_BOOKING = "CANCEL_BOOKING";
export const UPDATE_BOOKING_STARTED = "UPDATE_BOOKING_STARTED";
export const FETCH_NOTES = "FETCH_NOTES";
export const FETCH_NOTES_SUCCESS = "FETCH_NOTES_SUCCESS";
export const FETCH_NOTES_FAILED = "FETCH_NOTES_FAILED";

export const FETCH_ESTIMATE = "FETCH_ESTIMATE";
export const FETCH_ESTIMATE_SUCCESS = "FETCH_ESTIMATE_SUCCESS";
export const FETCH_ESTIMATE_FAILED = "FETCH_ESTIMATE_FAILED";
export const CLEAR_ESTIMATE = "CLEAR_ESTIMATE";

export const CONFIRM_BOOKING = "CONFIRM_BOOKING";
export const CONFIRM_BOOKING_SUCCESS = "CONFIRM_BOOKING_SUCCESS";
export const CONFIRM_BOOKING_FAILED = "CONFIRM_BOOKING_FAILED";
export const CLEAR_BOOKING = "CLEAR_BOOKING";

export const FETCH_BOOKING_DETAIL_BY_CODE = "FETCH_BOOKING_DETAIL_BY_CODE";
export const FETCH_BOOKING_DETAIL_BY_CODE_SUCCESS = "FETCH_BOOKING_DETAIL_BY_CODE_SUCCESS";
export const FETCH_BOOKING_DETAIL_BY_CODE_FAILED = "FETCH_BOOKING_DETAIL_BY_CODE_FAILED";
export const FETCH_BOOKING_DETAIL_BY_CODE_STOP = "FETCH_BOOKING_DETAIL_BY_CODE_STOP";
export const CLEAR_BOOKING_DATA = "CLEAR_BOOKING_DATA";

export const SELECT_BOOKING_SUCCESS = "SELECT_BOOKING_SUCCESS";
export const SELECT_BOOKING_FAILED = "SELECT_BOOKING_FAILED";
export const SELECT_BOOKING = "SELECT_BOOKING";

export const SELECT_BOOKING_DRIVER_SUCCESS = "SELECT_BOOKING_DRIVER_SUCCESS";
export const SELECT_BOOKING_DRIVER_FAILED = "SELECT_BOOKING_DRIVER_FAILED";
export const SELECT_BOOKING_DRIVER = "SELECT_BOOKING_DRIVER";

export const SELECT_BOOKING_DETAIL_SUCCESS = "SELECT_BOOKING_DETAIL_SUCCESS";
export const SELECT_BOOKING_DETAIL_FAILED = "SELECT_BOOKING_DETAIL_FAILED";
export const SELECT_BOOKING_DETAIL = "SELECT_BOOKING_DETAIL";

export const SELECT_BOOKING_DETAIL_ON_SUCCESS = "SELECT_BOOKING_DETAIL_ON_SUCCESS";
export const SELECT_BOOKING_DETAIL_ON_FAILED = "SELECT_BOOKING_DETAIL_ON_FAILED";
export const SELECT_BOOKING_DETAIL_ON = "SELECT_BOOKING_DETAIL_ON";

export const FETCH_CANCEL_REASONS = "FETCH_CANCEL_REASONS";
export const FETCH_CANCEL_REASONS_SUCCESS = "FETCH_CANCEL_REASONS_SUCCESS";
export const FETCH_CANCEL_REASONS_FAILED = "FETCH_CANCEL_REASONS_FAILED";
export const EDIT_CANCELLATION_REASON = "EDIT_CANCELLATION_REASON";
export const STOP_FETCH_CANCEL_REASONS = "STOP_FETCH_CANCEL_REASONS";

export const SEND_RESET_EMAIL = "SEND_RESET_EMAIL";
export const SEND_RESET_EMAIL_SUCCESS = "SEND_RESET_EMAIL_SUCCESS";
export const SEND_RESET_EMAIL_FAILED = "SEND_RESET_EMAIL_FAILED";

export const FETCH_PROMOS = "FETCH_PROMOS";
export const FETCH_PROMOS_SUCCESS = "FETCH_PROMOS_SUCCESS";
export const FETCH_PROMOS_FAILED = "FETCH_PROMOS_FAILED";
export const EDIT_PROMOS = "EDIT_PROMOS";

export const FETCH_BANNDEDUIDS = "FETCH_BANNDEDUIDS";
export const FETCH_BANNDEDUIDS_SUCCESS = "FETCH_BANNDEDUIDS_SUCCESS";
export const FETCH_BANNDEDUIDS_FAILED = "FETCH_BANNDEDUIDS_FAILED";
export const EDIT_BANNDEDUIDS = "EDIT_BANNDEDUIDS";

export const FETCH_ALL_USERS = "FETCH_ALL_USERS";
export const FETCH_ALL_DRIVERS = "FETCH_ALL_DRIVERS";
export const FETCH_ALL_DRIVERS_QUEUE = "FETCH_ALL_DRIVERS_QUEUE";
export const FETCH_ALL_ONCE_USERS = "FETCH_ALL_ONCE_USERS";
export const FETCH_ALL_ONCE_USERS_SUCCESS = "FETCH_ALL_ONCE_USERS_SUCCESS";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_DRIVERS_SUCCESS = "FETCH_ALL_DRIVERS_SUCCESS";
export const STOP_FETCH_ALL_DRIVERS_SUCCESS = "STOP_FETCH_ALL_DRIVERS_SUCCESS";
export const FETCH_ALL_DRIVERS_QUEUE_SUCCESS = "FETCH_ALL_DRIVERS_QUEUE_SUCCESS";
export const FETCH_ALL_ONCE_USERS_FAILED = "FETCH_ALL_ONCE_USERS_FAILED";
export const FETCH_ALL_USERS_FAILED = "FETCH_ALL_USERS_FAILED";
export const FETCH_ALL_DRIVERS_FAILED = "FETCH_ALL_DRIVERS_FAILED";
export const FETCH_ALL_DRIVERS_QUEUE_FAILED = "FETCH_ALL_DRIVERS_QUEUE_FAILED";
export const EDIT_USER = "EDIT_USER";
export const UPDATE_USER_GROUP = "UPDATE_USER_GROUP";
export const UPDATE_USER_GROUP_SUCCESS = "UPDATE_USER_GROUP_SUCCESS";
export const UPDATE_USER_GROUP_FAILED = "UPDATE_USER_GROUP_FAILED";
export const UPDATE_USER_LOCK_STATUS = "UPDATE_USER_LOCK_STATUS";
export const UPDATE_USER_LOCK_STATUS_SUCCESS = "UPDATE_USER_LOCK_STATUS_SUCCESS";
export const UPDATE_USER_LOCK_STATUS_FAILED = "UPDATE_USER_LOCK_STATUS_FAILED";
export const FETCH_ALL_ONCE_DRIVER = "FETCH_ALL_ONCE_DRIVER";
export const FETCH_ALL_ONCE_DRIVER_SUCCESS = "FETCH_ALL_ONCE_DRIVER_SUCCESS";
export const FETCH_ALL_ONCE_DRIVER_FAILED = "FETCH_ALL_ONCE_DRIVER_FAILED";

export const FETCH_ALL_FLEETADMIN = "FETCH_ALL_FLEETADMIN";
export const FETCH_ALL_FLEETADMIN_SUCCESS = "FETCH_ALL_FLEETADMIN_SUCCESS";
export const FETCH_ALL_FLEETADMIN_FAILED = "FETCH_ALL_FLEETADMIN_FAILED";

export const FETCH_DRIVER_IN_GROUP = "FETCH_DRIVER_IN_GROUP";
export const FETCH_DRIVER_IN_GROUP_SUCCESS = "FETCH_DRIVER_IN_GROUP_SUCCESS";
export const FETCH_DRIVER_IN_GROUP_FAILED = "FETCH_DRIVER_IN_GROUP_FAILED";

export const FETCH_DRIVER_ACTIVE = "FETCH_DRIVER_ACTIVE";
export const FETCH_DRIVER_ACTIVE_SUCCESS = "FETCH_DRIVER_ACTIVE_SUCCESS";
export const FETCH_DRIVER_ACTIVE_FAILED = "FETCH_DRIVER_ACTIVE_FAILED";

export const FETCH_DRIVER_OUT_GROUP = "FETCH_DRIVER_OUT_GROUP";
export const FETCH_DRIVER_OUT_GROUP_SUCCESS = "FETCH_DRIVER_OUT_GROUP_SUCCESS";
export const FETCH_DRIVER_OUT_GROUP_FAILED = "FETCH_DRIVER_OUT_GROUP_FAILED";

export const FETCH_DRIVER_OUT_IN_GROUP = "FETCH_DRIVER_OUT_IN_GROUP";
export const FETCH_DRIVER_OUT_IN_GROUP_SUCCESS = "FETCH_DRIVER_OUT_IN_GROUP_SUCCESS";
export const FETCH_DRIVER_OUT_IN_GROUP_FAILED = "FETCH_DRIVER_OUT_IN_GROUP_FAILED";

export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILED = "EDIT_USER_FAILED";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const SELECT_USER = "SELECT_USER";
export const SELECT_USER_SUCCESS = "SELECT_USER_SUCCESS";
export const SELECT_USER_FAILED = "SELECT_USER_FAILED";


export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILED = "FETCH_NOTIFICATIONS_FAILED";
export const STOP_FETCH_NOTIFICATIONS = "STOP_FETCH_NOTIFICATIONS";
export const EDIT_NOTIFICATIONS = "EDIT_NOTIFICATIONS";
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAILED = "SEND_NOTIFICATION_FAILED";


export const FETCH_MISTAKES = "FETCH_MISTAKES";
export const FETCH_MISTAKES_SUCCESS = "FETCH_MISTAKES_SUCCESS";
export const FETCH_MISTAKES_FAILED = "FETCH_MISTAKES_FAILED";

export const FETCH_ROLE_PERMISIONS = "FETCH_ROLE_PERMISIONS";
export const FETCH_ROLE_PERMISIONS_SUCCESS = "FETCH_ROLE_PERMISIONS_SUCCESS";
export const FETCH_ROLE_PERMISIONS_FAILED = "FETCH_ROLE_PERMISIONS_FAILED";
export const STOP_FETCH_ROLE_PERMISIONS = "STOP_FETCH_ROLE_PERMISIONS";
export const STOP_FETCH_MISTAKES = "STOP_FETCH_MISTAKES";
export const FETCH_DRIVER_MISTAKES = "FETCH_DRIVER_MISTAKES";
export const FETCH_DRIVER_MISTAKES_SUCCESS = "FETCH_DRIVER_MISTAKES_SUCCESS";
export const FETCH_DRIVER_MISTAKES_FAILED = "FETCH_DRIVER_MISTAKES_FAILED";
export const EDIT_MISTAKE = "EDIT_MISTAKE";
export const EDIT_ROLE_PERMISSION = "EDIT_ROLE_PERMISSION";
export const EDIT_ROLE_PERMISSION_SUCCESS = "EDIT_ROLE_PERMISSION_SUCCESS";
export const EDIT_ROLE_PERMISSION_FAILED = "EDIT_ROLE_PERMISSION_FAILED";
export const EDIT_ROLE_PERMISSION_MEMBER = "EDIT_ROLE_PERMISSION_MEMBER";
export const EDIT_ROLE_PERMISSION_MEMBER_SUCCESS = "EDIT_ROLE_PERMISSION_MEMBER_SUCCESS";
export const EDIT_ROLE_PERMISSION_MEMBER_FAILED = "EDIT_ROLE_PERMISSION_MEMBER_FAILED";
export const SEND_MISTAKE = "SEND_MISTAKE";
export const SEND_MISTAKE_SUCCESS = "SEND_MISTAKE_SUCCESS";
export const SEND_MISTAKE_FAILED = "SEND_MISTAKE_FAILED";

export const FETCH_MISTAKE_CONFIG = "FETCH_MISTAKE_CONFIG";
export const FETCH_MISTAKE_CONFIG_SUCCESS = "FETCH_MISTAKE_CONFIG_SUCCESS";
export const FETCH_MISTAKE_CONFIG_FAILED = "FETCH_MISTAKE_CONFIG_FAILED";
export const EDIT_MISTAKE_CONFIG = "EDIT_MISTAKE_CONFIG";

export const FETCH_TEAMS_TAXI = "FETCH_TEAMS_TAXI";
export const FETCH_TEAMS_TAXI_SUCCESS = "FETCH_TEAMS_TAXI_SUCCESS";
export const FETCH_TEAMS_TAXI_FAILED = "FETCH_TEAMS_TAXI_FAILED";
export const EDIT_TEAMS_TAXI = "EDIT_TEAMS_TAXI";
export const EDIT_TEAMS_TAXI_SUCCESS = "EDIT_TEAMS_TAXI_SUCCESS";
export const EDIT_TEAMS_TAXI_FAILED = "EDIT_TEAMS_TAXI_FAILED";

export const FETCH_TEAMS_TAXI_BY_ID = "FETCH_TEAMS_TAXI_BY_ID";
export const FETCH_TEAMS_TAXI_BY_ID_SUCCESS = "FETCH_TEAMS_TAXI_BY_ID_SUCCESS";
export const FETCH_TEAMS_TAXI_BY_ID_FAILED = "FETCH_TEAMS_TAXI_BY_ID_FAILED";

export const INVITE_MEMBER_TAXI_TEAM = "INVITE_MEMBER_TAXI_TEAM";
export const INVITE_MEMBER_TAXI_TEAM_SUCCESS = "INVITE_MEMBER_TAXI_TEAM_SUCCESS";
export const INVITE_MEMBER_TAXI_TEAM_FAILED = "INVITE_MEMBER_TAXI_TEAM_FAILED";

export const JOIN_TAXI_TEAM = "JOIN_TAXI_TEAM";
export const JOIN_TAXI_TEAM_SUCCESS = "JOIN_TAXI_TEAM_SUCCESS";
export const JOIN_TAXI_TEAM_FAILED = "JOIN_TAXI_TEAM_FAILED";

export const REMOVE_MEMBER_TAXI_TEAM = "REMOVE_MEMBER_TAXI_TEAM";
export const REMOVE_MEMBER_TAXI_TEAM_SUCCESS = "REMOVE_MEMBER_TAXI_TEAM_SUCCESS";
export const REMOVE_MEMBER_TAXI_TEAM_FAILED = "REMOVE_MEMBER_TAXI_TEAM_FAILED";

export const FETCH_DRIVERS_EARNING = "FETCH_DRIVERS_EARNING";
export const FETCH_DRIVERS__EARNING_SUCCESS = "FETCH_DRIVERS__EARNING_SUCCESS";
export const FETCH_DRIVERS__EARNING_FAILED = "FETCH_DRIVERS__EARNING_FAILED";

export const FETCH_WALLET_MANAGE = "FETCH_WALLET_MANAGE";
export const FETCH_WALLET_MANAGE_SUCCESS = "FETCH_WALLET_MANAGE_SUCCESS";
export const FETCH_WALLET_MANAGE_FAILED = "FETCH_WALLET_MANAGE_FAILED";

export const FETCH_BOOKING_DISCOUNT = "FETCH_BOOKING_DISCOUNT";
export const FETCH_BOOKING__DISCOUNT_SUCCESS = "FETCH_BOOKING__DISCOUNT_SUCCESS";
export const FETCH_BOOKING__DISCOUNT_FAILED = "FETCH_BOOKING__DISCOUNT_FAILED";
export const EDIT_BOOKING__DISCOUNT = "EDIT_BOOKING__DISCOUNT";

export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILED = "FETCH_SETTINGS_FAILED";
export const EDIT_SETTINGS = "EDIT_SETTINGS";
export const CLEAR_SETTINGS_ERROR = "CLEAR_SETTINGS_ERROR";

export const FETCH_PAYMENT_METHODS = "FETCH_PAYMENT_METHODS";
export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_FAILED = "FETCH_PAYMENT_METHODS_FAILED";
export const UPDATE_WALLET_BALANCE = "UPDATE_WALLET_BALANCE";
export const UPDATE_WALLET_BALANCE_SUCCESS = "UPDATE_WALLET_BALANCE_SUCCESS";
export const UPDATE_WALLET_BALANCE_FAILED = "UPDATE_WALLET_BALANCE_FAILED";
export const CLEAR_PAYMENT_MESSAGES = "CLEAR_PAYMENT_MESSAGES";
export const UPDATE_REFUND_BALANCE = "UPDATE_REFUND_BALANCE";
export const UPDATE_REFUND_BALANCE_SUCCESS = "UPDATE_REFUND_BALANCE_SUCCESS";
export const UPDATE_REFUND_BALANCE_FAILED = "UPDATE_REFUND_BALANCE_FAILED";

export const UPDATE_TRIP_PICKUP = "UPDATE_TRIP_PICKUP";
export const UPDATE_TRIP_DROP = "UPDATE_TRIP_DROP";
export const UPDATE_TRIP_NOTE = "UPDATE_TRIP_NOTE";
export const UPDATE_TRIP_PHONE = "UPDATE_TRIP_PHONE";
export const UPDATE_TRIP_GROUP = "UPDATE_TRIP_GROUP";

export const UPDATE_TRIP_PRICE = "UPDATE_TRIP_PRICE";
export const UPDATE_TRIP_CALLCENTER = "UPDATE_TRIP_CALLCENTER";
export const UPDATE_DRIVER_ACCEPT = "UPDATE_DRIVER_ACCEPT";
export const UPDATE_TRIP_CAR = "UPDATE_TRIP_CAR";
export const UPDATE_SELECTED_POINT_TYPE = "UPDATE_SELECTED_POINT_TYPE";
export const CLEAR_TRIP_POINTS = "CLEAR_TRIP_POINTS";
export const UPDATE_BOOKING_TIME = "UPDATE_BOOKING_TIME";
export const UPDATE_TWO_WAY = "UPDATE_TWO_WAY";

export const FETCH_TASKS = "FETCH_TASKS";
export const FETCH_TASKS_SUCCESS = "FETCH_TASKS_SUCCESS";
export const FETCH_TASKS_FAILED = "FETCH_TASKS_FAILED";
export const FETCH_ACCEPTED_TASKS = "FETCH_ACCEPTED_TASKS";
export const FETCH_ACCEPTED_TASKS_SUCCESS = "FETCH_ACCEPTED_TASKS_SUCCESS";
export const FETCH_ACCEPTED_TASKS_FAILED = "FETCH_ACCEPTED_TASKS_FAILED";

export const ACCEPT_TASK = "ACCEPT_TASK";
export const STARTED_TASK = "STARTED_TASK";
export const ASSIGN_TASK = "ASSIGN_TASK";
export const CANCEL_TASK = "CANCEL_TASK";

export const TRACKING_TASKS = "TRACKING_TASKS";
export const TRACKING_TASKS_SUCCESS = "TRACKING_TASKS_SUCCESS";
export const TRACKING_TASKS_FAILED = "TRACKING_TASKS_FAILED";

export const FETCH_BOOKING_LOCATION = 'FETCH_BOOKING_LOCATION';
export const FETCH_BOOKING_LOCATION_SUCCESS = 'FETCH_BOOKING_LOCATION_SUCCESS';
export const FETCH_BOOKING_LOCATION_FAILED = 'FETCH_BOOKING_LOCATION_FAILED';
export const STOP_LOCATION_FETCH = 'STOP_LOCATION_FETCH';

export const FETCH_BA_BOOKING_LOCATION = 'FETCH_BA_BOOKING_LOCATION';
export const FETCH_BA_BOOKING_LOCATION_SUCCESS = 'FETCH_BA_BOOKING_LOCATION_SUCCESS';
export const FETCH_BA_BOOKING_LOCATION_FAILED = 'FETCH_BA_BOOKING_LOCATION_FAILED';
export const STOP_BA_LOCATION_FETCH = 'STOP_BA_LOCATION_FETCH';

export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAILED = 'FETCH_MESSAGES_FAILED';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const STOP_FETCH_MESSAGES = 'STOP_FETCH_MESSAGES';

export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';

export const FETCH_CALLS_PENDING = 'FETCH_CALLS_PENDING';
export const FETCH_CALLS_PENDING_SUCCESS = 'FETCH_CALLS_PENDING_SUCCESS';
export const FETCH_CALLS_PENDING_FAILED = 'FETCH_CALLS_PENDING_FAILED';
export const FETCH_CALLS_DETAIL = 'FETCH_CALLS_DETAIL';
export const STOP_FETCH_CALLS_DETAIL = "STOP_FETCH_CALLS_DETAIL";
export const FETCH_CALLS_DETAIL_SUCCESS = 'FETCH_CALLS_DETAIL_SUCCESS';
export const FETCH_CALLS_DETAIL_FAILED = 'FETCH_CALLS_DETAIL_FAILED';
export const STOP_FETCH_CALLS_PENDING = 'STOP_FETCH_CALLS_PENDING';

export const CANCEL_CALL_BY_ID = "CANCEL_CALL_BY_ID";
export const CANCEL_CALL_BY_ID_SUCCESS = 'CANCEL_CALL_BY_ID_SUCCESS';
export const CANCEL_CALL_BY_ID_FAILED = 'CANCEL_CALL_BY_ID_FAILED';
export const PUSH_CALL_BY_ID = "PUSH_CALL_BY_ID";
export const PUSH_CALL_BY_ID_SUCCESS = 'PUSH_CALL_BY_ID_SUCCESS';
export const PUSH_CALL_BY_ID_FAILED = 'PUSH_CALL_BY_ID_FAILED';
export const FETCH_CALL_ACTIVE = 'FETCH_CALL_ACTIVE';
export const FETCH_CALL_ACTIVE_SUCCESS = 'FETCH_CALL_ACTIVE_SUCCESS';
export const FETCH_CALL_ACTIVE_FAILED = 'FETCH_CALL_ACTIVE_FAILED';

export const FETCH_WITHDRAWS = 'FETCH_WITHDRAWS';
export const FETCH_WITHDRAWS_SUCCESS = 'FETCH_WITHDRAWS_SUCCESS';
export const FETCH_WITHDRAWS_FAILED = 'FETCH_WITHDRAWS_FAILED';
export const EDIT_WITHDRAWS = 'EDIT_WITHDRAWS';

export const UPDATE_GPS_LOCATION = 'UPDATE_GPS_LOCATION';
export const UPDATE_GPS_LOCATION_SECOND = 'UPDATE_GPS_LOCATION_SECOND';
export const UPDATE_GPS_FRIEND = 'UPDATE_GPS_FRIEND';
export const UPDATE_DATA_CAR = 'UPDATE_DATA_CAR';
export const UPDATE_ROUTE_NAME = 'UPDATE_ROUTE_NAME';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const UPDATE_SESSION_NOTIFY = 'UPDATE_SESSION_NOTIFY';
export const UPDATE_SESSION_PLAY_BOOKING = 'UPDATE_SESSION_PLAY_BOOKING';
export const UPDATE_POLYGON = 'UPDATE_POLYGON';
export const UPDATE_TRACKING = 'UPDATE_TRACKING';
export const FETCH_GROUP_DRIVERS = "FETCH_GROUP_DRIVERS";
export const FETCH_GROUP_DRIVERS_SUCCESS = "FETCH_GROUP_DRIVERS_SUCCESS";
export const FETCH_GROUP_DRIVERS_FAILED = "FETCH_GROUP_DRIVERS_FAILED";
export const EDIT_GROUP_DRIVER = "EDIT_GROUP_DRIVER";

export const FETCH_GROUP_AREA = "FETCH_GROUP_AREA";
export const FETCH_GROUP_AREA_SUCCESS = "FETCH_GROUP_AREA_SUCCESS";
export const FETCH_GROUP_AREA_FAILED = "FETCH_GROUP_AREA_FAILED";

export const FETCH_GROUP_AREA_BY_TEAM = "FETCH_GROUP_AREA_BY_TEAM";
export const FETCH_GROUP_AREA_BY_TEAM_SUCCESS = "FETCH_GROUP_AREA_BY_TEAM_SUCCESS";
export const FETCH_GROUP_AREA_BY_TEAM_FAILED = "FETCH_GROUP_AREA_BY_TEAM_FAILED";

export const FETCH_GROUP_AREA_BY_ID = "FETCH_GROUP_AREA_BY_ID";
export const FETCH_GROUP_AREA_BY_ID_SUCCESS = "FETCH_GROUP_AREA_BY_ID_SUCCESS";
export const FETCH_GROUP_AREA_BY_ID_FAILED = "FETCH_GROUP_AREA_BY_ID_FAILED";

export const FETCH_AREA_BY_ID = "FETCH_AREA_BY_ID";
export const FETCH_AREA_BY_ID_SUCCESS = "FETCH_AREA_BY_ID_SUCCESS";
export const FETCH_AREA_BY_ID_FAILED = "FETCH_AREA_BY_ID_FAILED";

export const FETCH_AREAS = "FETCH_AREAS";
export const FETCH_AREAS_SUCCESS = "FETCH_AREAS_SUCCESS";
export const FETCH_AREAS_FAILED = "FETCH_AREAS_FAILED";
export const STOP_FETCH_AREAS = "STOP_FETCH_AREAS";
export const FETCH_AREA_WALLET = "FETCH_AREA_WALLET";
export const FETCH_AREA_WALLET_SUCCESS = "FETCH_AREA_WALLET_SUCCESS";
export const FETCH_AREA_WALLET_FAILED = "FETCH_AREA_WALLET_FAILED";

export const FETCH_ETO_WALLET = "FETCH_ETO_WALLET";
export const FETCH_ETO_WALLET_SUCCESS = "FETCH_ETO_WALLET_SUCCESS";
export const FETCH_ETO_WALLET_FAILED = "FETCH_ETO_WALLET_FAILED";
export const STOP_FETCH_ETO_WALLET = "STOP_FETCH_ETO_WALLET";
export const FETCH_POINT = "FETCH_POINT";
export const FETCH_POINT_SUCCESS = "FETCH_POINT_SUCCESS";
export const FETCH_POINT_FAILED = "FETCH_POINT_FAILED";

export const FETCH_MARKETS = "FETCH_MARKETS";
export const FETCH_MARKETS_SUCCESS = "FETCH_MARKETS_SUCCESS";
export const FETCH_MARKETS_FAILED = "FETCH_MARKETS_FAILED";

export const FETCH_TRADE = "FETCH_TRADE";
export const FETCH_TRADE_SUCCESS = "FETCH_TRADE_SUCCESS";
export const FETCH_TRADE_FAILED = "FETCH_TRADE_FAILED";

export const SET_TRADE = "SET_TRADE";
export const SET_TRADE_SUCCESS = "SET_TRADE_SUCCESS";
export const SET_TRADE_FAILED = "SET_TRADE_FAILED";

export const UPDATE_TRADE = "UPDATE_TRADE";
export const UPDATE_TRADE_SUCCESS = "UPDATE_TRADE_SUCCESS";
export const UPDATE_TRADE_FAILED = "UPDATE_TRADE_FAILED";

export const INIT_POINT = "INIT_POINT";
export const INIT_POINT_SUCCESS = "INIT_POINT_SUCCESS";
export const INIT_POINT_FAILED = "INIT_POINT_FAILED";

export const PUSH_POINT = "PUSH_POINT";
export const PUSH_POINT_SUCCESS = "PUSH_POINT_SUCCESS";
export const PUSH_POINT_FAILED = "PUSH_POINT_FAILED";

export const PUSH_MARKET = "PUSH_MARKET";
export const PUSH_MARKET_SUCCESS = "PUSH_MARKET_SUCCESS";
export const PUSH_MARKET_FAILED = "PUSH_MARKET_FAILED";

export const PUSH_MARKET_TRANSACTION = "PUSH_MARKET_TRANSACTION";
export const PUSH_MARKET_TRANSACTION_SUCCESS = "PUSH_MARKET_TRANSACTION_SUCCESS";
export const PUSH_MARKET_TRANSACTION_FAILED = "PUSH_MARKET_TRANSACTION_FAILED";

export const FETCH_NOTIFY_IMPORTANCE = "FETCH_NOTIFY_IMPORTANCE";
export const FETCH_NOTIFY_IMPORTANCE_SUCCESS = "FETCH_NOTIFY_IMPORTANCE_SUCCESS";
export const FETCH_NOTIFY_IMPORTANCE_FAILED = "FETCH_NOTIFY_IMPORTANCE_FAILED";

export const FETCH_VOTE = "FETCH_VOTE";
export const FETCH_VOTE_SUCCESS = "FETCH_VOTE_SUCCESS";
export const FETCH_VOTE_FAILED = "FETCH_VOTE_FAILED";

export const FETCH_TASKS_RUNNER = "FETCH_TASKS_RUNNER";
export const FETCH_TASKS_RUNNER_SUCCESS = "FETCH_TASKS_RUNNER_SUCCESS";
export const FETCH_TASKS_RUNNER_FAILED = "FETCH_TASKS_RUNNER_FAILED";

export const PUSH_TASKS_RUNNER = "PUSH_TASKS_RUNNER";
export const PUSH_TASKS_RUNNER_SUCCESS = "PUSH_TASKS_RUNNER_SUCCESS";
export const PUSH_TASKS_RUNNER_FAILED = "PUSH_TASKS_RUNNER_FAILED";

export const PUSH_NOTIFY_IMPORTANCE = "PUSH_NOTIFY_IMPORTANCE";
export const PUSH_NOTIFY_IMPORTANCE_SUCCESS = "PUSH_NOTIFY_IMPORTANCE_SUCCESS";
export const PUSH_NOTIFY_IMPORTANCE_FAILED = "PUSH_NOTIFY_IMPORTANCE_FAILED";

export const UPDATE_NOTIFY_IMPORTANCE = "UPDATE_NOTIFY_IMPORTANCE";
export const UPDATE_NOTIFY_IMPORTANCE_SUCCESS = "UPDATE_NOTIFY_IMPORTANCE_SUCCESS";
export const UPDATE_NOTIFY_IMPORTANCE_FAILED = "UPDATE_NOTIFY_IMPORTANCE_FAILED";

export const PUSH_VOTE = "PUSH_VOTE";
export const PUSH_VOTE_SUCCESS = "PUSH_VOTE_SUCCESS";
export const PUSH_VOTE_FAILED = "PUSH_VOTE_FAILED";

export const FETCH_AREAS_MEMBERS = "FETCH_AREAS_MEMBERS";
export const FETCH_AREAS_MEMBERS_SUCCESS = "FETCH_AREAS_MEMBERS_SUCCESS";
export const FETCH_AREAS_MEMBERS_FAILED = "FETCH_AREAS_MEMBERS_FAILED";

export const REG_LEAD_AREA = "REG_LEAD_AREA";
export const REG_LEAD_AREA_SUCCESS = "REG_LEAD_AREA_SUCCESS";
export const REG_LEAD_AREA_FAILED = "REG_LEAD_AREA_FAILED";

export const INIT_AREA_WALLET = "INIT_AREA_WALLET";
export const INIT_AREA_WALLET_SUCCESS = "INIT_AREA_WALLET_SUCCESS";
export const INIT_AREA_WALLET_FAILED = "INIT_AREA_WALLET_FAILED";

export const INIT_ETO_WALLET = "INIT_ETO_WALLET";
export const INIT_ETO_WALLET_SUCCESS = "INIT_ETO_WALLET_SUCCESS";
export const INIT_ETO_WALLET_FAILED = "INIT_ETO_WALLET_FAILED";

export const PUSH_AREA_WALLET = "PUSH_AREA_WALLET";
export const PUSH_AREA_WALLET_SUCCESS = "PUSH_AREA_WALLET_SUCCESS";
export const PUSH_AREA_WALLET_FAILED = "PUSH_AREA_WALLET_FAILED";

export const PUSH_ETO_WALLET = "PUSH_ETO_WALLET";
export const PUSH_ETO_WALLET_SUCCESS = "PUSH_ETO_WALLET_SUCCESS";
export const PUSH_ETO_WALLET_FAILED = "PUSH_ETO_WALLET_FAILED";

export const REG_MEMBER_AREA = "REG_MEMBER_AREA";
export const REG_MEMBER_AREA_SUCCESS = "REG_MEMBER_AREA_SUCCESS";
export const REG_MEMBER_AREA_FAILED = "REG_MEMBER_AREA_FAILED";


export const FETCH_GROUP_AREA_BY_TEAM_PENDING = "FETCH_GROUP_AREA_BY_TEAM_PENDING";
export const FETCH_GROUP_AREA_BY_TEAM_PENDING_SUCCESS = "FETCH_GROUP_AREA_BY_TEAM_PENDING_SUCCESS";
export const FETCH_GROUP_AREA_BY_TEAM_PENDING_FAILED = "FETCH_GROUP_AREA_BY_TEAM_PENDING_FAILED";

export const FETCH_ALL_TRANSACTIONS = "FETCH_ALL_TRANSACTIONS";
export const FETCH_ALL_TRANSACTIONS_SUCCESS = "FETCH_ALL_TRANSACTIONS_SUCCESS";
export const FETCH_ALL_TRANSACTIONS_FAILED = "FETCH_ALL_TRANSACTIONS_FAILED";

export const FETCH_PROMO_LOCATIONS = "FETCH_PROMO_LOCATIONS";
export const FETCH_PROMO_LOCATIONS_SUCCESS = "FETCH_PROMO_LOCATIONS_SUCCESS";
export const FETCH_PROMO_LOCATIONS_FAILED = "FETCH_PROMO_LOCATIONS_FAILED";
export const EDIT_PROMO_LOCATION = "EDIT_PROMO_LOCATION";

export const FETCH_GROUP_COORDS = "FETCH_GROUP_COORDS";
export const FETCH_GROUP_COORDS_SUCCESS = "FETCH_GROUP_COORDS_SUCCESS";
export const FETCH_GROUP_COORDS_FAILED = "FETCH_GROUP_COORDS_FAILED";
export const EDIT_GROUP_COORD = "EDIT_GROUP_COORD";

export const NOTIFY_BOOKING = "NOTIFY_BOOKING";
export const ADD_REPORT_DRIVER = "ADD_REPORT_DRIVER";

export const FETCH_REPORT_DRIVERS = "FETCH_REPORT_DRIVERS";
export const FETCH_REPORT_DRIVERS_SUCCESS = "FETCH_REPORT_DRIVERS_SUCCESS";
export const FETCH_REPORT_DRIVERS_FAILED = "FETCH_REPORT_DRIVERS_FAILED";
export const EDIT_REPORT_DRIVER = "EDIT_REPORT_DRIVER";
export const CONFIRM_REPORT_DRIVER_SUCCESS = "CONFIRM_REPORT_DRIVER_SUCCESS";
export const STOP_FETCH_REPORT_DRIVERS = "STOP_FETCH_REPORT_DRIVERS";

export const FETCH_PRICES = "FETCH_PRICES";
export const FETCH_PRICES_FAILED = "FETCH_PRICES_FAILED";
export const FETCH_PRICES_SUCCESS = "FETCH_PRICES_SUCCESS";

export const FETCH_DRIVER_FAVIOURITE = "FETCH_DRIVER_FAVIOURITE";
export const FETCH_DRIVER_FAVIOURITE_FAILED = "FETCH_DRIVER_FAVIOURITE_FAILED";
export const FETCH_DRIVER_FAVIOURITE_SUCCESS = "FETCH_DRIVER_FAVIOURITE_SUCCESS";

export const FETCH_ONE_DRIVER_FAVIOURITE = "FETCH_ONE_DRIVER_FAVIOURITE";
export const FETCH_ONE_DRIVER_FAVIOURITE_FAILED = "FETCH_ONE_DRIVER_FAVIOURITE_FAILED";
export const FETCH_ONE_DRIVER_FAVIOURITE_SUCCESS = "FETCH_ONE_DRIVER_FAVIOURITE_SUCCESS";

export const FETCH_FRIENDS = "FETCH_FRIENDS";
export const FETCH_FRIENDS_FAILED = "FETCH_FRIENDS_FAILED";
export const FETCH_FRIENDS_SUCCESS = "FETCH_FRIENDS_SUCCESS";

export const ADD_FRIENDS = "ADD_FRIENDS";
export const ADD_FRIENDS_FAILED = "ADD_FRIENDS_FAILED";
export const ADD_FRIENDS_SUCCESS = "ADD_FRIENDS_SUCCESS";

export const ADD_FRIENDS_ACCEPTED = "ADD_FRIENDS_ACCEPTED";
export const ADD_FRIENDS_ACCEPTED_FAILED = "ADD_FRIENDS_ACCEPTED_FAILED";
export const ADD_FRIENDS_ACCEPTED_SUCCESS = "ADD_FRIENDS_ACCEPTED_SUCCESS";

export const REMOVE_FRIENDS = "REMOVE_FRIENDS";
export const REMOVE_FRIENDS_FAILED = "REMOVE_FRIENDS_FAILED";
export const REMOVE_FRIENDS_SUCCESS = "REMOVE_FRIENDS_SUCCESS";

export const FETCH_NOTE_LIST = "FETCH_NOTE_LIST";
export const FETCH_NOTE_LIST_FAILED = "FETCH_NOTE_LIST_FAILED";
export const FETCH_NOTE_LIST_SUCCESS = "FETCH_NOTE_LIST_SUCCESS";

export const UPDATE_SESSION_TEAM_SELECTED = "UPDATE_SESSION_TEAM_SELECTED";
export const UPDATE_SESSION_SOUND_BUTTON_CLICK = "UPDATE_SESSION_SOUND_BUTTON_CLICK";
export const UPDATE_SESSION_SOUND_OPEN = "UPDATE_SESSION_SOUND_OPEN";
export const UPDATE_SESSION_SOUND_CLOSE = "UPDATE_SESSION_SOUND_CLOSE";
export const UPDATE_SESSION_SOUND_BATDONGHO = "UPDATE_SESSION_SOUND_BATDONGHO";
export const UPDATE_SESSION_SOUND_BOOKING = "UPDATE_SESSION_SOUND_BOOKING";
export const UPDATE_SESSION_SOUND_BOOKING_FIRST = "UPDATE_SESSION_SOUND_BOOKING_FIRST";
export const UPDATE_SESSION_SOUND_LOICHUC = "UPDATE_SESSION_SOUND_LOICHUC";
export const UPDATE_SESSION_SOUND_WARNING_CUSTOMER = "UPDATE_SESSION_SOUND_WARNING_CUSTOMER";
export const UPDATE_SESSION_HAS_UPDATE = "UPDATE_SESSION_HAS_UPDATE";
export const UPDATE_SESSION_HAS_NOTIFY = "UPDATE_SESSION_HAS_NOTIFY";
export const UPDATE_SESSION_HAS_BOOKING = "UPDATE_SESSION_HAS_BOOKING";
export const UPDATE_SESSION_HAS_FIX_BG = "UPDATE_SESSION_HAS_FIX_BG";
export const UPDATE_SESSION_HAS_UPDATE_GROUP = "UPDATE_SESSION_HAS_UPDATE_GROUP";
export const UPDATE_SESSION_HAS_UPDATE_TIME_OUT = "UPDATE_SESSION_HAS_UPDATE_TIME_OUT";
export const UPDATE_SESSION_HAS_UPDATE_TIME_ACTIVE = "UPDATE_SESSION_HAS_UPDATE_TIME_ACTIVE";
export const UPDATE_SESSION_HAS_UPDATE_CURRENT_AREA = "UPDATE_SESSION_HAS_UPDATE_CURRENT_AREA";
export const UPDATE_SESSION_HAS_BOOKING_ACTIVE = "UPDATE_SESSION_HAS_BOOKING_ACTIVE";
export const REPORT_FLEET_ADMIN_SUCCESS = "REPORT_FLEET_ADMIN_SUCCESS";
export const REPORT_FLEET_ADMIN = "REPORT_FLEET_ADMIN";