import React, {useContext} from 'react';
import {View} from "react-native-web";
import {colors} from "../../config/theme";
import IconButton from "@mui/material/IconButton";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import {HiddenPhone} from "common/src/other/CommonFunctions";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import PhoneDisabledOutlinedIcon from "@mui/icons-material/PhoneDisabledOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import {ConvertTimeStampTimeForPa} from "common/src/other/DateFunctions";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import NoteCallCenterComponent from "../CallCenter/NoteCallCenterComponent";
import {useDispatch, useSelector} from "react-redux";
import {FirebaseContext} from "common";

export default function CallCenterRowComponent(props) {
    const {api, getAddressMapFromNoteRef} = useContext(FirebaseContext);
    const {
        updateTripPhone,
        updateTripCallCenter
    } = api;
    const dispatch = useDispatch();

    const notelistdata = useSelector((state) => state.notelistdata.notes);
    const obj = props.callCenterObj;
    let arrayOptions =
        notelistdata && notelistdata[obj.item.source]
            ? Object.keys(
                Object.values(notelistdata[obj.item.source]).reduce(
                    (a, c) => ((a[c] = (a[c] || 0) + 1), a),
                    Object.create(null)
                )
            )
            : [];
    return (
        <View style={{
            maxWidth: 330, backgroundColor: 'rgba(255,255,255,0.77)',
            borderRadius: 10,
            shadowOffset: {
                height: 1,
                width: 0,
            },
            shadowColor: colors.BLACK,
            shadowOpacity: 0.35,
            shadowRadius: 10,
            border: 1,
            borderStyle: 'solid',
            borderColor: "#c7c7c7",
            marginTop: 10
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: "100%",
                padding: 0,
                margin: 0
            }}>
                <IconButton
                    aria-label="Gọi khách"
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    color="primary"
                    size="large"
                    onClick={() => {
                        if (props.showCall) {
                            props.showCall(obj.item);
                        }
                    }}
                >
                    <PhoneCallbackIcon fontSize="inherit"/>
                </IconButton>
                <div style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: "rgba(255,255,255,0.71)",
                    '&:hover': {
                        backgroundColor: "rgba(255,255,255,0.97)",
                    },
                    width: 200
                }}>
                    <h4 style={{fontSize: 30}}>{HiddenPhone(obj.item.source)}</h4>
                </div>
                <IconButton size="large"
                            color={"primary"}
                            onClick={() => {
                                if(props.groupApply && props.group){
                                    if (obj.item && obj.item.source) {
                                        if (props.handleClickOpenDialogBooking) {
                                            props.handleClickOpenDialogBooking(obj.item);
                                        }
                                    }
                                }else{
                                    alert("Vui lòng chọn điểm đón");
                                }
                            }
                            }
                            style={{
                                position: `absolute`,
                                right: `10px`,
                                top: '10px',
                                zIndex: 999,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: "#6059a7",
                                flexGrow: 1,
                                borderRadius: 5,
                                padding: 10
                            }}
                >
                    <ShareOutlinedIcon style={{fontSize: 35, color: "#ffffff"}}/>
                </IconButton>

                <IconButton size="large"
                            color={"primary"}
                            onClick={() => {
                                if(props.openCancelCallback){
                                    props.openCancelCallback(obj.item);
                                }
                            }
                            }
                            style={{
                                position: `absolute`,
                                right: `10px`,
                                top: '90px',
                                zIndex: 999,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: "#6059a7",
                                flexGrow: 1,
                                borderRadius: 5,
                                padding: 10
                            }}
                >
                    <PhoneDisabledOutlinedIcon style={{fontSize: 35, color: "#ffffff"}}/>
                </IconButton>
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: "100%"
            }}>
                <IconButton
                    aria-label="Ghi chú"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexGrow: 1,
                        padding: 10
                    }}
                    color="primary"
                    size="medium"
                    onClick={() => {

                    }}
                >
                    <CalendarMonthOutlinedIcon fontSize="inherit" style={{color: "#6059a7"}}/>
                </IconButton>
                <div style={{
                    display: 'flex',
                    flexGrow: 1,
                    position: 'relative',
                    borderRadius: 5,
                    backgroundColor: "rgba(255,255,255,0.71)",
                    '&:hover': {
                        backgroundColor: "rgba(255,255,255,0.97)",
                    },
                    width: 250,
                }}>
                    <h4>{ConvertTimeStampTimeForPa(obj.item.time * 1000)}</h4>
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: "100%"
            }}>
                <IconButton
                    aria-label="Ghi chú"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexGrow: 1,
                        padding: 10
                    }}
                    color="primary"
                    size="medium"
                    onClick={() => {

                    }}
                >
                    <NoteAltOutlinedIcon fontSize="inherit" style={{color: "#6059a7"}}/>
                </IconButton>
                <div style={{
                    display: 'flex',
                    flexGrow: 1,
                    position: 'relative',
                    borderRadius: 5,
                    backgroundColor: "rgba(255,255,255,0.71)",
                    '&:hover': {
                        backgroundColor: "rgba(255,255,255,0.97)",
                    },
                    width: 250,
                }}>
                    <View
                        style={{
                            justifyContent: "center",
                            minWidth: 200,
                        }}
                    >
                        <NoteCallCenterComponent
                            rowData={obj.item}
                            arrayOptions={arrayOptions}
                        />
                    </View>

                </div>
            </div>
        </View>
    )
}
