import React, {useState, useEffect, useContext, useCallback} from 'react';
import MaterialTable from 'material-table';
import {useSelector, useDispatch} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import {features, language} from 'config';
import {FirebaseContext} from 'common';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "@material-ui/core";
import ShowNotifyDialog from "../components/Notify/ShowNotifyDialog";

const useStyles = makeStyles(theme => ({
    titleList: {
        fontSize: 14,
        marginTop: 10
    },
    submit: {
        backgroundColor: colors.orange.A200
    },
    backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)",
        flexDirection: 'column'
    },
    row: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "rgba(255,0,0,0.74)"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#E8E8E8",
        fontSize: 16,
        padding: '10px 2px 10px 10px',
        color: "#000"
    },
    commonInputStyle: {
        borderRadius: 4,
        backgroundColor: "#E8E8E8",
    },
    title: {color: "#000"}
}));

export default function Notifications() {
    const {api} = useContext(FirebaseContext);
    const {
        sendNotification,
        editNotifications,
        stopFetchNotifications,
        fetchNotifications
    } = api;
    const styles = useStyles();
    const [showModal, setShowModal] = useState(false);
    const columns = [
        {
            title: language.device_type,
            field: 'devicetype',
            lookup: {All: 'All', ANDROID: 'Android', IOS: 'iOS'},
        },
        {
            title: language.user_type,
            field: 'usertype',
            lookup: {rider: language.rider, driver: language.driver},
        },
        {title: language.title, field: 'title'},
        {title: language.body, field: 'body'},
    ];

    const [data, setData] = useState([]);
    const notificationdata = useSelector(state => state.notificationdata);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchNotifications());
        return () => {
            dispatch(stopFetchNotifications());
        }
    }, []);

    useEffect(() => {
        if (notificationdata.notifications) {
            setData(notificationdata.notifications);
        }
    }, [notificationdata.notifications]);

    const hideModalCallback = useCallback(
        () => {
            setShowModal(false);
        },
        [],
    );

    return (
        notificationdata.loading ? <CircularLoading/> :
            <>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            id={"notifytodriver"}
                            className={styles.submit}
                            onClick={
                                () => {
                                    setShowModal(true);
                                }
                            }
                        >
                            {"Gửi thông báo đặc biệt"}
                        </Button>
                    </Grid>
                </Grid>
                <MaterialTable
                    title={language.push_notification_title}
                    columns={columns}
                    data={data}
                    editable={{
                        onRowAdd: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    tblData.push(newData);
                                    features.AllowCriticalEditsAdmin ?
                                        dispatch(sendNotification(newData))
                                        :
                                        alert(language.demo_mode);
                                    dispatch(editNotifications(newData, "Add"));
                                }, 600);
                            }),

                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    tblData[tblData.indexOf(oldData)] = newData;
                                    dispatch(editNotifications(newData, "Update"));
                                }, 600);
                            }),
                        onRowDelete: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    dispatch(editNotifications(newData, "Delete"));
                                }, 600);
                            }),
                    }}
                />
                {showModal ?
                    <ShowNotifyDialog showModal={showModal} hideModal={hideModalCallback}/> : null}
            </>
    );
}
